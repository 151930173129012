let location = window.location.href
let parques = window.location.href
location = location.replace("https://","https://sitio.")
parques = parques.replace("https://","https://parques.")
function getBaseUrl(url) {
  return url.slice(0, url.lastIndexOf("/") + 1);
}

const baseUrl = getBaseUrl(location);
const parquesUrl = getBaseUrl(parques);

const MODULES = [
  {
    module: 'hotels',
    moduleName: 'Hoteles',
    icon: 'hotels',
    path: '/hoteles',
    target:'_self',
    refTableDetail: ['hotel_detail']
  }, {
    module: 'trains',
    moduleName: 'Trenes',
    icon: 'train',
    path: '/trenes',
    target:'_self',
    refTableDetail: ['train_detail_segment', 'train_detail_passes']
  }, {
    module: 'flights',
    moduleName: 'Aéreos',
    icon: 'flights',
    path: '/vuelos',
    target:'_self',
    refTableDetail: ['air_detail']
  }, {
    module: 'renting_carz',
    moduleName: 'Autos',
    icon: 'auto',
    path: `${baseUrl}autos/`,
    target:'_self',
    refTableDetail: ['car_detail']
  }, {
    module: 'assists',
    moduleName: 'Asistencia',
    icon: 'assist',
    path: '/asistencia',
    target:'_self',
    refTableDetail: ['assistance_detail']
  }, {
    module: 'tours',
    moduleName: 'Experiencias',
    icon: 'packages',
    path: `${baseUrl}experiencias/`,
    target:'_self',
    refTableDetail: []
  }, {
    module: 'circuitos turisticos',
    moduleName: 'Circuitos',
    icon: 'circuitos',
    path: 'https://incomtour.com.ar/whitelabel/?token=ce742210862316c901fc51c86027a214',
    target:'_blank',
    refTableDetail: ['other_detail'],
    includeIfGTR: true
  }, {
    module: 'parques',
    moduleName: 'Parques',
    icon: 'parques',
    path: `${parquesUrl}`,
    target:'_blank',
    refTableDetail: ['other_detail'],
    includeIfGTR: true
  }
];

export {
  MODULES
}
