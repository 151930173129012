import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography, Grid, InputLabel, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getBanners, getPayment, getProducts, getClient, login, removeCookie, getLandings } from '../../../store/services/Login';
import { LoadingButton } from '@mui/lab';
import DialogMessage from '../../common/DialogMessage';
import RawMarkup from '../../common/RawMarkup';
import { useClientData } from '../../../context/ClientContext';
import { MODULES } from '../../../utils/modules';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Error } from '@mui/icons-material';
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const FIELDS = ['user', 'pass'];

const LoginForm = () => {
  const { t } = useTranslation();
  const { clientData, setClientData, setPayment } = useClientData();
  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [showErrorDevice, setShowErrorDevice] = useState(false)
  const [fingerprint, setFingerprint] = useState('')

  useEffect(() => {
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
      setClientData(null);
      localStorage.clear();
    }

    const loadFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setFingerprint(result.visitorId);
    }

    loadFingerprint();

    removeCookie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const toCamelCase = (str) => {
    let words = str.split('_');
    words = words.filter(elem => elem).map((elem, i) => {
      if (i === 0) {
        return elem;
      } else {
        return elem[0].toUpperCase() + elem.slice(1);
      }
    });
    return words.join('');
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const errorsAux = {};

    FIELDS.forEach(elem => {
      if (!form[elem]) {
        errorsAux[elem] = true;
      }
    })

    setErrors(errorsAux);

    if (Object.keys(errorsAux).length === 0) {
      const dataToSend = {
        ...form,
        app: clientData?.client?.app || 1,
        cli: clientData?.client?.cli || 1
      }
      setLoading(true);
      login(fingerprint, dataToSend).then(res => {
        localStorage.setItem('jwt', res.jwt);
        localStorage.setItem('hash_user', res.user.hashuser);

        const promises = [
          getProducts(res.jwt),
          getBanners(res.jwt),
          getLandings(res.jwt),
          getPayment(res.jwt)
        ];

        Promise.all(promises).then(async ([products, banners, landings, payment]) => {
          setPayment(payment);
          localStorage.setItem('payment', JSON.stringify(payment));

          res.user.id = products.user_id;
          res.user.username = products.username;
          localStorage.setItem('user', JSON.stringify(res.user));

          const productsAux = products?.products || [];
          localStorage.setItem('products', JSON.stringify(productsAux));

          let clientData = JSON.parse(localStorage.getItem('clientData') || '{}');

          clientData.landings = landings;

          if (products.work_unit_relation[0]) {
            localStorage.setItem('workUnit', JSON.stringify(products.work_unit_relation[0]));

           //const urlLocal = 'stg-hoteles-react.tije.travel' // Stg GTR
           const urlLocal = 'gotravelres.uy'// Prod UY
           //const urlLocal = 'stg-gtruy-react.tije.travel' // Stg UY

            const clientUrl = products.work_unit_relation[0]?.url
              || (window.location.host.includes('localhost')
                ? urlLocal
                : window.location.host);
            if (clientUrl) {
              const clientConfig = await getClient({ client: clientUrl });
              clientData = {
                ...clientData,
                ...clientConfig
              }
            }
          }
          if (products.business_unit_relation[0]) {
            localStorage.setItem('businessUnit', JSON.stringify(products.business_unit_relation[0]));
          }

          const refTableDetails = productsAux.map(elem => elem.ref_table_detail);
          let modules = MODULES.filter(module => {
            let flag = module.refTableDetail.some(elem => refTableDetails.includes(elem));
            if (module.refTableDetail?.[0] === 'other_detail') {
              flag = flag && productsAux.find(elem => elem.short_name.toLowerCase() === module.module)
            }
            return flag;
          });

          if (modules && clientData?.client?.sortProducts) {
            const sortOrder = clientData.client.sortProducts;
            modules = modules.sort((a, b) => sortOrder.indexOf(a.module.toLowerCase()) - sortOrder.indexOf(b.module.toLowerCase()));
          }

          clientData.modules = modules;

          setClientData(clientData);
          localStorage.setItem('clientData', JSON.stringify(clientData));

          if (banners?.data) {
            localStorage.setItem('banners', JSON.stringify({
              ...banners,
              time: dayjs().unix()
            }));
          }

          if (modules?.length > 0 && modules?.[0]?.path?.[0] !== '/') {
            window.location.href = modules?.[0]?.path;
          } else {
            navigate(modules?.[0]?.path || '/');
          }
        }).catch(error => {
          setShowError(error?.message || t('common.anErrorOcurred'));
        }).finally(() => {
          setLoading(false);
        })
      }).catch(error => {
        if (error?.exception) {
          const errorMessage = error.exception?.replace('ra.auth.', '');
          if (errorMessage === 'device_not_found' || errorMessage === 'device_with_expired_session') {
            setShowCode(true);
            setShowErrorDevice(null);
          } else if (errorMessage === 'device_code_expired' || errorMessage === 'device_code_invalid') {
            setShowErrorDevice({ title: `common.${toCamelCase(errorMessage)}Title` });
          } else if (errorMessage === 'reset_password' || errorMessage === 'user_disabled') {
            setShowErrorDevice({
              title: `common.${toCamelCase(errorMessage)}Title`,
              subtitle: `common.${toCamelCase(errorMessage)}Subtitle`
            });
            setShowCode(false);

            const formAux = {...form};
            delete formAux.device_code;
            setForm(formAux);
          } else {
            setShowError(t('common.loginErrorMessage'));
          }
        } else {
          setShowError(t('common.loginErrorMessage'));
        }

        setLoading(false);
      });
    }
  };

  const modalMessage = `<p style='font-size:18px;'>${t('common.registerMessage')} <strong style='color:#002848;'>mayorista@gotravelres.com</strong><p>`;

  const handleClickRegisterLink = () => {
    const registerLink = clientData?.client?.register?.link;
    if (registerLink) {
      window.location.href = registerLink;
    } else {
      handleOpenDialog(); // Abre el diálogo si no hay link
    }
  };

  return (
    <>
      <Grid className='form'>
        <Typography className='title'>
          {showCode ? t('common.logInCodeTitle') : t('common.logInTitle')}
        </Typography>
        {showCode && (
          <Typography className='subtitle'>
            {t('common.logInCodeSubtitle')}
          </Typography>
        )}
        <form onSubmit={handleSubmit} noValidate>
          {showCode ? (
            <>
              <InputLabel>{t('common.verificationCode')}</InputLabel>
              <TextField
                fullWidth
                id="device_code"
                name="device_code"
                autoComplete="device_code"
                placeholder={t('common.enterCode')}
                size='small'
                value={form.device_code || ''}
                error={errors.device_code}
                disabled={loading}
                onChange={(e) => setForm(current => ({ ...current, device_code: e.target.value }))}
              />

              {showErrorDevice && (
                <Grid className='row device-code-error-container'>
                  <Error />
                  <Grid>
                    {showErrorDevice?.title && (
                      <Typography>{t(showErrorDevice.title)}</Typography>
                    )}
                    {showErrorDevice?.subtitle && (
                      <Typography>{t(showErrorDevice.subtitle)}</Typography>
                    )}
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <>
              {showError && (
                <Alert severity='error' style={{ marginBottom: 10 }}>{showError}</Alert>
              )}
              <InputLabel>{t('common.user')}</InputLabel>
              <TextField
                fullWidth
                id="user"
                name="user"
                autoComplete="user"
                placeholder={t('common.user')}
                size='small'
                value={form.user || ''}
                error={errors.user}
                disabled={loading}
                onChange={(e) => setForm(current => ({ ...current, user: e.target.value }))}
              />

              <InputLabel>{t('common.password')}</InputLabel>
              <TextField
                fullWidth
                name="pass"
                type="password"
                id="pass"
                autoComplete="current-password"
                placeholder={t('common.password')}
                size='small'
                value={form.pass || ''}
                error={errors.pass}
                disabled={loading}
                onChange={(e) => setForm(current => ({ ...current, pass: e.target.value }))}
              />
            </>
          )}

          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            className='submit-button'
            loading={loading}
          >
            {showCode ? t('common.send') : t('common.logInButton')}
          </LoadingButton>
        </form>

        <Grid className='buttons-bottom row'>
          {showCode ? (
            <Button
              variant='standard'
              onClick={() => {
                setShowCode(false);
                setShowErrorDevice(false);
                setShowError(false);
                setForm({});
              }}
            >
              {t('common.enterWithOtherUser')}
            </Button>
          ) : (
            <>
              <Button
                variant='standard'
                className={`recover-button ${showError ? 'blink' : ''}`}
                onClick={() => navigate('/recover')}
              >
                {t('common.recoverPassword')}
              </Button>
              <Button variant='standard' onClick={handleClickRegisterLink}>{t('common.signIn')}</Button>
            </>
          )}
        </Grid>
      </Grid>

      <DialogMessage
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={t('common.registerTitle')}
        message={<RawMarkup content={modalMessage} />}
        showCloseButton
      />
    </>
  );
}

export default LoginForm;
