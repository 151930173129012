import React, { Fragment, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Icon,
  Link,
  Skeleton,
  Snackbar,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import {
  AccessTime,
  AirlineSeatReclineNormal,
  ArrowBack,
  ArrowForward,
  ArrowForwardIos,
  AttachMoney,
  Close,
  ConfirmationNumber,
  ExpandMore,
  Info,
  LabelImportant,
  Luggage,
  PublishedWithChanges,
  Restaurant,
  Train
} from '@mui/icons-material';
import Image from '../../common/Image';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { getClientIp } from '../../../store/services/IpServices';
import { connect } from 'react-redux';
import { startCheckoutAction } from '../../../store/actions';
import Preloader from '../../common/Preloader';
import { LoadingButton } from '@mui/lab';
import TrainScales from './TrainScales';
import { AMENITIES, FOOD_CONDITIONS, LUGGAGE_CONDITIONS } from '../../../utils/trainsData';
import { useClientData } from '../../../context/ClientContext';
import TranslatedText from '../../common/TranslatedText';
import ReportIcon from '@mui/icons-material/Report';
import { Stack } from '@mui/system';

const FARE_CLASSES = [
  {
    code: 'STANDARD',
    text: 'standard'
  },
  {
    code: 'PRIMERA',
    text: 'primera'
  }
];

const FLEXIBLE_TYPES = {
  BEST_PRICE: 'bestPrice',
  NON_FLEX: 'noFlexible',
  SEMI_FLEX: 'semiFlexible',
  FULL_FLEX: 'fullFlexible'
};

const BAGGAGE_MESSAGE_FARE = {
  STANDARD: {
    IRYO: {
      carrier: 'Iryo',
      messages: [
        'results.trains.baggageFareMessageIryoInicial',
        'results.trains.baggageFareMessageIryoSingular'
      ],
      marketingCarriers: ['OUIGOESP', 'ITALO']
    },
    OUIGOESP: {
      carrier: 'Ouigo',
      messages: ['results.trains.baggageFareMessageOuigoEssential'],
      marketingCarriers: ['IRYO', 'ITALO']
    },
    ITALO: {
      carrier: 'Italo',
      messages: ['results.trains.baggageFareMessageItaloSmart'],
      marketingCarriers: ['OUIGOESP', 'IRYO']
    }
  }
}

const TabPanel = (props) => {
  const { children, value, index, className = '' } = props;

  return (
    <div hidden={value !== index} className={`tab-panel ${className}`}>
      {value === index && children}
    </div>
  );
}

function ResultsComponentTickets({
  client,
  dataForm,
  results,
  loading,
  apiCheckout,
  errorApiCheckout,
  ...props
}) {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentProduct, setClientData } = useClientData();
  const [selectedTab, setSelectedTab] = useState(0);
  const [onlyDirectTrains, setOnlyDirectTrains] = useState(false);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [openModalConditions, setOpenModalConditions] = useState(false);
  const [modalConditionsData, setModalConditionsData] = useState();
  const [openModalScales, setOpenModalScales] = useState(false);
  const [modalScalesData, setModalScalesData] = useState();
  const [expandedSegments, setExpandedSegment] = useState([]);
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [openModalAvailability, setOpenModalAvailability] = useState(false);
  const [modalAvailabilityData, setModalAvailabilityData] = useState([]);
  const [notAvailableTrains, setNotAvailableTrains] = useState([]);
  const [messageSnackbar, setMessageSnackbar] = useState();
  const [dataModalBaggageConditions, setDataModalBaggageConditions] = useState();

  useEffect(() => {
    setOpenModalAvailability(false);
    setNotAvailableTrains([]);
    setSelectedOffers([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const segments = Object.values(results?.offers?.trains?.legs || {});
    const length = segments?.length || 0;
    if (length > 0) {
      setSelectedOffers(Array.from({ length }));
      setExpandedSegment(Array.from({ length }, () => ({ open: false })));
      setSelectedTab(segments.findIndex((elem) => Object.keys(elem?.offers || {}).length > 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  useEffect(() => {
    if (loadingCheckout && apiCheckout && Object.keys(apiCheckout).length !== 0) {
        if (!errorApiCheckout) {
          const iframePath = params.tokenIframe ? `${params.tokenIframe}/` : '';
          if (apiCheckout.baseDomain) {
            window.location.href = apiCheckout.urlRedirect.replace(apiCheckout.baseDomain, apiCheckout.baseDomain + iframePath);
          } else {
            window.location.href = iframePath + `${window.location.origin}/${apiCheckout.urlRedirect}`;
          }
        } else {
          setLoadingCheckout(false);

          if (apiCheckout.legs) {
            handleErrorAvailability(apiCheckout.legs);
          }
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorApiCheckout, apiCheckout]);

  const handleErrorAvailability = (legs) => {
    setModalAvailabilityData(legs);
    setOpenModalAvailability(true);
  }

  const getSegmentData = (indexSegment, leg, offer, fareClass) => {
    const segments = Object.values(offer?.segments || {});
    const data = {
      indexSegment,
      leg,
      offer,
      options: offer?.fare_class?.[fareClass]?.options,
      fareClass
    };

    const marketingCarriers = [
      ...new Set(segments.map(elem => elem.marketingCarrier))
    ];

  const trainsAlertParisMilan = () => {
    const isOperatingCarrierTGVInoui = segments[0]?.operatingCarrier === "TGV INOUI";
    const isParisMilanSearch = /paris/i.test(params["*"]) && /milan/i.test(params["*"]);
    const isSingleSegment = segments.length === 1;

    if (isOperatingCarrierTGVInoui && isParisMilanSearch && isSingleSegment) {
          return  <Tooltip
                  title={<span style={{ color: 'red' }}>{t('results.trains.trainsAlertTooltip')}</span>}
                  placement="top"
              >
                  <ReportIcon fontSize="small" style={{ color: 'red' }} />
              </Tooltip>

    };}

    return <Grid container>
      <Grid item xs={7} md={6}>
        <Grid className='row'>
          <span className='time'>{dayjs(offer.departure).format('HH:mm')}</span>
          <ArrowForward className='arrow' />
          <span className='time'>{dayjs(offer.arrival).format('HH:mm')}</span>
        </Grid>
        <Grid className='row'>
          <Typography className='duration'>{offer.duration}</Typography>
          <Button onClick={() => handleClickModalScales(data)}>
            {segments.length - 1} {t(`results.trains.${segments.length === 2 ? 'change' : 'changes'}`)}
          </Button>
          {trainsAlertParisMilan()}
        </Grid>
        {segments?.map((elem, i) => (
          <Typography key={i}>
            {`${t(`results.trains.${(elem?.vehicle?.type_vehicle || '').toLowerCase()}`)} ${elem?.vehicle?.type} Nº: ${elem?.vehicle?.reference}`}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={true} className='providers-container column'>
        {marketingCarriers.map((elem, i) => {
          const name = elem.replaceAll(' ', '');
          return <Image img={`providersTrains/${name}.png`} alt='logo-provider' key={i} hideBrokenImage />
        })}
      </Grid>
    </Grid>
  }

  const handleClickOffer = (data) => {
    let selectedOffersAux = [...selectedOffers];
    if (data.indexSegment > -1) {
      if (selectedOffersAux?.filter(elem => elem)?.length === 0
        || selectedOffersAux[data.indexSegment]?.firstOption
      ) {
        data.firstOption = true;

        selectedOffersAux = selectedOffersAux.map(elem => {
          if (elem?.supplier !== data.supplier) {
            return undefined;
          }

          return elem;
        });
      }

      if (data) {
        const expandedSegmentsAux = Array.from({ length: expandedSegments?.length || 0 }, () => false);
        if (data.options) {
          expandedSegmentsAux[data.indexSegment] = true;
        }
        setExpandedSegment(expandedSegmentsAux);

        const noSeats = data.options?.find(elem => elem.id === data.optionId && !elem.seats_left);
        if (noSeats) {
          setMessageSnackbar(t('results.trains.notAvailableSeats'));
        }
      }

      selectedOffersAux[data.indexSegment] = data;

      setSelectedOffers(selectedOffersAux);
    }
  }

  const getCurrency = () => {
    return currentProduct?.config?.CURRENCY_TO || currentProduct?.config?.currency_to || 'USD';
  }

  const formatPrice = (price) => {
    return parseInt(
      Math.sign(price || 0) *
      Math.floor(
        Math.abs(price || 0) + 0.5
      )
    );
  }

  const getPrice = (option) => {
    return option?.prices?.api?.values?.search_price;
  }

  const handleClickShowConditionsBaggage = (fareClass, marketingCarrier) => {
    let marketingCarriers = [
      marketingCarrier,
      ...(BAGGAGE_MESSAGE_FARE[fareClass]?.[marketingCarrier]?.marketingCarriers || [])
    ];
    marketingCarriers = marketingCarriers.map(elem => ({
      name: BAGGAGE_MESSAGE_FARE[fareClass]?.[elem]?.carrier,
      messages: BAGGAGE_MESSAGE_FARE[fareClass]?.[elem]?.messages || []
    }));
    setDataModalBaggageConditions(marketingCarriers);
  }

  const getBaggageMessageFare = (fare) => {
    const marketingCarriers = Object.values(fare?.offer?.segments || {})
      ?.map(elem => elem.marketingCarrier);

    const marketingCarrier = marketingCarriers.find(elem => BAGGAGE_MESSAGE_FARE[fare?.fareClass]?.[elem]);
    if (marketingCarrier) {
      return <div className='message-fare-container'>
        <Typography className='message-fare'>
          *{t('results.trains.baggageMessageFare')}
        </Typography>
        <Button
          className='message-fare-button'
          size='small'
          onClick={() => handleClickShowConditionsBaggage(fare?.fareClass, marketingCarrier)}
        >
          {t('results.trains.seeConditions')}
        </Button>
      </div>
    }
  }

  const getPricesData = (indexSegment, leg, offer, fareClass) => {
    const offers = offer?.fare_class?.[fareClass.code];
    const options = (offers?.options || []).filter(elem => elem);
    if (offers && options.length > 0) {
      const cheapestOption = options.find(elem => elem?.id === offers?.cheapest);
      const offerAux = {...offer};
      delete offerAux.fare_class;
      const sortedOptions = options.sort((a, b) => getPrice(a) - getPrice(b));
      const data = {
        indexSegment,
        leg,
        supplier: cheapestOption?.supplier,
        offer: offerAux,
        fareClass: fareClass.code,
        options: sortedOptions,
        optionId: cheapestOption?.id || sortedOptions?.[0]?.id
      };
      const isSelected = selectedOffers?.[indexSegment]?.offer.id === offer.id
        && selectedOffers?.[indexSegment]?.fareClass === fareClass.code;
      const price = getPrice(cheapestOption);
      return <Grid className='offer-button-container column'>
        <Button
          className={`offer-button ${isSelected && 'selected-offer'}`}
          variant='outlined'
          onClick={() => handleClickOffer(data)}
        >
          {getCurrency()} {formatPrice(price)}
        </Button>

        {isSelected && getBaggageMessageFare(selectedOffers?.[indexSegment])}
      </Grid>
    }
  }

  const getHighlightContainer = (segment, indexSegment) => {
    let offers = Object.values(segment?.offers || {})
      .filter(elem => !notAvailableTrains.includes(elem.id));

    if (dataForm?.tripType?.includes('roundTrip')) {
      const firstOption = selectedOffers.find(elem => elem?.firstOption);
      if (firstOption?.indexSegment !== indexSegment) {
        const supplier = firstOption?.supplier;
        if (supplier) {
          offers = offers.filter(elem => (
            Object.values(elem?.fare_class || {})?.[0]?.options?.[0]?.supplier === supplier
          ));
        }
      }
    }

    if (onlyDirectTrains) {
      offers = offers.filter(elem => Object.values(elem?.segments || {})?.length === 1);
    }

    const showNoTrains = offers.length === 0 ? true : false;
    if (showNoTrains) {
      return <Grid className='no-direct-trains-message'>
        {t('results.trains.noDirectTrains')}
      </Grid>;
    } else {
      offers = offers.filter(offer => {
        const options = Object.values(offer?.fare_class || {}).flatMap(elem => elem.options);
        return options.filter(elem => elem).length > 0;
      })
      return offers.slice(0, 22).map((offer, i) => {
        const isCheapest = offer.id === segment.tags.cheapest;
        const isRecommended = offer.id === segment.tags.cheapest && offer.id === segment.tags.fastest;
        const firstFareClass = FARE_CLASSES.find(fareClass => offer?.fare_class?.[fareClass.code]);
        const legTitle = Object.keys(results?.offers?.trains?.legs)[indexSegment];
        const leg = {
          ...segment.leg,
          leg: legTitle
        }

        return <Fragment key={i}>
          {(isCheapest || isRecommended) && (
            <Grid className='recommended-container row'>
              <LabelImportant fontSize='small' />
              {isRecommended ? t('results.trains.recommendedTrip') : t('results.trains.mostEconomical')}
            </Grid>
          )}
          <Grid container className={`highlight-container ${(isCheapest || isRecommended) && 'recommended'}`} key={i}>
            <Grid item xs={7} md={6} className='segment-data'>
              {getSegmentData(indexSegment, leg, offer, firstFareClass)}
            </Grid>
            {FARE_CLASSES.map((fareClass, j) => (
              <Grid item key={j} xs={true} className='prices-container column'>
                {getPricesData(indexSegment, leg, offer, fareClass)}
              </Grid>
            ))}
          </Grid>
        </Fragment>
      });
    }
  }

  const getTotalPrice = (offers) => {
    const total = offers.reduce((acc, current) => {
      const selectedOffer = current?.options?.find(elem => elem.id === current.optionId);
      if (selectedOffer) {
        return acc + getPrice(selectedOffer);
      }

      return acc;
    }, 0);

    return total;
  }

  const compareConditions = (conditionA, conditionB) => {
    return conditionA.name === conditionB.name
      && conditionA.large_description === conditionB.large_description;
  }

  const getLargeDescriptions = (offer) => {
    if (offer?.conditions) {
      let conditions = Array.isArray(offer?.conditions)
        ? offer?.conditions
        : [offer?.conditions];
      conditions = conditions.filter((elem, i) => (
        conditions.findIndex(obj => compareConditions(obj, elem)) === i
      ));
      return conditions?.map((condition, i) => (
        <Fragment key={i}>
          <Typography className='title mb-05' variant='h3'>
            {condition.name}
          </Typography>
          <TranslatedText
            text={condition.large_description}
            language={currentProduct?.config?.LOCALE || 'es'}
          />
        </Fragment>
      ));
    }
  }

  const getTicketingConditions = (offer) => {
    if (offer?.ticketingOptions) {
      let ticketingOptions = Array.isArray(offer?.ticketingOptions)
        ? offer?.ticketingOptions
        : [offer?.ticketingOptions];
      ticketingOptions = ticketingOptions.slice(0, 1);
      return ticketingOptions?.map((condition, i) => {
        let translatedCondition = condition;
        if (translatedCondition === i18n.getFixedT('es')('results.trains.ticketMail')) {
          translatedCondition = t('results.trains.ticketMail');
        } else if (translatedCondition === i18n.getFixedT('es')('results.trains.noPrintTicket')) {
          translatedCondition = t('results.trains.noPrintTicket');
        } else if (translatedCondition === i18n.getFixedT('es')('results.trains.downloadTicket')) {
          translatedCondition = t('results.trains.downloadTicket');
        }

        return <Grid className='row condition' key={i}>
          <ConfirmationNumber fontSize='small' />
          {translatedCondition}
        </Grid>
      });
    }
  }

  const getLuggageConditions = (offer) => {
    if (offer) {
      const conditions = LUGGAGE_CONDITIONS[offer?.marketing_carrier]?.[offer?.name];
      if (conditions) {
        return <Grid className='row condition'>
          <Luggage fontSize='small' />
          <Grid className='luggage-condition'>
            {conditions.map((condition, i) => (
              <Typography key={i} dangerouslySetInnerHTML={{ __html: t(condition) }} />
            ))}
          </Grid>
        </Grid>
      }
    }
  }

  const getFoodConditions = (offer) => {
    if (offer) {
      const condition = FOOD_CONDITIONS[offer?.marketing_carrier]?.[offer?.name];
      if (condition) {
        return <Grid className='row condition'>
          <Restaurant fontSize='small' />
          {t(condition)}
        </Grid>
      }
    }
  }

  const getSeatReservationConditions = (segments) => {
    let conditionFlag = false;
    let condition = '';
    segments?.forEach(elem => {
      if (condition && condition !== elem.seatReservationConditions) {
        conditionFlag = true;
      }
      condition = elem.seatReservationConditions;
    });

    if (conditionFlag) {
      condition = t('results.trains.variableSeat');
    } else if (condition === i18n.getFixedT('es')('results.trains.seatReservationIncluded')) {
      condition = t('results.trains.seatReservationIncluded');
    }

    if (condition) {
      return <Grid className='row condition'>
        <AirlineSeatReclineNormal fontSize='small' />
        <TranslatedText text={condition} language={currentProduct?.config?.LOCALE || 'es'} isHtml={false} />
      </Grid>
    }
  }

  const getMinorsMessage = () => {
    const passengers = params['*']?.split('/')?.[6]?.split('-');
    const minorsFiveYears = passengers?.find(elem => parseInt(elem) <= 5);
    if (minorsFiveYears) {
      return <Typography dangerouslySetInnerHTML={{ __html: t('results.trains.travelFreeMinorsTickets') }} />
    }
  }

  const getConditionIcon = (condition) => {
    switch (condition.type) {
      case 'refund':
        return <AttachMoney fontSize='small' />;
      case 'change':
        return <PublishedWithChanges fontSize='small' />;
      default:
        return '';
    }
  }

  const getConditions = (selectedOffer) => {
    if (selectedOffer?.conditions) {
      let conditions = Array.isArray(selectedOffer?.conditions)
        ? selectedOffer?.conditions
        : [selectedOffer?.conditions];

      conditions = conditions.reduce((acc, current) => {
        const index = acc?.findIndex(elem => (
          JSON.stringify(elem.short_description) === JSON.stringify(current.short_description)
        ));
        if (index > -1) {
          if (!acc[index].names.includes(current.name)) {
            acc[index].names.push(current.name);
          }
        } else {
          acc.push({
            names: [current.name],
            short_description: current.short_description
          });
        }

        return acc;
      }, []);

      return conditions?.map((elem, i) => (
        <Fragment key={i}>
          {elem.names.map((name, z) => (
            <Grid key={z} item xs={12} className='type-title'>
              {name}
            </Grid>
          ))}
          {elem.short_description.length > 0 && (
            <Grid item xs={12} className='short-conditions'>
              {elem.short_description.map((condition, j) => (
                <div className='row condition' key={j}>
                  {getConditionIcon(condition)}
                  <Typography>
                    {condition.title && <p>{condition.title}</p>}
                    {condition.subtitle && <p>{condition.subtitle}</p>}
                  </Typography>
                </div>
              ))}
            </Grid>
          )}
        </Fragment>
      ));
    }
  }

  const handleClickModalScales = (offer) => {
    setModalScalesData(offer);
    setOpenModalScales(true);
  }

  const getPassengersTitle = () => {
    const isSelected = selectedOffers.some(elem => elem);
    if (isSelected) {
      const passengers = [
        dataForm?.adults ? `${dataForm.adults} ${dataForm.adults === 1 ? t('multiSearch.trains.adult') : t('multiSearch.trains.adults')}` : '',
        dataForm?.minors ? `${dataForm.minors} ${dataForm.minors === 1 ? t('multiSearch.trains.young') : t('multiSearch.trains.youngs')}` : '',
        dataForm?.seniors ? `${dataForm.seniors} ${dataForm.seniors === 1 ? t('multiSearch.trains.senior') : t('multiSearch.trains.seniors')}` : ''
      ].filter(elem => elem).join(', ');

      return <Typography className='passengers'>{passengers}</Typography>;
    }

    return '';
  }

  const handleChangeFlexibleOption = (segmentIndex, optionId) => {
    if (selectedOffers?.[segmentIndex]) {
      const selectedOffersAux = JSON.parse(JSON.stringify(selectedOffers));
      selectedOffersAux[segmentIndex].optionId = optionId;
      setSelectedOffers(selectedOffersAux);

      const noSeats = selectedOffersAux[segmentIndex].options?.find(elem => elem.id === optionId && !elem.seats_left);
      if (noSeats) {
        setMessageSnackbar(t('results.trains.notAvailableSeats'));
      } else {
        setMessageSnackbar(null);
      }
    }
  }

  const handleExpandSegment = (index) => {
    const expandedSegmentsAux = [...expandedSegments];
    expandedSegmentsAux[index] = !expandedSegmentsAux[index];
    setExpandedSegment(expandedSegmentsAux);
  }

  const handleChangeSelectedTab = (index) => {
    setSelectedTab(index);
    const expandedSegmentsAux = Array.from({ length: expandedSegments?.length || 0 }, () => false);
    if (selectedOffers[index] && selectedOffers[index]?.options) {
      expandedSegmentsAux[index] = true;
    }
    setExpandedSegment(expandedSegmentsAux);
  }

  const getSegmentsResume = (options, fareClass, offer, isModal) => {
    const selectedOption = options?.find(elem => elem.code === fareClass || elem.name === fareClass);
    const segments = Object.values(offer?.segments || {});

    return <TrainScales
      segments={segments}
      fareFeatures={selectedOption?.fare_features}
      isModal={isModal}
    />;
  }

  const handleOpenConditionsModal = (data) => {
    const selectedOffer = data?.options?.find(option => option.id === data.optionId);
    const segments = Object.values(data?.offer?.segments || {});
    if (selectedOffer) {
      setOpenModalConditions(true);
      setModalConditionsData({
        offer: selectedOffer,
        segments
      });
    }
  }

  const getAmenitiesIcons = (fareFeatures, includeNames = false, limit = -1) => {
    let filteredAmenities = fareFeatures?.map(elem => ({
      ...elem,
      icon: AMENITIES.find(amenity => amenity.code === elem.code)?.icon
    })) || [];

    if (!includeNames) {
      filteredAmenities = filteredAmenities.filter(elem => elem.icon);
    }

    if (limit > -1) {
      filteredAmenities = filteredAmenities.slice(0, limit);
    }

    return filteredAmenities?.map((amenity, i) => (
      <div className='row amenity' key={i}>
        {amenity.icon && (
          <Icon fontSize='small'>{amenity.icon}</Icon>
        )}
        {includeNames && <Typography>{amenity.name}</Typography>}
      </div>
    ));
  }

  const toFixedCustom = (input, decimals) => {
    const arr = (input.toString()).split(".");
    if (arr.length === 1) {
      return input;
    }

    const int = arr[0],
      max = arr[1].length,
      dec = arr[1].substr(0, decimals > max ? max : decimals);
    return decimals === 0 ? int : [int, "." , dec].join("");
  }

  const handleClickBeforeAfter = (add, index) => {
    let [directTrain, roundTrip, origins, destinations, dates, times, passengers] = params['*']?.split('/');
    const timesSplit = times.split(',');
    let newDates = dates.split(',').map((date, i) => (
      dayjs(`${date} ${timesSplit[i]}`, 'YYYY MM DD HHmm')
    ));
    if (add) {
      newDates[index] = newDates[index].add(2, 'hours');
    } else {
      newDates[index] = newDates[index].subtract(2, 'hours');
    }
    dates = newDates.map(date => date.format('YYYY-MM-DD')).join(',');
    times = newDates.map(date => date.format('HHmm')).join(',');
    const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
    navigate(iframePath + `/trenes/resultados/tickets/${[directTrain, roundTrip, origins, destinations, dates, times, passengers].join('/')}`);
  }

  const handleClickReserve = async (offers) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const accessToken = localStorage.getItem('jwt');
    const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;

    if (user && accessToken && tokenCheckout) {
      setLoadingCheckout(true);

      const passengers = params['*']?.split('/')[6];
      const clientIp = await getClientIp();
      const urlRedirect = location.pathname;

      const items = offers
        .filter(elem => elem)
        .map(elem => {
          const offer = elem.options.find(option => option.id === elem.optionId);
          return {
            leg: elem.leg.leg,
            supplier: offer.supplier,
            x_correlation_id: offer.x_correlation_id,
            location: offer.location
          }
        });

      const dataProduct = {
        urlRedirect,
        supplier: null,
        ip: clientIp,
        x_correlation_id: results.x_correlation_id,
        travelers: passengers.split('-').map(elem => ({ age: elem })),
        items,
        currency: currentProduct?.config?.CURRENCY_TO || currentProduct?.config?.currency_to || 'USD'
      };

      const requestData = {
        module: 'trains',
        productType: 'point-to-point',
        dataProduct,
        username: user?.username
      };

      props.startCheckout(accessToken, tokenCheckout, requestData);
    } else {
      const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
      if (iframePath) {
        localStorage.clear();
        setClientData(null);
        navigate(iframePath);
      } else {
        navigate('/login', { state: { expiredSession: true } });
      }
    }
  }

  const handleClickModifySelection = (startCheckout = false) => {
    const notAvailableTrainsAux = modalAvailabilityData.map((elem) => elem.id);
    const selectedOffersAux = selectedOffers?.map((elem) => (
      notAvailableTrainsAux.includes(elem?.offer?.id) ? undefined : elem
    ));

    setNotAvailableTrains(current => ([
      ...current,
      ...notAvailableTrainsAux
    ]));
    setSelectedOffers(selectedOffersAux);
    setOpenModalAvailability(false);

    if (startCheckout) {
      handleClickReserve(selectedOffersAux);
    }
  }

  const handleCloseSnackbar = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessageSnackbar(null)
  }

  const isDisabledReserveButton = () => (
    selectedOffers.some(elem => {
      const noSeats = elem?.options?.find(option => option.id === elem.optionId && !option.seats_left)
      return !elem || noSeats
    })
  )

  return (
    <>
      <Container>
        <Grid className='results-component' container spacing={3} columns={24}>
          <Grid item xs={24} lg={14} xl={16} className='left-side'>
            {loading ? (
              <Grid style={{ padding: 10 }}>
                <Box className='row mb-1' style={{ width: '50%' }}>
                  <Skeleton variant="circular" width={30} height={30} className='mr-05' />
                  <Skeleton variant='rounded' height={45} style={{ flex: 1 }} />
                </Box>
                <Skeleton className='mb-1' variant='rounded' width='75%' height={25} />
                <Skeleton className='mb-1' variant='rounded' width='100%' height={80} />
                <Skeleton className='mb-1' variant='rounded' width='100%' height={80} />
                <Skeleton variant='rounded' width='100%' height={80} />
              </Grid>
            ) : (
              <Grid>
                <Tabs
                  className='results-tabs'
                  variant='scrollable'
                  scrollButtons='auto'
                  value={selectedTab}
                  onChange={(e, value) => handleChangeSelectedTab(value)}
                >
                  {Object.values(results?.offers?.trains?.legs || {})?.map((elem, i) => {
                    const isSelected = selectedOffers.find(offer => offer?.segmentId === elem.id);
                    const disabled = Object.keys(elem.offers).length === 0;
                    return <Tab
                      key={i}
                      value={i}
                      sx={{ minWidth: { xs: '100%', md: Object.keys(results?.offers?.trains?.legs || {}).length === 1 ? '100%' : '50%' } }}
                      className={isSelected && 'selected-offer'}
                      disabled={disabled}
                      label={<Grid container>
                        <Grid item xs={disabled ? 7 : 12} className='row'>
                          <Train className='train-icon' />
                          <Grid>
                            <Typography className='row destination'>
                              {t('results.trains.segment')} {i + 1}:&nbsp;
                              <span className='row'>
                                <span>{elem?.leg?.origin?.label}</span>
                                <ArrowForward fontSize='small' />
                                <span>{elem?.leg?.destination?.label}</span>
                              </span>
                            </Typography>
                            <Typography className='row'>
                              <AccessTime fontSize='small' />
                              <span className='date'>
                                {elem?.leg?.departure ? dayjs(elem?.leg?.departure).format('ddd DD MMM YY') : null}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                        {disabled && (
                          <Grid item xs={5} className='row'>
                            <div className='message-no-results'>
                              {t('results.trains.noResultsSegment')}
                            </div>
                          </Grid>
                        )}
                      </Grid>}
                    />
                  })}
                </Tabs>

                {Object.values(results?.offers?.trains?.legs || {})?.map((elem, i) => (
                  <TabPanel value={selectedTab} index={i} key={i}>
                    <Grid className='title-container row'>
                      <Typography className='title row'>
                        {t('results.trains.selectSegment')} {i + 1}:&nbsp;
                        <b className='row'>
                          {elem?.leg?.origin?.label}
                          <ArrowForward fontSize='small' />
                          {elem?.leg?.destination?.label}
                        </b>
                      </Typography>
                      <Grid className='filters row'>
                        {t('results.trains.filter')}
                        <Button
                          variant='outlined'
                          size='small'
                          className={onlyDirectTrains ? 'selected' : ''}
                          onClick={() => setOnlyDirectTrains(!onlyDirectTrains)}
                        >
                          {t('results.trains.directs')}
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid container className='headers-container'>
                      <Grid item xs={7} md={6} className='header schedule-header row'>
                        <Button size='small' className='mr-2' onClick={() => handleClickBeforeAfter(false, i)}>
                          <ArrowBack fontSize='small' className='mr-05' /> {t('results.trains.earlier')}
                        </Button>
                        <AccessTime className='mr-05' />
                        {t('results.trains.schedules')}
                        <Button size='small' className='ml-2' onClick={() => handleClickBeforeAfter(true, i)}>
                          {t('results.trains.later')} <ArrowForward fontSize='small' className='ml-05' />
                        </Button>
                      </Grid>
                      {FARE_CLASSES.map((elem, i) => (
                        <Grid item xs={true} key={i} className='header row'>{t(`results.trains.fareClasses.${elem.text}`)}</Grid>
                      ))}
                    </Grid>

                    {getHighlightContainer(elem, i)}
                  </TabPanel>
                ))}
              </Grid>
            )}
          </Grid>

          <Grid item xs={24} lg={10} xl={8} className='right-side'>
            <Card>
              <CardHeader
                title={selectedOffers.some(elem => elem)
                  ? <span style={{ fontSize: 15 }}>{t('results.trains.totalTickets')}</span>
                  : <span>{t('results.trains.noTicketsSelected')}</span>
                }
                subheader={!selectedOffers.some(elem => elem)
                  ? t('results.trains.selectTicketToContinue')
                  : <div className='column'>
                      <Typography>
                        <span className='price-currency'>{getCurrency()}</span>
                        <span className='price'>{formatPrice(getTotalPrice(selectedOffers))}</span>
                      </Typography>
                      <span style={{ fontSize: 12 }}>({t('results.trains.notIncludeExpenses')})</span>
                    </div>
                }
              />
              <CardContent className='column'>
                {getPassengersTitle()}
                <LoadingButton
                  fullWidth
                  variant='contained'
                  color='secondary'
                  className='submit-button'
                  loading={loadingCheckout}
                  disabled={isDisabledReserveButton()}
                  onClick={() => handleClickReserve(selectedOffers)}
                >
                  {t('common.reserve')}
                </LoadingButton>
                {selectedOffers.some(elem => elem) ? (
                  selectedOffers?.map((elem, i) => {
                    if (elem) {
                      const selectedOffer = elem?.options?.find(option => option.id === elem.optionId);
                      const price = getPrice(selectedOffer);
                      const segments = Object.values(elem?.offer?.segments || {});
                      const marketingCarrier = [
                        ...new Set(segments.map(elem => elem.marketingCarrier))
                      ];

                      return <Grid className='segment-resume' key={i}>
                        <Accordion
                          key={i}
                          expanded={expandedSegments?.[i] || false}
                          onChange={() => handleExpandSegment(i)}
                        >
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <Grid>
                              <Grid className='row segment-info'>
                                <Train fontSize='small' className='train-icon' />
                                <Typography className='row destination'>
                                  {t('results.trains.segment')} {i + 1}:&nbsp;
                                  <span className='row'>
                                    {elem.leg.origin.label}
                                    <ArrowForward fontSize='small' />
                                    {elem.leg.destination.label}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid className='row'>
                                <Grid className='row date'>
                                  <AccessTime fontSize='small' />
                                  <span className='row'>
                                    {elem?.offer?.departure ? dayjs(elem.offer?.departure).format('ddd DD MMM YY') : null}
                                    &nbsp;-&nbsp;
                                    <span className='row'>
                                      {dayjs(elem?.offer?.departure).format('HH:mm')}
                                      <ArrowForward fontSize='small' />
                                      {dayjs(elem?.offer?.arrival).format('HH:mm')}
                                    </span>
                                  </span>
                                </Grid>
                                <Grid className='row amenities-container'>
                                  {getAmenitiesIcons(selectedOffer?.fare_features, false, 2)}
                                  {selectedOffer?.fare_features.length > 2 && `+${selectedOffer?.fare_features.length - 2}`}
                                </Grid>
                              </Grid>
                              <Grid className='row'>
                                <Grid className='row duration'>
                                  {elem?.offer?.duration}
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleClickModalScales(elem);
                                    }}
                                  >
                                    {segments.length - 1} {t(`results.trains.${segments.length === 2 ? 'change' : 'changes'}`)}
                                  </Button>
                                </Grid>
                                <Grid className='row providers-container'>
                                  {marketingCarrier.map((elem, i) => {
                                    const name = elem.replaceAll(' ', '');
                                    return <Image img={`providersTrains/${name}.png`} alt='logo-provider' key={i} hideBrokenImage />
                                  })}
                                </Grid>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            {getSegmentsResume(elem.options, elem.fareClass, elem.offer, false)}
                            {elem.options && (
                              <>
                                <Tabs
                                  className='flexible-container'
                                  variant='scrollable'
                                  scrollButtons='auto'
                                  value={selectedOffers[i].optionId}
                                  onChange={(e, value) => handleChangeFlexibleOption(i, value)}
                                >
                                  {elem.options.map((option, j) => {
                                    const isSelected = option.id === elem.optionId;
                                    const flexibleType = j === 0
                                      ? 'BEST_PRICE'
                                      : option?.fare_conditions?.[0]?.code?.replaceAll('-', '_');
                                    let price = getPrice(option);
                                    if (j > 0) {
                                      const bestPrice = getPrice(elem.options[0]);
                                      price -= bestPrice;
                                    }
                                    return <Tab
                                      key={j}
                                      value={option.id}
                                      className={`option ${isSelected ? 'selected-option' : ''}`}
                                      sx={{
                                        minWidth: {
                                          xs: '100%',
                                          sm: elem.options.length === 1 ? '100%' : '33%',
                                          lg: elem.options.length === 1 ? '100%' : '50%',
                                          xl: elem.options.length === 1 ? '100%' : '33%'
                                        }
                                      }}
                                      label={<Grid>
                                        <Typography className='name'>
                                          {flexibleType && (
                                            t(`results.trains.flexibleTypes.${FLEXIBLE_TYPES[flexibleType]}`)
                                          )}
                                        </Typography>
                                        <Typography className='value'>{j > 0 ? '+' : ''} {getCurrency()} {toFixedCustom(price, 2)}</Typography>
                                      </Grid>}
                                    />
                                  })}
                                </Tabs>
                                {elem.options.map(option => (
                                  <TabPanel value={selectedOffers[i].optionId} index={option.id} key={option.id}>
                                  <Grid className='resume-option' container rowSpacing={1}>
                                    {getConditions(option)}
                                    <Grid item xs={12}>
                                      <Button className='conditions-button' onClick={() => handleOpenConditionsModal(selectedOffers[i])}>
                                        <ArrowForwardIos /> {t('results.trains.seeConditions')}
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  </TabPanel>
                              ))}
                              </>
                            )}
                          </AccordionDetails>
                        </Accordion>
                        <Grid className='accordion-footer'>
                          <Grid className='row price'>
                              <Grid className='row'>
                                <b>{t('results.trains.totalSegment')} {i + 1}</b>
                                <Tooltip title={`${t('results.trains.totalByPassengers')}. ${t('results.trains.notIncludeExpenses')}.`}>
                                  <Info className='info-icon' fontSize='small' />
                                </Tooltip>
                              </Grid>
                              <Typography className='value'>
                                {getCurrency()} {formatPrice(price)}
                              </Typography>
                            </Grid>
                        </Grid>
                      </Grid>
                    }

                    return null;
                  })
                ) : (
                  <Image img='noTickets.png' alt='no-tickets' className='no-tickets' />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={openModalScales}
        onClose={() => setOpenModalScales(false)}
        className='closable-modal scales-modal'
        maxWidth='sm'
        fullWidth
      >
        <Fab size="small" onClick={() => setOpenModalScales(false)} className='close-button'>
          <Close fontSize='small' />
        </Fab>

        <DialogTitle variant='h5'>{t('results.trains.tripSummary')}</DialogTitle>

        <DialogContent>
          <Grid className='row segment-title'>
            <Train fontSize='small' className='train' />
            {t('results.trains.segment')} {modalScalesData?.indexSegment + 1}: {modalScalesData?.leg?.origin.label}
            <ArrowForward fontSize='small' className='arrow' />
            {modalScalesData?.leg?.destination.label}
          </Grid>
          <Grid className='row date'>
            <AccessTime fontSize='small' className='time' />
            {dayjs(modalScalesData?.offer?.departure).format('ddd DD MMM YY')}
            &nbsp;- {dayjs(modalScalesData?.offer?.departure).format('HH:mm')}
            <ArrowForward fontSize='small' className='arrow' />
            {dayjs(modalScalesData?.offer?.arrival).format('HH:mm')}
          </Grid>
          <Grid className='row duration'>
            {modalScalesData?.offer?.duration}&nbsp;
            <span className='scales'>
              {modalScalesData?.offer?.segments && (
                <>
                  {Object.keys(modalScalesData?.offer?.segments).length - 1} {t(`results.trains.${Object.keys(modalScalesData?.offer?.segments).length === 2 ? 'change' : 'changes'}`)}
                </>
              )}
            </span>
          </Grid>
          {getSegmentsResume(modalScalesData?.options, modalScalesData?.fareClass, modalScalesData?.offer, true)}
          <div className='conditions'>
            {getSeatReservationConditions(Object.values(modalScalesData?.offer?.segments || {}))}
          </div>
          <Stack direction="column" spacing={1} sx={{marginTop:1}}>
            <Typography>{t('results.trains.suggestedTime1')}</Typography>
            <Typography>{t('results.trains.suggestedTime2')}</Typography>
            <Typography>{t('results.trains.suggestedTime3')}</Typography>
          </Stack>

        </DialogContent>
      </Dialog>

      <Dialog
        open={openModalConditions}
        onClose={() => setOpenModalConditions(false)}
        className='closable-modal conditions-modal'
        maxWidth='sm'
        fullWidth
      >
        <Fab size="small" onClick={() => setOpenModalConditions(false)} className='close-button'>
          <Close fontSize='small' />
        </Fab>

        <DialogTitle variant='h5'>{t('results.trains.conditions')}</DialogTitle>

        <DialogContent className='conditions'>
          <div className='mb-1'>
            {getLargeDescriptions(modalConditionsData?.offer)}
          </div>
          {modalConditionsData?.offer?.conditions?.[0]?.terms && (
            <div className='mb-05'>
              <Link href={modalConditionsData?.offer?.conditions?.[0]?.terms} target="_blank">
                {t('results.trains.termsAndConditions')}
              </Link>
            </div>
          )}
          {getSeatReservationConditions(modalConditionsData?.segments)}
          {getTicketingConditions(modalConditionsData?.offer)}
          {getLuggageConditions(modalConditionsData?.offer)}
          {getFoodConditions(modalConditionsData?.offer)}
          {getMinorsMessage()}
        </DialogContent>
      </Dialog>

      <Dialog
        open={openModalAvailability}
        onClose={() => setOpenModalAvailability(false)}
        className='closable-modal availability-trains-modal'
        maxWidth='sm'
        fullWidth
      >
        <Fab size="small" onClick={() => setOpenModalAvailability(false)} className='close-button'>
          <Close fontSize='small' />
        </Fab>

        <DialogTitle variant='h5'>{t('results.trains.errorAvailability')}</DialogTitle>

        <DialogContent>
          <Typography>
            {modalAvailabilityData.length === 1 ? (
              t('results.trains.noAvailabilityTrain')
            ) : (
              t('results.trains.noAvailabilityTrains')
            )}
          </Typography>
          {modalAvailabilityData.map((elem, i) => {
            const departure = dayjs(elem.departure);
            const arrival = dayjs(elem.arrival);
            return <Grid className='mt-1' key={i} style={{ backgroundColor: "#f1f5ff", padding: 15, borderRadius: 16 }}>
              <Grid className='row destination'>
                <Train fontSize='small' className='train' />
                {elem.origin.label}
                <ArrowForward className='arrow' />
                {elem.destination.label}
              </Grid>
              <Grid className='row time'>
                <AccessTime fontSize='small' className='time-icon' />
                {departure.format('ddd DD MMM YY')} - {departure.format('HH:mm')}
                <ArrowForward className='arrow' />
                {arrival.format('HH:mm')}
              </Grid>
            </Grid>
          })}
          <Typography className='mt-1'>
            {modalAvailabilityData.length < selectedOffers.filter(elem => elem)?.length ? (
              t('results.trains.continueOthersTrains')
            ) : (
              t('results.trains.modifySelectionTrain')
            )}
          </Typography>
          <Grid
            className='row mt-1'
            style={{ justifyContent: modalAvailabilityData.length < selectedOffers.filter(elem => elem)?.length
              ? "space-between"
              : 'flex-end'
            }}
          >
            <Button variant='outlined' onClick={() => handleClickModifySelection()}>
              {t('results.trains.modifySelection')}
            </Button>

            {modalAvailabilityData.length < selectedOffers.filter(elem => elem)?.length && (
              <Button variant='contained' onClick={() => handleClickModifySelection(true)}>
                {t('results.trains.continue')}
              </Button>
            )}
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={Boolean(dataModalBaggageConditions)}
        onClose={() => setDataModalBaggageConditions(null)}
        className='closable-modal baggage-conditions-modal'
        maxWidth='sm'
        fullWidth
      >
        <Fab
          size="small"
          onClick={() => setDataModalBaggageConditions(null)}
          className='close-button'
        >
          <Close fontSize='small' />
        </Fab>

        <DialogTitle variant='h5'>
          {t('results.trains.baggageFareTitle', { carriers: dataModalBaggageConditions?.map(elem => elem.name).join(', ') })}
        </DialogTitle>

        <DialogContent>
          {dataModalBaggageConditions?.map((elem, i) => (
            <div className='carrier-container'>
              <Typography className='carrier-name'>{elem.name}</Typography>
              {elem.messages.map((message, j) => (
                <Typography className='message'>
                  {j === 0 && <span>{t('common.important').toUpperCase()}:&nbsp;</span>}
                  {t(message)}
                </Typography>
              ))}
            </div>
          ))}
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
        open={Boolean(messageSnackbar)}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {messageSnackbar}
        </Alert>
      </Snackbar>

      {loadingCheckout && (
        <Preloader
          addDots
          image={`sites/${client.client.name}/preloadTrenes.gif`}
          text={t('common.processingRequest')}
        />
      )}
    </>
  );
}

const mapStateToProps = reducers => {
  return reducers.trainsReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    startCheckout: (access, tokenCheckout, data) => dispatch(startCheckoutAction(access, tokenCheckout, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsComponentTickets);
