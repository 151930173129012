import { createTheme } from "@mui/material/styles";
import "suneditor/dist/css/suneditor.min.css";

const borderWidth = 2
const darkenHexColor = (hex, percentage) => {
  percentage = isNaN(percentage) ? 0 : (parseInt(percentage) / 100);
  hex = hex.replace("#", "");
  if (hex.length !== 6) {
    return hex;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const darken = (color) => Math.floor(color * (1 - percentage));
  const rDark = darken(r);
  const gDark = darken(g);
  const bDark = darken(b);

  const darkenedHex = `#${rDark.toString(16).padStart(2, "0")}${gDark.toString(16).padStart(2, "0")}${bDark.toString(16).padStart(2, "0")}`;
  return darkenedHex;
}

/* const lightenHexColor = (hex, percentage) => {
  percentage = isNaN(percentage) ? 0 : (parseInt(percentage) / 100);
  hex = hex.replace("#", "");
  if (hex.length !== 6) {
    return hex;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const lighten = (color) => Math.min(255, Math.floor(color + (255 - color) * percentage));
  const rLight = lighten(r);
  const gLight = lighten(g);
  const bLight = lighten(b);

  const lightenedHex = `#${rLight.toString(16).padStart(2, "0")}${gLight.toString(16).padStart(2, "0")}${bLight.toString(16).padStart(2, "0")}`;
  return lightenedHex;
} */

const createThemeFromData = (styles = {}) => {
  const theme = createTheme({
    typography: {
      fontFamily: styles?.fonts,
      fontSize: styles?.body?.fontSize,
    },
    palette: {
      primary: { main: styles?.brandPrimary?.value },
			secondary: { main: styles?.brandSecondary?.value },
      accent: { main: styles?.accentColor },
      default: { main: styles?.accentColor },
			textColor: { main: styles?.body?.color },
			background: { default: styles?.body?.background },
			text: { primary: styles?.body?.color },
    },
    components: {
      MuiTypography: {
        variants: [
          {
            props: { variant: "h1" },
            style: {
              color: styles?.headings?.h1?.color
            },
          },
          {
            props: { variant: "h2" },
            style: {
              color: styles?.headings?.color
            },
          },
          {
            props: { variant: "h3" },
            style: {
              color: styles?.headings?.color
            },
          },
          {
            props: { variant: "h4" },
            style: {
              color: styles?.headings?.color
            },
          },
          {
            props: { variant: "h5" },
            style: {
              color: styles?.headings?.color
            },
          },
          {
            props: { variant: "h6" },
            style: {
              color: styles?.headings?.color
            },
          }
        ],
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          },
        },
      },
      MuiAppBar: {
          styleOverrides: {
              root: {
                backgroundColor: styles?.navbar?.background,
                color: styles?.navbar?.color,
              }
          }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: styles?.tooltip?.background,
            color: styles?.tooltip?.color,
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
        variants: [
          {
            props: { variant: "contained" },
            style: {
              backgroundColor: styles?.buttons?.default?.background,
              color: styles?.buttons?.default?.color,
              "&:hover": {
                backgroundColor: styles?.buttons?.default?.hover?.background,
                color: styles?.buttons?.default?.hover?.color,
              }
            }
          },
          {
            props: { variant: "contained", color: "primary" },
            style: {
              backgroundColor: styles?.buttons?.primary?.background,
              color: styles?.buttons?.primary?.color,
              "&:hover": {
                backgroundColor: styles?.buttons?.primary?.hover?.background,
                color: styles?.buttons?.primary?.hover?.color,
              }
            }
          },
          {
            props: { variant: "contained", color: "secondary" },
            style: {
              backgroundColor: styles?.buttons?.secondary?.background,
              color: styles?.buttons?.secondary?.color,
              "&:hover": {
                backgroundColor: styles?.buttons?.secondary?.hover?.background,
                color: styles?.buttons?.secondary?.hover?.color,
              }
            }
          },

          {
            props: { variant: "contained", color: "default" },
            style: {
              backgroundColor: styles?.accentColor,
              color: "#fff",
              "&:hover": {
                backgroundColor: styles?.accentColorHover
              }
            },
          },
          {
            props: { color: "default", variant: "outlined" },
            style: {
              borderColor: styles?.accentColor,
              color: styles?.accentColor
            }
          },
          {
            props: { variant: "outlined" },
            style: {
              borderWidth: borderWidth,
              "&:hover": {borderWidth: borderWidth,}
            }
          }
        ],
      },
      MuiBox: {
        styleOverrides: {
          root: {
            borderRadius: 4,
          },
        },
      },
      MuiOutlinedInput: {
        root: {paddingLeft: 8}
      },
      MuiDialogTitle: {
        root: {borderBotom: '1px solid #ebebeb'}
      },
      MuiSelected: {
        backgroundColor: 'red'
      },
      MuiSkeleton: {
        styleOverrides: {
          root: {
            maxWidth: "100%"
          },
        },
      }
    },
  });

  return theme;
};

export const globalStyles = (theme, styles) => {
  return {
    body: {
      margin: 0,
      padding: 0,
      color: styles.body?.color,
      fontFamily: styles.fonts,
      fontWeight: styles.body?.fontWeight,
      lineHeight: styles.body?.lineHeight,
      // Zoho Bot
      "& .zsiq_floatmain": {
        right: "auto",
        left: 20,
        bottom: 20,

        "&.hidden": {
          visibility: 'hidden !important'
        },

        "& .zsiq_cnt": {
          left: '75px !important',

          "&::after": {
            left: -5,
            right: "auto"
          },

          "& .siqico-close": {
            right: "-10px !important",
            left: "auto"
          }
        }
      },
      "& .zls-sptwndw": {
        left: "15px !important"
      },
      // Prehome Gotrenes
      "& .prehome": {
        backgroundColor: "#fff",

        "& h1": {
          fontSize: "1.8rem",
          fontWeight: 500
        },

        "& h2": {
          fontSize: "1.4rem"
        },

        "& h4": {
          fontSize: "1rem"
        },

        "& p": {
          fontSize: "1rem"
        },

        "& .MuiButton-contained": {
          padding: "6px 16px",
          textAlign: "center",
          fontSize: 16
        },

        "& .hero": {
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        },

        "& .beneficios": {
          backgroundColor: "#155dff",
          color: "#fff",
          textAlign: "center",
          padding: "40px 0"
        },

        "& .registro": {
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "#fff",
          position: "relative",
          overflow: "hidden",
          padding: "40px 0",

          "& .img-contact": {
            position: "absolute",
            maxHeight: 470,
            right: 0,
            bottom: -25
          }
        },

        "@media (max-width: 899px)": {
          "& .logo-header": {
            maxWidth: 150
          },

          "& .hero": {
            backgroundSize: "120%",
            paddingTop: "40%"
          },

          "& .img-contact": {
            display: "none"
          }
        },

        "@media (min-width: 900px)": {
          "& h1": {
            fontSize: "2.4rem"
          },

          "& h2": {
            fontSize: "1.6rem"
          },

          "& h4": {
            fontSize: "1.2rem"
          },

          "& .hero": {
            backgroundPosition: "bottom center"
          },
        }
      },
      // Zoho Form Register
      "& .zoho-form-register": {
        "& .crm-web-to-entity-form": {
          width: "100%",
          padding: 25,
          margin: "0 auto",
          boxSizing: "border-box",
          background: "rgb(49 49 49 / 34%)",
          borderRadius: 20,
          textAlign: "left",
          direction: "ltr",

          "& .zcwf-title": {
            wordWrap: "break-word",
            margin: "0px 6px 10px",
            fontWeight: "bold",
            color: "#fff"
          },

          "& .wfrm-fld-dpNn": {
            display: "none"
          },

          "& .input-wrapper": {
            "& .MuiFormLabel-root": {
              color: "#fff",
              fontSize: 14,
              marginBottom: 2
            },

            "& .MuiInputBase-root": {
              backgroundColor: "#fff",
              marginTop: 3
            }
          },

          "& .captcha-button": {
            padding: 0,
            textDecoration: "underline",
            color: "#fff",
            fontSize: 16,
            marginTop: -8
          }
        }
      },
      "& .hero-thankyoupage-confirmation": {
        padding: "40px 0",
        backgroundSize: 'cover',
        backgroundPosition: 'top left',
        backgroundRepeat: "no-repeat",
        color: '#fff',
        minHeight: '60vh',
        display: "flex",

        "& .email": {
          color: "#ff5e52",

          "&:hover": {
            color: "#d75046"
          }
        },

        "@media (max-width: 599px)": {
          backgroundSize: "120%",
          paddingTop: "50%"
        },
      },
      "& .condiciones-generales": {
        padding: "40px 0",

        "& p": {
          marginBottom: 16
        },

        "& .MuiContainer-root": {
          maxWidth: "1350px !important"
        }
      },
      "& .MuiOutlinedInput-root:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
        border: "1px solid rgba(0, 0, 0, 0.23) !important"
      },
      "& .input-wrapper": {
        "& .required": {
          color: "#ff0000"
        },

        "& > div": {
          display: "inline-flex",

          "& > div .MuiFormControl-root, & > div .MuiInputBase-root": {
            width: "100%"
          }
        },

        "&.direction-column": {
          "& > div": {
            flexDirection: "column"
          }
        },

        "&.direction-row": {
          "& > div": {
            flexDirection: "row",

            "& .MuiFormLabel-root": {
              marginRight: 10,
              maxHeight: 48
            }
          }
        }
      },
      "& .MuiContainer-root": {
        "@media (max-width: 599px)": {
          padding: "0 10px"
        },

        "&:not(.MuiContainer-maxWidthSm):not(.MuiContainer-maxWidthMd)": {
          "@media (min-width: 1440px)": {
            maxWidth: 1440
          },

          "@media (min-width: 1580px)": {
            maxWidth: 1580
          }
        }
      },
      "& .MuiAppBar-root": {
        boxShadow: "none"
      },
      "& .MuiButton-outlined": {
        //borderWidth: styles?.borderWidth
        borderWidth: borderWidth
      },
      "& .site-header-logged": {
        color: styles.navbarTop?.color,
        background: styles.navbarTop?.background,

        "& .MuiButton-textPrimary": {
          color: styles.navbarTop?.color,
        },

        "& .MuiButton-outlined": {
          color: styles.navbarTop?.button?.color,
          borderColor: styles.navbarTop?.button?.color,
        }
      },
      "& .site-header": {
        "& a": {
          padding: "8px 8px 0 0",

          "& img": {
            maxWidth: 230,

            "@media(min-width: 900px) and (max-width: 1199px)": {
              maxWidth: "8rem"
            },
          }
        },
        "& .menu-list": {
          "@media(min-width: 900px) and (max-width: 1199px)": {
            gap: 0,

            "& .MuiListItem-root .MuiButton-root": {
              fontSize: 11
            }
          },
        },
        "& .MuiButton-outlined": {
          borderColor: "inherit",
          //borderWidth: styles?.borderWidth
          borderWidth: borderWidth
        },
        "& .MuiButton-root": {
          fontWeight: styles.navbar.fontWeight,
          fontSize: styles.navbar.fontSize,
          //borderWidth: styles?.borderWidth
          borderWidth: borderWidth
        }
      },
      "& .MuiTooltip-tooltip": {
        boxShadow: theme.shadows[1],
        fontSize: 13
      },
      "& img": {
        maxWidth: "100%"
      },
      "& .preloader": {
        position: "relative",

        "& .backdrop": {
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#ffffff80",
          zIndex: 1100,

          "& .content": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 288,
            maxWidth: "90vw",
            textAlign: 'center',
            background: 'white',
            padding: '40px 32px',
            borderRadius: 16,

            [theme.breakpoints.down('sm')]: {
              left: "5%",
              transform: "translate(0, -50%)",
              width: "90%",
              minWidth: "unset",
              maxWidth: "unset",
              padding: '40px 16px'
            },

            "& .text": {
              fontSize: 26,
              textAlign: "center",
              marginTop: 24,
              fontWeight: 500,
              color: styles.brandPrimary?.value,

              "& .dots-container": {
                textWrap: "nowrap",
                marginLeft: 5,

                "& .dots": {
                  margin: "0 2px"
                }
              }
            }
          }
        }
      },
      "& .grid-slider-item": {
        paddingLeft: 32,
        paddingRight: 32
      },
      "& .carousel-item": {
        borderRadius: styles.borderRadius,
        height: "250px",
        backgroundSize: "cover",
        backgroundPosition: "center"
      },
      "& .banner-item img": {
        borderRadius: styles.borderRadius
      },
      "& .banner-slider-section": {
        padding: "30px 0",

        "& .carousel-item": {
          aspectRatio: "16/8",
          width: "100%",
          minHeight: 160,
          height: "auto",

          "@media(min-width: 900px)": {
          minHeight: 180,
          aspectRatio: "16/3"
          }
        }
      },
      "& .banner-single-image": {
        marginBottom: 16,
        display: "flex",

        "&:last-of-type": {
          marginBottom: 0
        },

        "& *": {
          display: "flex",
          flex: 1
        }
      },
      "& .banner-single-images": {
        borderRadius: styles.borderRadius,
        position: "relative",
        width: "100%",
        paddingTop: "75%",
        backgroundSize: "cover",
        backgroundPosition: "center",

        "& img": {
          borderRadius: styles.borderRadiusSmall
        }
      },
      "& .banner-promotions-section": {
        backgroundColor: styles.body.backgroundAlt,
        paddingBottom: 30,
        paddingTop: 30,

        "& .react-multi-carousel-track": {
          paddingBottom: "10px !important",

          "& .react-multi-carousel-item": {
            padding: "0 16px",

            "& > .MuiPaper-root": {
              height: "100%"
            },

            "& .title-banner-promotions": {
              fontSize: '1.4em'
            }
          }
        },
      },
      "& .banner-offers-section": {
        paddingBottom: 30,
        paddingTop: 30,

        "& .react-multi-carousel-track": {
          paddingBottom: "10px !important",

          "& .react-multi-carousel-item": {
            padding: "0 16px",

            "& .banner-offer": {
              position: 'relative',
              height: "100%"
            },
            "& .discount-label": {
              position: 'absolute',
              left: 0,
              top: 0,
              borderRadius: '0 0 8px 0',
              color: styles.promoLabel.color,
              backgroundColor: styles.promoLabel.background,
              padding: '4px 8px'
            },
            "& .name-offer": {
              display: 'block',
              lineHeight: 1.4,
              minHeight: 54
            },

            "& .priceTextSecondary": {
              fontSize: 14
            }
          }
        }
      },
      "& .banner-image-section": {
        backgroundColor: styles.body.backgroundAlt,
        paddingBottom: 30,
        paddingTop: 30
      },
      "& .banner-featured-section": {
        paddingBottom: 30,
        paddingTop: 30,

        "& .react-multi-carousel-track": {
          paddingBottom: "10px !important",

          "& .react-multi-carousel-item": {
            padding: "0 16px"
          }
        },
      },
      "& .icon-favorite": {
        color: styles.favoriteIcon
      },
      "& .banner-slider": {
        "& .MuiTypography-root": {
          color: "#fff",
          textAlign: "center",

          "&:not(:last-of-type)": {
            marginBottom: 10
          }
        }
      },
      "& .container-slider": {
        paddingLeft: "10px",
        paddingRight: "10px"
      },
      "& .react-multi-carousel-list": {
        "& .react-multi-carousel-track": {
          padding: 0
        },

        "& .react-multiple-carousel__arrow": {
          backgroundColor: "#fff",
          padding: 4,
          margin: 0,
          minHeight: 30,
          minWidth: 30,
          boxShadow: "0px 0px 2px 0px gray",
          zIndex: 10,

          "&:hover": {
            opacity: "50%"
          },

          "&::before": {
            color: styles.brandPrimary.value,
            fontSize: 13,
            fontWeight: "bold"
          },

          "&.react-multiple-carousel__arrow--left": {
            left: "5px",

            "&::before": {
              right: "1px"
            }
          },

          "&.react-multiple-carousel__arrow--right": {
            right: "5px",

            "&::before": {
              left: "1px"
            }
          }
        },

        "& .react-multi-carousel-dot-list": {
          flexWrap: "wrap"
        }
      },
      "& .price": {
        color: styles.headings.color,
        fontSize: styles.headings.h1.fontSize,
        lineHeight: '28px',
      },
      "& .popover-menu .MuiPopover-paper": {
        boxShadow: "2px 2px 8px -4px rgba(0, 0, 0, 0.15)"
      },
      "& .nav-bar-accordion": {
        boxShadow: "none",
        borderTop: "1px solid #e1eaff",

        "&.Mui-expanded": {
          margin: 0
        },

        "& .MuiAccordionSummary-root": {
          display: "none"
        },

        "& .MuiAccordionDetails-root": {
          padding: "10px 0 0"
        }
      },
      "& .nav-bar": {
        backgroundColor: "#fff",
        width: "100%",

        "& .MuiContainer-root": {
          display: "flex",
          overflowX: "auto",

          "& .tab-item": {
            color: styles.searchItem.color,
            minHeight: "inherit",
            marginRight: 4,
            padding: "12px 20px 8px",
            display: "flex",
            flexDirection: "column",
            gap: 1,
            textDecoration: "none",
            fontWeight: "bold",
            borderBottom: "2px solid",
            borderColor: "transparent",
            fontSize: 16,

            "&.activeTab, &:hover": {
              borderColor: `${styles.searchItem.active.background}`,
              color: styles.searchItem.active.color
            },

            "& .custom-icon": {
              height: 20,
              marginRight: 4
            }
        },
        }
      },
      "& .homepage": { //estilos globales para todas las homepages
        "& .search-container": {
          backgroundColor: styles.search.container.background, //contenedor gris
          padding: "24px 0"
        },
        "& .search-box-container": {//contenedor azul homepage
          padding: 16,
          borderRadius: 16,
          overflow: "hidden",
        }
      },
      "& .landing-page": {
        "& .search-container": {
          backgroundColor: styles.search.container.background, //contenedor gris
          padding: "24px 0",
          marginBottom: 24
        },
        "& .search-box-container": {//contenedor azul homepage
          padding: 16,
          borderRadius: 16,
          overflow: "hidden",
        }
      },
      "& .search-box-container": {//contenedor azul
        backgroundColor: styles.search.background,
        color: styles.search.color,

        "& .search-box": { //MUI container
          "& .MuiGrid-item:first-of-type": {
            position: "relative"
          },

          "& .MuiStack-root": {
            paddingTop: 0
          },

          "& label": {
            color: styles.search.color,
            whiteSpace: "nowrap",
            fontSize: "14px",
          },

          "& .MuiInputBase-root": {
            backgroundColor: styles.search.inputs.background,
            fontSize: 14
          },

          "& .select-popover-field": {
            backgroundColor: styles.search.inputs.background,
            fontSize: 14,
            color: "#606060",
            borderRadius: "4px",
            padding: "0 13px",
            border: "1px solid #0000003b !important",

            "& > .row": {
              justifyContent: "space-between",

              "& > .row": {
                overflowX: "hidden",

                "& span": {
                  padding: 7.5,
                  textWrap: "nowrap",
                  overflowX: "hidden",
                  textOverflow: "ellipsis"
                },

                "& svg": {
                  color: "#0000008a"
                }
              }
            }
          },

          "& .MuiAutocomplete-root": {
            "& .MuiInputBase-root": {
              paddingLeft: 14
            }
          },

          "& .places-switch": {
            marginTop: 5,
            marginLeft: 0,
            [theme.breakpoints.down("md")]: {
              marginTop: 10
            },

            "& .MuiSwitch-switchBase:not(.Mui-checked)+.MuiSwitch-track": {
              backgroundColor: "#fff",
              opacity: 0.5
            },

            "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
              opacity: 0.8
            }
          },

          "& .places-input": {
            "&:hover": {
              "& .MuiIconButton-root": {
                display: "inline-flex"
              }
            },

            "& .MuiInputBase-input": {
              paddingLeft: 8
            },

            "& .MuiIconButton-root": {
              display: "none"
            }
          },

          "& .hide-input": {
            visibility: "hidden",
            position: "absolute",
            top: 36,
            paddingRight: 16
          }
        },

        "& .MuiAccordion-root::before": {
          display: "none"
        }
      },
      "& .header-ttoperadora": {
        "& .site-header-logged": {
          backgroundColor: styles.brandPrimary.value,
          color: "white",

          "& .MuiButton-outlined": {color: "white", borderColor: "white",}
        },
        "& .MuiButton-containedDefault": {color: styles.brandPrimary.value},
      },
      "& .train-search.search-box-container": {
        paddingBottom: 8,

        "& .resume-container": {
          paddingTop: 8,
          paddingBottom: 8,

          "& .back-button": {
            color: "#fff",
            marginRight: 30,

            "& .MuiSvgIcon-root": {
              marginRight: 5
            }
          },

          "& .destination, & .date": {
            paddingRight: 20,
            marginRight: 20,
            borderRight: "1px solid #155dff",
            textTransform: "capitalize",

            "& .MuiSvgIcon-root": {
              marginRight: 5,
              color: "#155dff"
            }
          },

          "& .pax": {
            "& .MuiSvgIcon-root": {
              marginRight: 5,
              color: "#155dff"
            }
          },

          "& .modify-button": {
            color: "#fff",
            borderColor: "#fff",
            marginLeft: 30,

            "& .MuiSvgIcon-root": {
              marginRight: 5
            }
          },
        },

        "& .MuiAccordion-root": {
          backgroundColor: styles.brandPrimary.value,
          margin: 0,
          boxShadow: "none",

          "& .MuiAccordionSummary-root": {
            display: "none"
          },

          "& .MuiAccordionDetails-root": {
            padding: 0
          }
        },

        "& .ticket-type-buttons": {
          marginRight: 16,

          "& .MuiButton-root": {
            color: "#fff",
            borderColor: "#fff",
            borderRadius: 0,
            padding: "5px 10px",

            "&.selected": {
              //backgroundColor: "#155dff",
              //borderColor: "#155dff"
              backgroundColor: styles.tabs.selected.background,
              color: styles.tabs.selected.color,
              borderColor: styles.tabs.selected.borderColor,

            },

            "& .MuiSvgIcon-root": {
              marginRight: 5
            },

            "&:first-of-type": {
              borderRadius: "25px 0 0 25px"
            },

            "&:last-of-type": {
              borderRadius: "0 25px 25px 0"
            }
          }
        },

        "& .trip-type-buttons": {
          "& .MuiButton-root": {
            color: "#fff",
            borderColor: "#fff",
            borderRadius: 25,
            padding: "5px 10px",

            "&.selected": {
              //backgroundColor: "#155dff",
              //borderColor: "#155dff",
              backgroundColor: styles.tabs.selected.background,
              color: styles.tabs.selected.color,
              borderColor: styles.tabs.selected.borderColor,
            },

            "&:not(:last-of-type)": {
              marginRight: 16
            }
          }
        },

        "& .MuiDivider-root": {
          backgroundColor: "#fff",
          height: 30,
          margin: "2px 16px 0 0"
        },

        "& .segment-container": {
          position: "relative",

          "&:last-of-type": {
            marginBottom: 12
          },

          "& .segment-number": {
            width: 30,
            height: 30,
            marginTop: 24,
            padding: "4px 0",
            color: "#fff",
            border: "1px solid",
            borderColor: styles.accentColor,
            borderRadius: "50%",
            textAlign: "center",
            position: "absolute"
          },

          "& .delete-segment-button": {
            position: "absolute",
            margin: "20px 10px 0 0",
            right: -50,
            color: "#ffffff99"
          },

          "& .MuiAutocomplete-root": {
            "& .MuiInputBase-input": {
              paddingLeft: 0
            },

            "& .MuiAutocomplete-endAdornment": {
              marginTop: 1,
              right: 7
            }
          },

          "& .select-placeholder": {
            "& .MuiSelect-select": {
              color: "#606060",
              opacity: 0.5
            }
          }
        },

        "& .search-stations-switcher": {
          position: "absolute",
          top: 30,
          right: -18,
          padding: 4,
          color: "#fff",

          "& .MuiSvgIcon-root": {
            fontSize: "1.8rem"
          }
        },

        "& .passengers-ages-container": {
          position: "relative",

          "& .age-field input": {
            paddingLeft: 4,
            paddingRight: 4,

            "&[type='number']::-webkit-inner-spin-button, &[type='number']::-webkit-outer-spin-button": {
              WebkitAppearance: "none",
              margin: 0
            },

            /* Oculta las flechas en Firefox */
            "&[type='number']": {
              MozAppearance: "textfield"
            }
          }
        },

        "& .add-segment-container": {
          minHeight: 35,
          justifyContent: "space-between",

          "& .MuiButton-root": {
            color: "#fff"
          }
        }
      },
      "& .flights-search.search-box-container": {
        paddingBottom: 8,

        "& .resume-container": {
          paddingTop: 8,
          paddingBottom: 8,

          "& .back-button": {
            color: "#fff",
            marginRight: 30,

            "& .MuiSvgIcon-root": {
              marginRight: 5
            }
          },

          "& .destination, & .date": {
            paddingRight: 20,
            marginRight: 20,
            borderRight: "1px solid #155dff",
            textTransform: "capitalize",

            "& .MuiSvgIcon-root": {
              marginRight: 5,
              color: "#155dff"
            }
          },

          "& .pax": {
            "& .MuiSvgIcon-root": {
              marginRight: 5,
              color: "#155dff"
            }
          },

          "& .modify-button": {
            color: "#fff",
            borderColor: "#fff",
            marginLeft: 30,

            "& .MuiSvgIcon-root": {
              marginRight: 5
            }
          },
        },

        "& .MuiAccordion-root": {
          backgroundColor: styles.brandPrimary.value,
          margin: 0,
          boxShadow: "none",

          "& .MuiAccordionSummary-root": {
            display: "none"
          },

          "& .MuiAccordionDetails-root": {
            padding: 0
          }
        },

        "& .buttons-container": {
          "@media (max-width: 599px)": {
            flexDirection: "column !important",

            "& .trip-type-buttons": {
              marginBottom: 10,

              "& .MuiButton-root": {
                padding: 5
              }
            },

            "& .class-input": {
              marginBottom: 5
            }
          }
        },

        "& .trip-type-buttons": {
          marginRight: 16,

          "& .MuiButton-root": {
            color: "#fff",
            borderColor: "#fff",
            borderRadius: 0,
            padding: "5px 10px",

            "&.selected": {
              //backgroundColor: "#155dff",
              //borderColor: "#155dff"
              backgroundColor: styles.tabs.selected.background,
              color: styles.tabs.selected.color,
              //borderColor: styles.tabs.selected.borderColor,
              borderRight: 'none'

            },

            "& .MuiSvgIcon-root": {
              marginRight: 5
            },

            "&:first-of-type": {
              borderRadius: "25px 0 0 25px"
            },

            "&:last-of-type": {
              borderRadius: "0 25px 25px 0"
            }
          }
        },

        "& .class-input": {
          color: "#fff",

          "& .MuiInputBase-root": {
            marginLeft: 10,
            backgroundColor: "unset",

            "& .MuiSelect-select": {
              color: "#fff",
              padding: "6.5px 32px 6.5px 14px"
            },

            " & .MuiSvgIcon-root": {
              color: "#fff"
            },

            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#fff !important",
              borderRadius: 8
            }
          }
        },

        "& .MuiDivider-root": {
          backgroundColor: "#fff",
          height: 30,
          margin: "2px 16px 0 0"
        },

        "& .three-days-container": {
          alignItems: "center",
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: 0,
          color: "#fff",

          "& .MuiSwitch-switchBase:not(.Mui-checked)+.MuiSwitch-track": {
            backgroundColor: "gray",
            opacity: 1
          }
        },

        "& .segment-container": {
          position: "relative",

          "&:last-of-type": {
            marginBottom: 12
          },

          "& .segment-number": {
            width: 30,
            height: 30,
            marginTop: 24,
            padding: "4px 0",
            color: "#fff",
            border: "1px solid #155dff",
            borderRadius: "50%",
            textAlign: "center",
            position: "absolute"
          },

          "& .delete-segment-button": {
            position: "absolute",
            margin: "20px 10px 0 0",
            right: -50,
            color: "#ffffff99"
          },

          "& .MuiAutocomplete-root": {
            "& .MuiInputBase-input": {
              paddingLeft: 0
            },

            "& .MuiAutocomplete-endAdornment": {
              marginTop: 1,
              right: 7
            }
          },

          "& .select-placeholder": {
            "& .MuiSelect-select": {
              color: "#606060",
              opacity: 0.5
            }
          }
        },

        "& .search-destinations-switcher": {
          position: "absolute",
          top: 30,
          right: -18,
          padding: 4,
          color: "#fff",

          "& .MuiSvgIcon-root": {
            fontSize: "1.8rem"
          }
        },

        "& .passengers-ages-container": {
          position: "relative",

          "& .age-field input": {
            paddingLeft: 4,
            paddingRight: 4,

            "&[type='number']::-webkit-inner-spin-button, &[type='number']::-webkit-outer-spin-button": {
              WebkitAppearance: "none",
              margin: 0
            },

            /* Oculta las flechas en Firefox */
            "&[type='number']": {
              MozAppearance: "textfield"
            }
          }
        },

        "& .add-segment-container": {
          minHeight: 35,
          marginLeft: 8,

          "& .MuiButton-root:not(.submit-button)": {
            color: "#fff"
          },

          "& .advanced-search-container": {
            display: "flex",
            justifyContent: "flex-end"
          },

          "& .submit-button": {
            marginTop: 10
          }
        },

        "& .advanced-search-accordion": {
          width: "100%",

          "&:before": {
            display: "none"
          }
        },

        "& .currency-input": {
          "& .currency": {
            fontWeight: 600,
            color: "#606060",
            fontSize: 16
          }
        },

        "& .pointer-input": {
          "& .MuiInputBase-root": {
            padding: "0px 10px",

            "& .MuiInputBase-input": {
              paddingRight: 0
            }
          }
        },

        "& .date-picker .MuiInputBase-root": {
          "&:hover": {
            "& .MuiInputAdornment-positionEnd": {
              "& .MuiButtonBase-root": {
                display: "inline-flex"
              }
            }
          },

          "& .MuiInputAdornment-positionEnd": {
            marginLeft: 0,

            "& .MuiButtonBase-root": {
              padding: 4,
              display: "none"
            }
          }
        },

        "& .clearable-select": {
          "&:hover": {
            "& .MuiInputAdornment-positionEnd": {
              "& .MuiButtonBase-root": {
                display: "inline-flex"
              }
            }
          },

          "& .MuiInputAdornment-positionEnd": {
            marginRight: 16,

            "& .MuiButtonBase-root": {
              padding: 4,
              display: "none"
            }
          }
        },

        "@media (min-width: 600px)": {
          "& .search-destinations-switcher": {
            right: "-24px"
          }
        },

        "@media (max-width: 599px)": {
          "& .segment-container": {
            "& .destinations-container": {
              "& .search-destinations-switcher": {
                right: -6
              },

              "& .MuiGrid-item:last-of-type": {
                paddingLeft: "8px !important"
              }
            },

            "& .date-picker .MuiInputBase-root": {
              paddingLeft: 10
            }
          }
        },

        "@media (max-width: 899px)": {
          "& .add-segment-container": {
            marginTop: 10,

            "& .submit-button": {
              marginBottom: 8
            }
          }
        }
      },
      "& .assist-search.search-box-container": {
        "& .resume-container": {
          padding: "8px 0",

          "& .back-button": {
            color: "#fff",
            marginRight: 30,

            "& .MuiSvgIcon-root": {
              marginRight: 5
            }
          },

          "& .destination, & .date": {
            paddingRight: 20,
            marginRight: 20,
            borderRight: "1px solid #155dff",
            textTransform: "capitalize",

            "& .MuiSvgIcon-root": {
              marginRight: 5,
              color: "#155dff"
            }
          },

          "& .pax": {
            "& .MuiSvgIcon-root": {
              marginRight: 5,
              color: "#155dff"
            }
          },

          "& .modify-button": {
            color: "#fff",
            borderColor: "#fff",
            marginLeft: 30,

            "& .MuiSvgIcon-root": {
              marginRight: 5
            }
          }
        },

        "& .trip-type-buttons": {
          marginRight: 16,

          "& .MuiButton-root": {
            color: "#fff",
            borderColor: "#fff",
            borderRadius: 0,
            padding: "5px 10px",

            "&.selected": {
              //backgroundColor: "#155dff",
              //borderColor: "#155dff"
              backgroundColor: styles.tabs.selected.background,
              color: styles.tabs.selected.color,
              //borderColor: styles.tabs.selected.borderColor,
              borderRight: 'none'

            },

            "& .MuiSvgIcon-root": {
              marginRight: 5
            },

            "&:first-of-type": {
              borderRadius: "25px 0 0 25px"
            },

            "&:last-of-type": {
              borderRadius: "0 25px 25px 0"
            }
          }
        },

        "& .MuiAccordion-root": {
          backgroundColor: styles.brandPrimary.value,
          margin: 0,
          boxShadow: "none",

          "& .MuiAccordionSummary-root": {
            display: "none"
          },

          "& .MuiAccordionDetails-root": {
            padding: 0
          }
        },
      },
      "& .submit-button": {
        fontSize: "14px",
        minWidth: "80px",
        textTransform: "none",
        height: "40px",
        marginTop: "20px"
      },

      "& .rooms-popover": {
        "& .MuiPopover-paper": {
          width: 334
        },

        "& label": {
          whiteSpace: "nowrap",
          fontSize: "14px",
          marginRight: "5px"
        },

        "& .rooms-input": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10
        },

        "& .room-info": {
          padding: "10px 5px",
          border: "1px solid #efefef",

          ":not(:last-of-type)": {
            marginBottom: 10
          },

          "& .subtitle": {
            display: "flex",
            alignItems: "center",
            marginBottom: 5,

            "& .MuiSvgIcon-root": {
              marginRight: 5,
              fontSize: 16
            }
          },

          "& .ages-container": {
            paddingTop: 10,

            "& .MuiDivider-root": {
              marginBottom: 10,
              borderColor: "#efefef",
            },

            "& .MuiGrid-root:first-of-type .MuiSvgIcon-root": {
              marginLeft: 5,
              fontSize: 14,
              cursor: "pointer"
            },

            "& .ages-inputs": {
              display: "grid",
              gridTemplateColumns: "repeat(4, 65px)",
              gridGap: 10
            }
          }
        }
      },
      "& .trains-guests-popover": {
        "& .MuiPopover-paper": {
          width: 334
        },

        "& .MuiInputBase-root": {
          minWidth: 68
        },

        "& .passengers-input": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,

          "& .title": {
            fontSize: 16,
            fontWeight: 600
          },

          "& .subtitle": {
            fontSize: 14,
            lineHeight: 1
          },

          "& .buttons-container": {
            width: 88,
            justifyContent: "space-between",

            "& .MuiButtonBase-root": {
              padding: 3,
              border: '1px solid #00000042',

              "&:not(.Mui-disabled)": {
                color: styles.brandSecondary.value,
                borderColor: styles.brandSecondary.value
              }
            }
          }
        },

        "& .passenger-age-container": {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: 10,

          "& .MuiTypography-root": {
            marginRight: 30
          },

          "& .MuiInputBase-root": {
            minWidth: 105
          }
        },

        "& .MuiDivider-root": {
          marginBottom: 10
        },

        "& .max-pax-text": {
          color: styles.brandSecondary.value,
          fontSize: 12
        }
      },
      "& .flights-guests-popover": {
        "& .MuiPopover-paper": {
          width: 334
        },

        "& .MuiInputBase-root": {
          minWidth: 68
        },

        "& .passengers-input": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,

          "& .title": {
            fontSize: 16,
            fontWeight: 600
          },

          "& .subtitle": {
            fontSize: 14,
            lineHeight: 1
          },

          "& .buttons-container": {
            width: 88,
            justifyContent: "space-between",

            "& .MuiButtonBase-root": {
              padding: 3,
              border: '1px solid #00000042',

              "&:not(.Mui-disabled)": {
                color: styles.brandSecondary.value,
                borderColor: styles.brandSecondary.value
              }
            }
          }
        },

        "& .passenger-age-container": {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: 10,

          "& .MuiTypography-root": {
            marginRight: 30
          },

          "& .MuiInputBase-root": {
            minWidth: 105
          }
        },

        "& .MuiDivider-root": {
          marginBottom: 10
        },

        "& .minor-ages-text": {
          color: "#606060",
          fontSize: 12
        }
      },
      "& .footer": {
        backgroundColor: styles.footer.background,
        fontSize: styles.footer.fontSize,
        paddingTop: 40,
        paddingBottom: 1,

        "& a": {
          fontSize: styles.footer.fontSize,
        },

        "& p": {
          fontSize: styles.footer.fontSize,
        },

        "& *": {
          color: styles.footer.color,
        },

        "& .logo": {
          maxWidth: 230
        },

        "& h6": {
          fontWeight: 700,
          fontSize: 18,
          lineHeight: "22px",
          marginBottom: 18
        },

        "& .title": {
          fontSize: styles.headings.h5.fontSize,
          color: styles.headings.h5.color,
          fontWeight: styles.headings.h5.fontWeight,
          marginBottom: 20,
          "& .MuiSvgIcon-root": {
            marginRight:4
          }
        },

        "& .subtitle": {
          fontSize: styles.headings.h6.fontSize,
          color: styles.headings.h6.color,
          fontWeight: styles.headings.h6.fontWeight,
          marginBottom: 5
        },

        "& .phonenumber": {
          "& a": {
            fontSize: 26,
            color: styles.headings.h4.color,
            fontWeight: 600,
            marginBottom: 0
          }
        },

        "& .email": {
          color: styles.headings.h4.color,
          "& a": {
            color: styles.headings.h4.color,
            display: 'flex',
            alignItems: 'center',
            fontSize:16,
            marginBottom: 0
          },
          "& .MuiIcon-root": {
            fontSize: 18
          },
          "& span": {marginRight: 10}
        },

        "& .location": {
          color: styles.headings.h4.color,
          "& a": {
            color: styles.headings.h4.color,
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: 0
          },
          "& .MuiIcon-root": { fontSize: 16 },
          "& span": {marginRight: 10}
        },

        "& .instagram-feed": {
          "& a": {
            marginRight: 10, marginBottom:10
          },
          "& img": {
            width:95
          }
        },

        "&.footer-ttoperadora": {
          "& a, & .row-submenu .MuiTypography-root": {
            fontWeight: 600
          },
          "& .about": {
            fontWeight:600
          }
        },

        "&.footer-gotravelres":{
          "& .last-col":{
            paddingLeft:'30px'
          }
        },

        "& li": {
          margin: "8px 0"
        },

        "& .network .MuiSvgIcon-root": {
          fontSize: 32
        },

        "& .contact-column": {
          "& h6": {
            fontSize: 22
          },

          "& .whatsapp": {
            justifyContent: "flex-end",
            fontWeight: 500,

            "& .MuiSvgIcon-root": {
              marginRight: 5
            }
          },

          "& p": {
            margin: "8px 0"
          }
        },

        "& .bottom": {
          marginTop: 20,

          "& .certificates": {
            display: "flex",
            alignItems: "flex-end",

            "& img:not(:first-of-type)": {
              marginLeft: 10
            },

            "& p": {
              marginLeft: 15,
              fontSize: 13
            }
          },

          "& .flags": {
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",

            "& .flag": {
              width: 30,
              borderRadius: 5,

              "&:not(:first-of-type)": {
                marginLeft: 10
              }
            }
          }
        },

        "& .bottom-tto": {
          backgroundColor: styles.brandPrimary.value,
          color: "white",
          "& p": {color: "white"},
          "& a": {color: "white"}
        },

        "& .redes": {
          "& .row-submenu": {
            display: "flex"
          },

          "& a": {
            display: "inline-block"
          }
        }
      },
      "& .MuiCalendarPicker-root": {
        "& .between": {
          backgroundColor: "#fff0"
        },
        "& .range-between": {
          borderRadius: 0,
          backgroundColor: "#1976d21f"
        },
        "& .start-date, & .end-date": {
          "& .MuiButtonBase-root": {
            color: "#fff"
          }
        },
        "& .start-date.end-date, & .range-between:last-of-type.start-date, & .range-between:first-of-type.end-date": {
          borderRadius: "50% !important"
        },
        "& .range-between:first-of-type, & .start-date:not(.range-between:first-of-type):not(.end-date)": {
          borderRadius: "50% 0 0 50%",
        },
        "& .range-between:last-of-type, & .end-date:not(.range-between:last-of-type):not(.start-date)": {
          borderRadius: "0 50% 50% 0",
        },
        "& .preselected-day": {
          borderTop: "2px dashed lightgray",
          borderBottom: "2px dashed lightgray",
        }
      },
      "& .pointer-input": {
        cursor: "pointer",

        "& *": {
          cursor: "pointer"
        }
      },
      "& .group-title": {
        marginLeft: 10,
        display: "flex",
        color: "#007ce1",
        alignItems: "center",

        "& .MuiSvgIcon-root": {
          marginRight: 3
        }
      },
      "& .group-option": {
        paddingLeft: 20,
      },
      "& .select-option": {
        paddingLeft: 16,
        marginLeft: 20
      },
      "& .row": {
        display: "flex !important",
        alignItems: "center !important",
        flexDirection: "row !important"
      },
      "& .column": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      },
      "& .results-page": {
        "& .search-container": {
          backgroundColor: styles.search.background,

          "& .search-box-container": {
            padding: "16px 0 8px",
            display: "block",

            "&.assist-search": {
              paddingTop: "8px !important",
            },

            "& form": {
              paddingLeft: 0
            },

            "& .search-box": {
              borderRadius: 0,
              padding: "0 0 8px"
            },

            "&.train-search, &.flights-search": {
              paddingTop: 8,

              "& .search-box": {
                padding: 0
              },
            }
          }
        },

        "& .alert-no-results": {
          margin: "30px 0",
          boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)",
          border: "1px solid #5f2120",
          borderRadius: 12,
          "& .MuiAlert-icon": {
            margin: "auto 12px auto 0",
            fontSize: 30
          },

          "& .title-noassists": {
            fontSize: 20,
            fontWeight: 550,
          },

          "& .subtitle": {
            fontSize: 18,
            fontWeight: 500,
            opacity: 0.85
          }
        },

        "& .alert-error-rule": {
          margin: "30px 0",
          boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)",
          border: "1px solid #663c00",
          borderRadius: 12,

          "& .MuiAlert-icon": {
            margin: "auto 12px auto 0",
            fontSize: 30
          },

          "& .title": {
            fontSize: 20,
            fontWeight: 500
          }
        }
      },
      "& .gimmonix-results": {
        "& .results-container": {
          padding: "16px 0",

          "& > .title": {
            fontSize: 24,
            fontWeight: 500,
            marginBottom: 16,
            color: styles.headings.color,

            "@media(max-width: 425px)": {
              fontSize: 18
            },

            "@media(min-width: 426px) and (max-width: 768px)": {
              fontSize: 20
            }
          }
        },

        "& .comparator-container": {
          width: "100%",
          height: 240,
          backgroundColor: `${styles.brandPrimary.value}CC`,
          position: "fixed",
          bottom: 0,
          left: 0,
          zIndex: 20,
          color: "#fff",
          padding: "10px 0",

          "& .MuiContainer-root": {
            height: "100%",

            "& .title": {
              fontSize: 20
            },

            "& .buttons-container": {
              "& .MuiButton-root": {
                color: "#fff",
                borderColor: "#fff",

                "&:last-of-type": {
                  marginLeft: 10
                }
              }
            },

            "& .hotel-info": {
              padding: 15,
              backgroundColor: "#fff",
              borderRadius: 16,
              maxHeight: 155,
              position: "relative",

              "& > div": {
                width: "calc(100% - 100px)"
              },

              "& .name": {
                fontSize: 16,
                color: styles.headings.color,
                fontWeight: 600,
                margin: "5px 0",
                lineHeight: "normal",
                textWrap: "nowrap",
                textOverflow: "ellipsis",
                overflowX: "hidden"
              },

              "& .location": {
                color: styles.brandPrimary.value,
                marginBottom: 5,

                "& span": {
                  fontSize: 12,
                  color: "#606060"
                }
              },

              "& .price-container": {
                "& .MuiTypography-root:first-of-type": {
                  fontSize: 12,
                  color: "#606060"
                },

                "& .price": {
                  fontSize: 20,
                  color: styles.brandPrimary.value,
                  fontWeight: 600
                }
              },

              "& .close-button": {
                position: "absolute",
                top: -10,
                right: -10,
                backgroundColor: "#ffffffd9",
                boxShadow: "0px 0px 1px 0px black"
              }
            }
          }
        },

        "& .comparation-component": {
          "& .title": {
            marginBottom: 20
          },

          "& .buttons-container": {
            "& .button": {
              marginBottom: 10
            },
          },

          "& .first-row": {
            "& > *": {
              maxHeight: 445
            },

            "& > .hotel-info": {
              borderRadius: "16px 16px 0 0",
              position: "relative",

              "& .recommended-container": {
                position: "absolute",
                top: 0,
                left: 16,
                zIndex: 50,
                maxWidth: "calc(100% - 16px)",
                fontSize: 14,
              },

              "& .carousel-item": {
                borderRadius: "16px 16px 0 0",
                borderTop: `2px solid ${styles.accentColor}`
              },

              "& > .MuiGrid-root": {
                padding: 10,
                height: "calc(100% - 250px)",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                backgroundColor: "#fff",

                "& .property": {
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#606060"
                },

                "& .name": {
                  fontSize: 20,
                  fontWeight: 500,
                  color: styles.headings.color,
                  marginBottom: 5,
                  lineHeight: "normal",
                  flex: 1,
                  maxHeight: "2.8em",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  textOverflow: "ellipsis"
                },

                "& .location": {
                  color: styles.brandPrimary.value,
                  marginBottom: 5,

                  "& span": {
                    fontSize: 16,
                    color: "#606060"
                  }
                },

                "& .MuiButton-root": {
                  textDecoration: "underline",
                  color: styles.brandPrimary.value,
                  fontSize: 14,
                  fontWeight: 600,
                  marginTop: 5
                }
              },

              "& .close-button": {
                position: "absolute",
                top: -10,
                right: -10,
                backgroundColor: "#ffffffd9",
                boxShadow: "0px 0px 1px 0px black",
                zIndex: 60
              }
            },
          },

          "& .price-info": {
            backgroundColor: "#fff",
            padding: 10,
            marginTop: 4,
            borderRadius: "0 0 16px 16px",
            flex: 1,
            maxWidth: "100%",

            "& .price": {
              fontSize: 24,
              fontWeight: 700,

              "& .dots-container": {
                paddingBottom: 16,
                color: styles.headings.color
              }
            },

            "& .price-text": {
              fontSize: 16,
              color: "#606060",
              fontWeight: 500
            },

            "& .MuiButton-root": {
              fontSize: 16,
              fontWeight: 700,
              padding: "0 10px",
              marginTop: 10
            },

            "& .provider-logo": {
              marginTop: 4,
              height: 40,
              maxWidth: "50%",
              objectFit: "contain",
              objectPosition: "left"
            },

            "& .room": {
              fontSize: 16,
              fontWeight: 700,
              color: "#606060",
              marginBottom: 4,
              textWrap: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }
          },

          "& .green": {
            marginRight: 4
          },

          "& .amenity": {
            marginTop: 4,
            padding: 4,
            height: 32,
            backgroundColor: "#fff",
            justifyContent: "center",
          },

          "& .amenity-name": {
            backgroundColor: "#e1eaff",
            paddingLeft: 10,
            color: styles.brandPrimary.value,
            fontSize: 13,
            fontWeight: 500,
            height: "calc(100% - 4px)"
          },

          "& .map": {
            height: 400,
            marginTop: 4,
            padding: 10,
            backgroundColor: "#fff",

            "& .gm-style-iw-t": {
              bottom: "35px !important"
            }
          },

          "& .attractions": {
            marginTop: 4,
            padding: "4px 10px",
            backgroundColor: "#fff",
            height: "calc(100% - 4px)",

            "& p": {
              marginTop: 0,

              "&:last-of-type": {
                marginBottom: 0
              }
            }
          },

          "& .add-hotel-column": {
            backgroundColor: "#fff",
            padding: '16px 12px',
            borderRadius: 16,

            "& .title": {
              fontSize: 16,
              fontWeight: 700,
              marginBottom: 0
            },

            "& .hotel-card": {
              boxShadow: "5px 5px 10px 0px lightgray",
              borderRadius: 16,
              margin: "12px 0",
              borderTop: `2px solid ${styles.accentColor}`,

              "& .hotel-info": {
                padding: 8,

                "& .property": {
                  fontSize: 12,
                  color: "#606060"
                },

                "& .name": {
                  fontSize: 16,
                  color: styles.headings.color,
                  fontWeight: 600,
                  margin: "5px 0",
                  lineHeight: "normal"
                },

                "& .location": {
                  color: styles.headings.color,
                  marginBottom: 5,

                  "& span": {
                    fontSize: 12,
                    color: "#606060"
                  }
                },

                "& .price-container": {
                  "& .MuiTypography-root:first-of-type": {
                    fontSize: 12,
                    color: "#606060"
                  },

                  "& .price": {
                    fontSize: 20,
                    color: styles.brandPrimary.value,
                    fontWeight: 600
                  }
                },

                "& .MuiButton-root": {
                  width: "100%",
                  marginBottom: 10,
                  fontSize: 16,
                  fontWeight: 600
                }
              }
            },

            "& > .MuiButton-root": {
              marginBottom: 12,
              fontSize: 15,
              fontWeight: 600
            }
          },

          "& .recommended-container": {
            height: 40,
            backgroundColor: styles.recommended.background,
            color: styles.recommended.color,
            padding: "0 10px",
            borderRadius: "16px 0 16px 0",
            width: "fit-content",
            fontSize: 14,

            "& .MuiSvgIcon-root": {
              marginRight: 5
            }
          }
        }
      },
      "& .filters-component": {
        backgroundColor: "#fff",
        padding: 10,
        borderRadius: 15,

        "& .title": {
          color: styles.headings.color,
          marginBottom: 10,

          "& .MuiSvgIcon-root": {
            marginRight: 3
          }
        },

        "& .active-filters-container": {
          display: "flex",
          marginBottom: 8,
          flexWrap: "wrap",

          "& .active-filter": {
            border: `1px solid ${styles.accentColor}`,
            borderRadius: 50,
            fontSize: 13,
            color: styles.accentColor,
            padding: "4px 8px",
            marginRight: 5,
            marginBottom: 5,

            "& .MuiIconButton-root": {
              backgroundColor: "#5e8fff",
              color: "#fff",
              padding: 0,
              marginLeft: 5
            }
          },
        },

        "& .section": {
          "&.skeleton": {
            "& .filters": {
              padding: 0
            },

            "& .MuiSkeleton-root": {
              margin: "5px 0"
            }
          },

          "& .title": {
            color: styles.headings.color,
            fontSize: 16,
            fontWeight: 500,
            marginBottom: 5
          },

          "& .filters": {
            padding: "0 5px",
            display: "flex",
            flexDirection: "column",

            "& .filter": {
              display: "flex",
              alignItems: "center"
            },

            "& > .MuiButton-root": {
              margin: "auto"
            },

            "&.range-price": {
              marginTop: 5,

              "& .row span": {
                color: "black"
              }
            },

            "& .MuiSlider-root": {
              color: "#2196f3",
              width: "calc(100% - 20px)",
              marginLeft: 10
            }
          },

          "& .search-input": {
            display: "flex",

            "& .search-button": {
              backgroundColor: styles.brandSecondary.value,
              borderRadius: 2,
              color: "#fff",
              padding: "8px 10px"
            }
          }
        },

        "& .MuiDivider-root": {
          margin: "10px 0"
        }
      },
      "& .clear-filter-button": {
        marginTop: -6,
        "& .MuiSvgIcon-root": {
          width: '0.8em',
          height: '0.8em',
          marginRight: 2
        }
      },
      "& .results-component": {
        "& .sort-button": {
          margin: "0 5px",
          color: styles.accentColor,

          "&.active": {
            background: "#e1eaff"
          },

          "& .MuiSvgIcon-root": {
            marginLeft: 5,
            fontSize: 18
          }
        },

        "& .map-button": {
          color: styles.accentColor,
          borderColor: styles.accentColor,

          "& .MuiSvgIcon-root": {
            marginRight: 5,
          }
        },

        "& .hotel-card": {
          boxShadow: "5px 5px 10px 0px lightgray",
          marginBottom: 30,
          borderRadius: 16,

          "&:last-of-type": {
            marginBottom: 0
          },

          "&.skeleton": {
            height: 245,

            "& .MuiSkeleton-root": {
              transform: "unset",

              "&.image-skeleton": {
                borderRadius: "16px 0 0 16px"
              }
            },

            "& .info-container": {
              justifyContent: "space-between",
              flex: 1,

              "& .MuiSkeleton-root": {
                margin: "10px 0"
              }
            }
          },

          "&.recommended": {
            "& .hotel-content": {
              borderRadius: "0 0 16px 16px",

              "& .carousel-item, & .image-small": {
                borderTopLeftRadius: "0px !important"
              }
            }
          },

          "&:not(.recommended):not(.skeleton)": {
            borderTop: `2px solid ${styles.accentColor}`
          },

          "& .recommended-container": {
            height: 40,
            backgroundColor: styles.recommended.background,
            color: styles.recommended.color,
            borderRadius: "16px 16px 0 0",
            fontSize: 14,

            "& .MuiSvgIcon-root": {
              marginRight: 5,
              marginLeft: 5
            }
          },

          "& .hotel-content": {
            minHeight: 245,
            backgroundColor: "#fff",
            borderRadius: 16,
            display: "flex",

            "& .image-container": {
              position: "relative",

              "& .LazyLoad": {
                height: "100%",

                "& .MuiCircularProgress-root": {
                  margin: "auto"
                }
              },

              "& .images-carousel": {
                position: "relative",
                height: "100%",

                "&.hidden": {
                  visibility: "hidden"
                },

                "& .react-multi-carousel-track": {
                  height: "100%"
                },

                "& .carousel-item": {
                  borderRadius: "16px 0 0 16px",
                  height: "100%",

                  "@media(max-width: 899px)": {
                    borderBottomLeftRadius: 0
                  },
                }
              },

              "& .favorite-button": {
                position: "absolute",
                top: 10,
                right: 10,
                zIndex: 10,
                color: "#fff",
                backgroundColor: "#3c3c3c80"
              },

              "& .image-small": {
                objectFit: "cover",
                height: "100%",
                borderTopLeftRadius: 16
              }
            },

            "& .info-container": {
              justifyContent: "space-between",
              flex: 1,
              alignItems: "initial",
              position: "relative",

              "& .left-side": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "0 20px",
                height: "100%",
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",

                "@media(max-width: 599px)": {
                  padding: "25px 10px 0",

                  "& p, & .MuiButton-root, & .boardbases": {
                    fontSize: "12px !important"
                  },

                  "& .material-icons, & .MuiSvgIcon-root": {
                    fontSize: 15
                  },

                  "& .name": {
                    fontSize: "18px !important",
                    textWrap: "wrap !important",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical"
                  },

                  "& .buttons-container": {
                    flexDirection: "column",
                    alignItems: "flex-start",

                    "& .MuiTypography-root:not(:last-of-type)": {
                      marginBottom: 4
                    }
                  }
                },

                "@media(min-width: 600px) and (max-width: 899px)": {
                  paddingTop: 20,

                  "& > *": {
                    marginBottom: "0px !important"
                  }
                },

                "& > *": {
                  marginBottom: 4
                },

                "& .property": {
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#606060"
                },

                "& .name": {
                  color: styles.headings.color,
                  fontSize: 20,
                  fontWeight: 500,
                  maxWidth: 450,
                  textWrap: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  marginBottom: 0
                },

                "& .address": {
                  flexFlow: "wrap",

                  "& .location": {
                    fontSize: 14,
                    marginBottom: 4,
                    marginRight: 8,
                    overflowX: "hidden",

                    "& .MuiSvgIcon-root": {
                      color: styles.brandPrimary.value,
                      fontSize: 14,
                      marginRight: 8
                    },

                    "& span": {
                      overflowX: "hidden",
                      textWrap: "nowrap",
                      textOverflow: "ellipsis"
                    }
                  },

                  "& .distance": {
                    fontSize: 14,
                    marginBottom: 4,
                    backgroundColor: "#e5e5e5",
                    padding: "0 4px",
                    borderRadius: 12,
                    overflow: "hidden",
                    minWidth: "50%",
                    flex: 1,

                    "& .MuiSvgIcon-root": {
                      fontSize: 14,
                      marginRight: 4
                    },

                    "& span": {
                      textWrap: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }
                  },
                },

                "& .MuiButton-root": {
                  textDecoration: "underline",
                  fontSize: 13,
                  fontWeight: 600
                },

                "& .amenities": {
                  lineHeight: "10px",

                  "& .MuiIcon-root": {
                    color: styles.accentColor,
                    marginRight: 6
                  }
                },

                "& .boardbases": {
                  fontSize: 13,
                  marginRight: 8,

                  "& .MuiSvgIcon-root": {
                    color: styles.green,
                    marginRight: 5
                  }
                },

                "& .cancelation": {
                  fontSize: 13,
                  lineHeight: 1,
                  "& .check-icon": {
                    marginRight: 5,
                    color: styles.green
                  }
                },

                "& .buttons-container": {
                  flexDirection: "row",
                  alignItems: "center"
                }
              },

              "& > .MuiFormControlLabel-root": { },

              "& .compare-checkbox": {
                color: styles.compareCheckbox.value,
                position: "absolute",
                top: 0,
                right: 0,

                "& .MuiCheckbox-root": {
                  paddingRight: 5,

                  "&:not(.Mui-disabled)": {
                    color: styles.compareCheckbox.value,
                  }
                },

                "& .MuiTypography-root": {
                  fontSize: 14,
                  fontWeight: 'bold'
                }
              }
            },

            "& .right-side": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              paddingTop: 25,
              alignItems: "center",

              "&.bottom": {
                padding: 10,

                "@media(max-width: 424px)": {
                  "& p": {
                    fontSize: 11
                  },

                  "& .MuiSvgIcon-root": {
                    fontSize: 14
                  },

                  "& .total-price": {
                    fontSize: 18
                  },

                  "& .provider-logo": {
                    maxHeight: 25,
                    objectPosition: "left"
                  },

                  "& .MuiButton-root": {
                    margin: "0px !important"
                  },

                  "& .cancelation": {
                    fontSize: "10px !important"
                  }
                },

                "@media(max-width: 599px)": {
                  "& .cancelation": {
                    fontSize: 13
                  }
                },

                "@media(max-width: 899px)": {
                  "& .cancelation": {
                    margin: "5px auto 0"
                  }
                },

                "& .column": {
                  alignItems: "flex-end"
                },

                "& .MuiButton-root": {
                  margin: "10px auto"
                },
              },

              "@media(min-width: 900px) and (max-width: 1199px)": {
                "& .total-price": {
                  fontSize: "24px !important"
                },

                "& .cancelation": {
                  fontSize: "12px !important",
                }
              },

              "& > *": {
                fontSize: 14
              },

              "& .total-price": {
                color: styles.headings.color,
                fontSize: 28,
                fontWeight: 700,

                "& .dots-container": {
                  color: styles.headings.color,
                  margin: "5px auto",
                  padding: "3px 0 17px"
                }
              },

              "& .avg, .taxes": {
                color: styles.headings.color,
                fontSize: 12,
                fontWeight: 500,

                "& .dots-container": {
                  margin: "-8px 5px 0",
                  fontSize: 20
                },

                "& .MuiSvgIcon-root": {
                  marginLeft: 5,
                  cursor: "pointer"
                }
              },

              "& .provider-logo": {
                maxHeight: 25,
                width: "fit-content",
                margin: "auto",
                objectPosition: "left"
              },

              "& .MuiButton-root": {
                margin: "10px auto 10px auto"
              },

              "& .check-icon": {
                marginRight: 5,
                color: styles.green
              }
            },
          }
        },

        "& .pagination-container": {
          display: "flex",
          justifyContent: "center",
          marginTop: 16
        }
      },
      "& .hotel-modal": {
        "& .close-button": {
          zIndex: 1101
        },

        "& .MuiDialogContent-root": {
          "& .map": {
            height: "500px !important",
            maxHeight: "58vh"
          },

          "& .hotel-card": {
            boxShadow: "5px 5px 10px 0px lightgray",
            marginBottom: 30,
            borderRadius: 16,
            position: "absolute",
            top: 90,
            left: 12,
            backgroundColor: "#fff",
            maxWidth: "35%",
            borderTop: `2px solid ${styles.accentColor}`,

            "& .recommended-container": {
              height: 40,
              backgroundColor: styles.accentColor,
              color: "#fff",
              borderRadius: "16px 0 16px 0",
              width: "fit-content",
              padding: "0 10px",
              fontSize: 14,

              "& .MuiSvgIcon-root": {
                marginRight: 5
              }
            },

            "& .hotel-info": {
              padding: 15,

              "@media(max-width: 900px)": {
                "& > .row": {
                  flexDirection: "column !important"
                },

                "& .price-container": {
                  marginTop: "0px !important"
                },

                "& .MuiGrid-root": {
                  maxWidth: "100%"
                }
              },

              "& .property": {
                fontSize: 12,
                color: "#606060"
              },

              "& .name": {
                fontSize: 16,
                color: styles.brandPrimary.value,
                fontWeight: 600,
                margin: "5px 0",
                lineHeight: "normal",
                textWrap: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              },

              "& .location": {
                color: styles.brandPrimary.value,
                marginBottom: 5,

                "& span": {
                  fontSize: 12,
                  color: "#606060"
                }
              },

              "& .price-container": {
                margin: "10px 0",

                "& .MuiTypography-root:first-of-type": {
                  fontSize: 12,
                  color: "#606060"
                },

                "& .price": {
                  fontSize: 20,
                  color: styles.brandPrimary.value,
                  fontWeight: 600
                }
              },

              "& .MuiButton-root": {
                width: "100%"
              }
            }
          }
        },

        "& .hotel-info": {
          alignItems: "flex-start",
          minHeight: 77,

          "& .title": {
            fontSize: 28,
            color: styles.headings.color,
            fontWeight: 500,
            lineHeight: '30px'
          },

          "& .bottom-info": {
            "&.row .MuiGrid-root:not(:first-of-type)": {
              "& .MuiSvgIcon-root": {
                marginLeft: 15
              }
            },

            "& > *": {
              marginTop: 5
            },

            "& .address, & .date": {
              "& .MuiSvgIcon-root": {
                marginRight: 5,
                color: styles.brandPrimary.value
              },

              "& span": {
                fontSize: 16,
                color: "#606060"
              }
            }
          },

          "& .row .MuiRating-root": {
            marginLeft: 10
          },

          "@media(max-width: 425px)": {
            alignItems: "left",

            "& > .row": {
              flexDirection: "column !important",
              alignItems: "flex-start !important",
              maxWidth: "100%",

              "& .MuiRating-root": {
                marginLeft: 0
              }
            },

            "& .title": {
              fontSize: 24,
              maxWidth: "100%",
              textWrap: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            },

            "& .MuiSvgIcon-root": {
              marginLeft: "0px !important"
            }
          }
        },

        "& .buttons-container": {
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-end",

          "@media(max-width: 430px)": {
            flexDirection: "column",
            alignItems: "end",

            "& .MuiButton-root:first-of-type": {
              marginBottom: 5
            }
          },

          "& .MuiButton-root": {
            "&:not(:first-of-type)": {
              marginLeft: 15
            },

            "& .MuiSvgIcon-root": {
              marginRight: 5,
            }
          }
        },

        "& .amenities-container": {
          marginTop: 20,

          "& .title": {
            fontSize: 25,
            fontWeight: 700,
            color: styles.headings.color,
          },

          "& .MuiSvgIcon-root": {
            marginRight: 4
          }
        },

        "& .preview-container": {
          marginTop: 20,

          "& img": {
            height: 120,
            width: "100%",
            objectFit: "cover",
            cursor: "pointer"
          }
        },

        "& .room-view": {
          "& .title": {
            fontSize: 25,
            fontWeight: 700,
            color: styles.headings.color
          },

          "& .show-rooms-button": {
            backgroundColor: styles.green
          },

          "& .room-container": {
            boxShadow: "0px 0px 10px 0px lightgray",
            marginLeft: 0,
            marginBottom: 20,
            borderRadius: 16,
            justifyContent: "space-between",
            backgroundColor: "rgba(0, 0, 0, 0.02)",

            "& > .MuiGrid-root": {
              padding: 10
            },

            "& .title": {
              fontSize: 18,
              marginBottom: 0
            },

            "& .left-side": {
              borderRight: "1px solid rgba(0, 0, 0, 0.12)"
            },

            "& .top": {
              marginBottom: 10,
              flex: 1,

              "@media(max-width: 599px)": {
                "& .room-pax": {
                  alignItems: "flex-start !important"
                },

                "& .boardbase": {
                  justifyContent: "flex-start !important"
                },

                "& .refundable": {
                  textAlign: "left !important"
                }
              },

              "& .rooms": {
                display: "flex",
                flexDirection: "column",
                flex: 1,

                "& .MuiDivider-root": {
                  margin: "10px 0"
                },

                "& .room-info": {
                  "& .room-alert": {
                    fontSize: 14,
                    fontStyle: "italic",
                    color: "#ff0000"
                  },

                  "& .subtitle": {
                    color: styles.brandPrimary.value
                  },

                  "& .room-pax": {
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column"
                  },

                  "& .boardbase": {
                    flexWrap: "wrap",
                    justifyContent: "center",
                    textAlign: "center",

                    "& .MuiSvgIcon-root": {
                      marginRight: 3
                    },

                    "& .green": {
                      color: styles.green
                    }
                  }
                },
              },

              "& .refundable": {
                color: styles.green,
                textAlign: "center",

                "& .non-refundable": {
                  color: "#f5444a"
                },
              }
            },

            "& .price-container": {
              color: styles.brandPrimary.value,
              display: "flex",
              alignItems: "center",

              "& > .MuiGrid-container": {
                alignItems: "center",
                justifyContent: "center"
              },

              "& .column": {
                "&:last-of-type": {
                  "@media(min-width: 600px) and (max-width: 899px)": {
                    alignItems: "flex-end"
                  },
                },

                "& > *": {
                  margin: "3px 0"
                },
              },

              "& .provider-logo": {
                maxHeight: 25,
                width: "fit-content",
                objectPosition: "left",
                marginTop: 10
              },

              "& .taxes .MuiSvgIcon-root": {
                marginLeft: 5,
                cursor: "pointer"
              },
            },

            "& .cancelation-policy": {
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",

              "& .cancelation-info": {
                color: styles.green
              },

              "& .error-policy": {
                color: "#f5444a"
              }
            }
          },

          "& .filters-component": {
            border: "1px solid #f0f0f0",

            "& .title": {
              fontSize: 16,
              fontWeight: "unset"
            },

            "& .section .title": {
              fontWeight: 500
            }
          },

          "& .alert-error": {
            margin: "26px 0",
            boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)",
            border: "1px solid #663c00",
            borderRadius: 12,

            "& .MuiAlert-icon": {
              margin: "auto 12px auto 0",
              fontSize: 30
            },

            "& .MuiAlert-message .MuiTypography-root": {
              fontSize: 18,
              fontWeight: 500
            }
          }
        },

        "& .map": {
          height: "500px !important",
          maxHeight: "58vh"
        },

        "& .hotel-card": {
          boxShadow: "5px 5px 10px 0px lightgray",
          marginBottom: 30,
          borderRadius: 16,
          position: "absolute",
          top: 60,
          left: 12,
          backgroundColor: "#fff",
          maxWidth: "30%",
          borderTop: `2px solid ${styles.accentColor}`,

          "& .recommended-container": {
            height: 40,
            backgroundColor: styles.recommended.background,
            color: styles.recommended.color,
            borderRadius: "16px 0 16px 0",
            width: "fit-content",
            padding: "0 10px",
            fontSize: 14,

            "& .MuiSvgIcon-root": {
              marginRight: 5
            }
          },

          "& .hotel-info": {
            padding: 15,

            "@media(max-width: 900px)": {
              "& > .row": {
                flexDirection: "column !important"
              },

              "& .price-container": {
                marginTop: "0px !important"
              },

              "& .MuiGrid-root": {
                maxWidth: "100%"
              }
            },

            "& .property": {
              fontSize: 12,
              color: "#606060"
            },

            "& .name": {
              fontSize: 16,
              color: styles.headings.color,
              fontWeight: 600,
              margin: "5px 0",
              lineHeight: "normal",
              textWrap: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            },

            "& .location": {
              color: styles.brandPrimary.value,
              marginBottom: 5,

              "& span": {
                fontSize: 12,
                color: "#606060"
              }
            },

            "& .price-container": {
              margin: "10px 0",

              "& .MuiTypography-root:first-of-type": {
                fontSize: 12,
                color: "#606060"
              },

              "& .price": {
                fontSize: 20,
                color: styles.brandPrimary.value,
                fontWeight: 600
              }
            },

            "& .MuiButton-root": {
              width: "100%"
            }
          }
        },

        "& .map-filters": {
          marginBottom: 16,

          "& .title": {
            color: styles.brandPrimary.value,
            fontSize: 16,
            fontWeight: 500
          },

          "& .stars-filter": {
            "& > .MuiFormControlLabel-root": {
              position: "relative",

              "& .collapse": {
                position: "absolute",
                top: "100%",
                zIndex: 10,
                backgroundColor: "#fff",
                marginLeft: 11,

                "& .MuiFormControlLabel-root": {
                  marginRight: 0,
                  paddingRight: 16
                },

                "& .MuiRating-root": {
                  marginTop: 4
                }
              }
            }
          },

          "& .price-filter": {
            "& .left-input .MuiInputBase-root": {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
            },

            "& .right-input .MuiInputBase-root": {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0
            },

            "& .search-button": {
              backgroundColor: styles.brandSecondary.value,
              borderRadius: 2,
              color: "#fff",
              padding: "8px 10px",
              height: 38
            }
          }
        }
      },
      "& .images-carousel": {
        "& > div:last-of-type": {
          position: "absolute",
          bottom: 20,
          zIndex: 5,
          padding: "0 20px"
        },

        "& > div:not(:last-of-type)": {
          "& .MuiButtonBase-root": {
            backgroundColor: "#ffffffb3",
            padding: 4,
            top: "calc(50% - 12px) !important",
            color: styles.accentColor
          },
        },
      },
      "& .scroll-to-top-button": {
        position: "fixed !important",
        right: 20,
        bottom: 100,
        zIndex: 40,
        backgroundColor: `${styles.brandPrimary.value} !important`,
        border: `1px solid ${styles.brandPrimary.value} !important`,
        transition: "opacity 0.5s, visibility 0.5s !important",
        opacity: 0,
        visibility: "hidden",
        color: "#fff",

        "&.show": {
          opacity: 1,
          visibility: "initial"
        }
      },
      "& .comparation-component": {
        padding: "15px 0 30px",

        "& .button": {
          color: styles.accentColor,

          "& .MuiSvgIcon-root": {
            marginRight: 5
          }
        },

        "& .title": {
          fontSize: 24,
          fontWeight: 500,
          color:styles.headings.color
        }
      },
      "& .newsletter": {
        backgroundColor: styles.newsletter.background,
        color: styles.newsletter.color,
        padding: "30px 0",

        "& .row": {
          justifyContent: "center",
          flexWrap: "wrap",

          "& .title": {
            fontSize: 30,
            fontWeight: 500
          },

          "& .MuiTextField-root": {
            margin: "0 15px",
            width: 300,

            "& .MuiInputBase-root": {
              background: "#fff"
            }
          },

          "& .MuiButton-root": {
            width: 200,
            margin: "10px 0",
            backgroundColor: "#e0e0e0",

            "&:not(.Mui-disabled)": {
              color: styles.newsletter.button?.color,
              backgroundColor: styles.newsletter.button?.background,
              borderColor: styles.newsletter.button?.borderColor,
              border: styles.newsletter.button?.border
            }
          }
        },

        "& .MuiGrid-root:last-of-type": {
          textAlign: "center",
          marginTop: 10,

          "& .MuiTypography-root": {
            fontSize: 14
          }
        },

        "& .MuiTextField-root": {
          minWidth: 300
        }
      },
      "& .checkout": {
        "&:has( .steps-buttons)": {
          marginBottom: 92
        },

        "& .steps": {
          "& > .MuiContainer-root": {
            "@media (min-width: 1440px)": {
              maxWidth: 1440
            },
          },

          "& .stepper-container": {
            backgroundColor: "rgba(255, 255, 255, 0.5)",

            "& .MuiPaper-root": {
              backgroundColor: "transparent"
            },

            "& .stepper-component": {
              maxWidth: 1170,
              margin: "auto",
              padding: 24,
              [theme.breakpoints.down("sm")]: {
                padding: 12
              }
            },

            "& .MuiStep-horizontal": {
              [theme.breakpoints.down("sm")]: {
                paddingLeft: 4,
                paddingRight: 4
              }
            },

            "& .MuiStepLabel-iconContainer": {
              [theme.breakpoints.down("sm")]: {
                paddingRight: 4
              },

              "&.Mui-active": {
                "& .MuiStepIcon-text": {
                  fill: "#fff"
                }
              },

              "& .MuiStepIcon-root": {
                border: "1px solid rgba(0, 0, 0, 0.4)",
                borderRadius: "50%",
                width: 30,
                height: 30,
                [theme.breakpoints.down("sm")]: {
                  width: 18,
                  height: 18
                },

                "&:not(.Mui-active):not(.Mui-completed)": {
                  color: "#fff",

                  "& .MuiStepIcon-text": {
                    fill: "#a0a0a0"
                  }
                },

                "& .MuiStepIcon-text": {
                  fontSize: "0.85rem"
                }
              }
            },

            "& .MuiStepLabel-label": {
              fontSize: 14,
              textTransform: "capitalize",
              [theme.breakpoints.down("sm")]: {
                fontSize: 13
              },

              "&.Mui-active": {
                color: styles.brandPrimary.value
              }
            }
          },

          "& .col-content, & .col-sidebar": {
            paddingTop: 40,
            paddingBottom: 24,

            "& .sticky": {
              position: "sticky",
              top: 0
            }
          },

          "& .timer-checkout": {
            marginBottom: 20
          },

          "& .MuiFormControl-root": {
            "& .MuiInputBase-root > *:not(svg):not(fieldset):not(.MuiInputAdornment-root)": {
              height: 20,
              padding: 14,
              fontSize: 16,
              minHeight: "unset",

              "&.MuiSelect-select": {
                padding: "14px 32px 14px 14px",
              }
            },

            "& fieldset": {
              borderRadius: 12
            }
          },

          "& .MuiFormLabel-root": {
            fontSize: 17,
            fontWeight: 500,
            color: "#606060",
            marginBottom: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",

            "& .MuiSvgIcon-root": {
              marginLeft: 5
            }
          },

          "& .MuiPaper-root": {
            borderRadius: 12
          },

          "& .paper-title": {
            color: styles.headings.color,
            marginBottom: 20,
            fontSize: 26,
            [theme.breakpoints.down("sm")]: {
              fontSize: 22
            }
          },

          "& .step-passenger": {
            "& .paper-form": {
              padding: 20
            },

            "& .form": {
              "& .message:first-of-type": {
                marginTop: 0
              },

              "& .paper-form .message": {
                boxShadow: "none",
                backgroundColor: "#f8f8f8",
                borderRadius: 0,
                marginBottom: 15
              },

              "& .agency-container": {
                marginTop: 20
              },

              "& .passengers-container": {
                "& .passenger-container": {
                  "&:not(:first-of-type)": {
                    marginTop: 30
                  },

                  "& .title": {
                    color: styles.headings.color,
                    fontSize: 18,
                    marginBottom: 5,
                    justifyContent: "space-between"
                  },

                  "& .passenger-type": {
                    fontSize: 16
                  }
                }
              }
            },

            "& .gimmonix-form": {
              "& .room-accordion": {
                border: "1px solid #e1eaff",
                boxShadow: "unset",

                "&::before": {
                  display: "none"
                },

                "&:not(:first-of-type)": {
                  marginTop: 16
                },

                "& .MuiAccordionSummary-root": {
                  background: styles.cardsAlt.header.background,
                  minHeight: "unset",
                  height: 60,
                  borderRadius: 12,
                  color: styles.cardsAlt.header.color,
                  width: "100%",
                  padding: "0 16px",

                  "&.Mui-expanded": {
                    borderRadius: "12px 12px 0 0"
                  },

                  "&:not(:first-of-type)": {
                    marginTop: 16
                  },

                  "& .MuiAccordionSummary-content": {
                    margin: 0,
                    fontSize: 20,
                    color: styles.brandPrimary.value
                  },

                  "& .MuiAccordionSummary-expandIconWrapper": {
                    color: styles.brandPrimary.value
                  }
                },

                "& .MuiAccordionDetails-root": {
                  padding: 16,

                  "& .title": {
                    color: styles.brandPrimary.value,
                    fontSize: 18,
                    marginBottom: 8,
                    display: "flex",
                    justifyContent: "space-between",

                    "&:not(:first-of-type)": {
                      marginTop: 16
                    }
                  }
                }
              }
            }
          },

          "& .step-payment": {
            "& .booking-info-container": {
              border: "2px solid #43a047",
              borderRadius: 8,
              padding: 16,
              backgroundColor: "#fff",
              marginBottom: 20,
              alignItems: "flex-start !important",

              "& .MuiSvgIcon-root": {
                color: "#43a047",
                marginRight: 10
              }
            },

            "& .title-container": {
              marginBottom: 5,
              justifyContent: "space-between",

              "& .title": {
                fontSize: 20,
                fontWeight: 500,
                color: styles.brandPrimary.value
              },

              "& .booking-number": {
                fontSize: 16,
                fontWeight: 500,
                color: "#3c3c3c"
              }
            },

            "& .error-payment-container": {
              border: `1px solid ${styles.thankyoupage.ko.backgroundColor}`,

              "& .MuiAlert-icon": {
                fontSize: 30
              },

              "& .title": {
                fontSize: 22,
                fontWeight: 600,
                color: styles.thankyoupage.ko.backgroundColor
              },

              "& .subtitle": {
                fontSize: 16,
                color: "#474847"
              }
            }
          },

          "& .steps-buttons": {
            width: "100%",
            left: 0,
            bottom: 0,
            position: "fixed",
            zIndex: 10,
            backgroundColor: "#ffffffe6",
            padding: "16px 0",
            height: 92,
            [theme.breakpoints.up("md")]: {
              boxShadow: "0 -4px 10px 0 #00000033"
            },
            [theme.breakpoints.down("md")]: {
              position: "relative",
              padding: "0",
              background: "none",
              height: "auto",
              marginBottom: 16,

              "& .steps-buttons-inner": {
                flexDirection: "column",
                width: "100%",

                "& .text-total-sumary": {
                  marginRight: "0px !important",
                  width: "100%",

                  "& .MuiFormControlLabel-root": {
                    marginTop: 5,
                    marginBottom: 5,
                    width: "100%",
                    justifyContent: "flex-end"
                  }
                }
              }
            },

            "& .MuiContainer-root": {
              justifyContent: "space-between"
            },

            "& .steps-buttons-inner": {
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",

              "& .text-total-sumary": {
                fontSize: 18,
                lineHeight: "22px",
                verticalAlign: "top",
                marginRight: 20,

                "& .text": {
                  verticalAlign: "top",
                  padding: " 0 4px"
                },

                "& .price": {
                  fontWeight: "600",
                  fontSize: 16,
                  lineHeight: "22px",
                  verticalAlign: "top",
                  padding: " 0 4px"
                },

                "& .amount": {
                  fontSize: 22,
                  lineHeight: "22px",
                  verticalAlign: "top",
                },

                "& .price-detail-total": {
                  justifyContent: "flex-end",

                  "& .MuiIconButton-root": {
                    //color: "#155dff",
                    color: styles.chipColor.background,
                    padding: "0 8px"
                  }
                },

                "& .MuiCheckbox-root": {
                  padding: "0 9px"
                }
              }
            },

            "& .help-container": {
              "& .icon-container": {
                color: "#fff",
                backgroundColor: "#0066cc",
                height: 60,
                width: 60,
                borderRadius: "50%",
                display: "flex",

                "& .MuiSvgIcon-root": {
                  margin: "auto",
                  fontSize: 32
                }
              },

              "& .message-container": {
                height: 60,
                boxShadow: "0 4px 4px #00000040",
                borderRadius: 12,
                marginLeft: 20,
                padding: 10,

                "& p": {
                  fontSize: 16,
                  lineHeight: "20px"
                }
              }
            },

            "& .btn-next-step": {
              minWidth: 240,
              padding: 16,
              fontSize: 16,
              [theme.breakpoints.down("md")]: {
                width: "100%"
              }
            }
          }
        },

        "& .purchase-card": {
          [theme.breakpoints.down("md")]: {
            borderRadius: 0
          },

          "& .MuiCardHeader-root": {
            backgroundColor: styles.cards.header.background,
            color: styles.cards.header.color,
            [theme.breakpoints.down("lg")]: {
              padding: "8px !important"
            },

            "& .MuiAvatar-root": {
              backgroundColor: "#ffffff33",
              overflow: "initial",

              "& .MuiBadge-badge": {
                top: "-15%",
                right: "-15%",
                backgroundColor: styles.badge.background,
                color: styles.badge.color
              }
            },

            "& .title-card-header": {
              fontSize: 20,
              [theme.breakpoints.down("sm")]: {
                fontSize: 18
              }
            },

            "& .title-card-subheader": {
              fontSize: 14,
              textWrap: "wrap",
              [theme.breakpoints.down("lg")]: {
                fontSize: 10
              },
              [theme.breakpoints.down("xl")]: {
                fontSize: 12
              }
            },

            "& .MuiCardHeader-action": {
              margin: "auto -3px",

              "& .MuiIconButton-root": {
                color: "#fff"
              }
            },
          },

          "& .item-resume": {
            "& .container-item-resume": {
              whiteSpace: "pre-line",

              "& .MuiSvgIcon-root": {
                marginLeft: 5,
                color: styles.chipColor.background,
              }
            }
          },

          "& .price-details-container": {
            padding: 16,
            backgroundColor: "#f8f8f8",
            [theme.breakpoints.down("md")]: {
              backgroundColor: styles.headings.color,

              "& > *, & .taxes, & .sub-amount, & .amount": {
                color: "#fff !important"
              }
            },

            "& .left": {
              "&:not(:first-of-type)": {
                padding: 4
              },

              "& .MuiTypography-root": {
                fontSize: 14,

                ":first-of-type": {
                  fontSize: 16
                }
              }
            },

            "& .taxes": {
              color: styles.headings.color
            },

            "& .sub-amount": {
              fontSize: 18,
              color: styles.headings.color
            },

            "& .amount": {
              fontSize: 22,
              color: styles.headings.color
            },

            "& .MuiGrid-item:has(> .hide)": {
              display:'none'
            }
          }
        },

        "& .message": {
          display: "flex",
          alignItems: "flex-start",
          marginTop: 20,

          "& .message-body": {
            padding: 16,
            alignItems: "flex-start !important"
          },

          "& .title-h4": {
            marginBottom: 12,
            fontSize: 18,
            color: styles.headings.color,
            fontWeight: 500,

            "& .message-icon": {
              marginTop: 4
            }
          },

          "& .content .rowmarkup": {marginLeft: 34},

          "& .message-icon": {
            marginRight: 12,
            color: styles.chipColor.background,
            alignSelf: 'flex-start'
          },

          "& .MuiTypography-body2": {
            "& .rowmarkup": {
              fontSize: 15,
              marginBottom: 5
            },

            "&:last-of-type": {
              "& .rowmarkup": {
                marginBottom: 0
              }
            }
          }
        },

        "& .remarks-container": {
          backgroundColor: "#ebecf933",
          width: "100%",

          "& .MuiButton-root": {
            color: styles.chipColor.background,
            margin: "4px 0 4px 16px",
            fontWeight: 500
          }
        },

        "& .secure-site": {
          display: "flex",
          alignItems: "center",
          padding: 12,
          marginTop: 20,

          "& .icon-secure-site": {
            flex: " 0 0 auto",
            marginTop: 0,
            marginBottom: "auto",

            "& .avatar": {
              width: 40,
              height: 40,
              display: "flex",
              overflow: "hidden",
              userSelect: "none",
              borderRadius: "50%",
              justifyContent: "center",
              border: "1px solid rgba(0, 0, 0, 0.2)",

              "& .MuiSvgIcon-root": {
                margin: "auto",
                color: styles.brandPrimary.value
              }
            }
          },

          "& .text-secure-site": {
            flex: "1 1 auto",
            textAlign: "center",
            padding: "0 22px",

            "& .title": {
              color: styles.headings.color,
              fontSize: 15
            },

            "& .subtitle": {
              color: "#606060",
              fontSize: 15,
              margin: "5px 0 15px"
            },

            "& .logos-sitio-seguro": {
              maxWidth: 160
            }
          }
        },

        "& .certificates": {
          "& img": {
            height: 50,
            objectFit: "contain",
            marginTop: 20,

            "&:not(:first-of-type)": {
              marginLeft: 20
            }
          }
        },

        "& .booking-status-ok": {
          "& > .MuiGrid-container": {
            justifyContent: "center"
          },

          "& .booking-message-header": {
            backgroundColor: styles.thankyoupage.ok.backgroundColor,
            color: styles.thankyoupage.ok.color
          },

          "& .booking-number": {
            borderColor: `${styles.thankyoupage.ok.backgroundColor} !important`
          }
        },

        "& .booking-status-ko": {
          "& > .MuiGrid-container": {
            justifyContent: "center"
          },

          "& .booking-message-header": {
            backgroundColor: styles.thankyoupage.ko.backgroundColor,
            color: styles.thankyoupage.ko.color
          },

          "& .booking-number": {
            borderColor: `${styles.thankyoupage.ko.backgroundColor} !important`
          }
        },

        "& .booking-status-pending": {
          "& > .MuiGrid-container": {
            justifyContent: "center"
          },

          "& .booking-message-header": {
            backgroundColor: styles.thankyoupage.paymentPending.backgroundColor,
            color: styles.thankyoupage.paymentPending.color
          },

          "& .booking-number": {
            borderColor: `${styles.thankyoupage.paymentPending.backgroundColor} !important`
          }
        },

        "& .status-booking-icon, & .status-payment-icon": {
          "&.status-ok": {
            color: styles.thankyoupage.ok.backgroundColor
          },

          "&.status-ko": {
            color: styles.thankyoupage.ko.backgroundColor
          },

          "&.status-pending": {
            color: styles.thankyoupage.paymentPending.backgroundColor
          }
        },

        "& .message-container": {
          padding: 20,
          position: 'relative',
          border: `2px solid #dadada`,

          "& .info-icon": {
            position: "absolute",
            top: -18,
            backgroundColor: "#fff",
            left: "50%",
            transform: "translate(-50%, 0%)",
            fontSize: 32
          },

          "& .timer": {
            fontSize: 32,
            fontWeight: "bold"
          },

          "&.status-pending": {
            backgroundColor: `${styles.thankyoupage.paymentPending.backgroundColor}0d`,
            border: `2px solid ${styles.thankyoupage.paymentPending.backgroundColor}`,

            "& .info-icon": {
              color: styles.thankyoupage.paymentPending.backgroundColor,
            }
          },

          "&.status-ko": {
            backgroundColor: `${styles.thankyoupage.ko.backgroundColor}0d`,
            border: `2px solid ${styles.thankyoupage.ko.backgroundColor}`,

            "& .info-icon": {
              color: styles.thankyoupage.ko.backgroundColor,
            }
          },

          "& .new-search-button": {
            backgroundColor: "#155dff",
            color: "#fff",
            marginTop: 15
          }
        },

        "& .booking-message ": {
          "& .booking-message-header": {
            [theme.breakpoints.down("sm")]: {
              alignItems: "center",
              justifyContent: "center"
            },

            "& .MuiAvatar-colorDefault": {
              backgroundColor: "rgba(255,255,255,.25)",
              color: styles.thankyoupage.ok.color
            },

            "& .MuiCardHeader-content": {
              [theme.breakpoints.down("sm")]: {
                display: "none"
              }
            },

            "& .MuiCardHeader-title": {
              fontSize: 22
            }
          },

          "& .card-divider": {
            border: "none",

            "&:nth-of-type(odd)": {
              backgroundColor: "#f8f8f8"
            },

            "& .booking-title": {
              marginTop: 16,
              marginBottom: 16,
              fontSize: 32,
              color: styles.brandPrimary.value,

              '&.error': {
                fontSize: 26,
              }
            },
          },

          "& .content": {
            maxWidth: 650,
            margin: "auto"
          },

          "& .row-booking-number, & .row-status-booking, & .row-status-payment" : {
            "& strong": {
              fontSize: 18
            },

            "& .status-booking, & .status-payment": {
              display: "flex",
              justifyContent: "center",
              fontSize: 18,
              alignItems: "center",

              "& .material-icons": {
                marginRight: 5
              }
            },

            "& .booking-detail": {
              fontSize: 16,
              color: "#707070"
            },

            "& .booking-number": {
              padding: '5px 10px',
              fontSize: 22,
              border: '1px solid black',
              borderRadius: '4px'
            },

            "& .paypal-button": {
              color: styles.brandPrimary.value,
              fontWeight: 500,
              backgroundColor: "#ffc439",

              "& img": {
                maxWidth: 60,
                marginLeft: 5
              }
            }
          },

          "& .card-content-booking": {
            fontSize: 16
          }
        },

        "& .thankyoupage": {
          margin: "24px 0",

          "& .inner-card-content-message": {
            width: "90%",
            margin: "auto",
            border: "1px solid",
            borderRadius: 16,
            overflow: "hidden",
            marginBottom: 25,
            borderColor: styles.thankyoupage.message_error.borderColor,

            "& .card-message-error": {
              backgroundColor: styles.thankyoupage.message_error.backgroundColor,
              color: styles.thankyoupage.message_error.color,
              borderColor: styles.thankyoupage.message_error.backgroundColor,
              position: 'relative',
              fontSize: 18,

              "& .icon-card-message": {
                color: styles.thankyoupage.message_error.color,
                top: 8,
                left: 8,
                position: "absolute",
                fontSize: 30
              }
            }
          },

          "& .preloader-final": {
            textAlign: 'center',
            maxWidth: 600,
            margin: "auto",
            borderRadius: 12,

            "& .preloader-dots": {
              color: 'white'
            },

            "& .preloader_final_title": {
              color: 'white',
              fontSize: 20
            },

            "& .lds-ellipsis": {
              display: "inline-block",
              position: "relative",
              width: 80,
              height: 40,

              "& div": {
                position: "absolute",
                top: 14,
                width: 13.33333,
                height: 13.33333,
                borderRadius: "50%",
                backgroundColor: 'white',

                "&:nth-of-type(1)": {
                  left: 8,
                  animation: "lds-ellipsis1 0.6s infinite",
                },

                "&:nth-of-type(2)": {
                  left: 8,
                  animation: "lds-ellipsis2 0.6s infinite",
                },

                "&:nth-of-type(3)": {
                  left: 32,
                  animation: "lds-ellipsis2 0.6s infinite",
                },

                "&:nth-of-type(4)": {
                  left: 56,
                  animation: "lds-ellipsis3 0.6s infinite",
                },

                "@keyframes lds-ellipsis1": {
                  "0%": {
                    transform: "scale(0)"
                  },

                  "100%": {
                    transform: "scale(1)"
                  }
                },

                "@keyframes lds-ellipsis2": {
                  "0%": {
                    transform: "translate(0, 0)"
                  },

                  "100%": {
                    transform: "translate(24px, 0)"
                  }
                },

                "@keyframes lds-ellipsis3": {
                  "0%": {
                    transform: "scale(1)"
                  },

                  "100%": {
                    transform: "scale(0)"
                  }
                }
              }
            },

            "& .card_header_preloader": {
              padding: '30px 20px 10px',
              backgroundColor: styles.brandSecondary.value
            },

            "& .card_header_preloader_icon": {
              color: styles.brandSecondary.value,
              fontSize: 30
            },

            "& .card_header_preloader_description": {
              fontSize: 18,
              [theme.breakpoints.up('md')]: {
                padding: '16px 80px'
              }
            },

            "& .card_header_preloader_avatar": {
              width: 60,
              height: 60,
              backgroundColor: 'white',
              margin: 'auto',
              marginBottom: 10
            },

            "& .quotes-selected": {
              marginBottom: 2
            }
          },
        },

        "& .footer": {
          [theme.breakpoints.down("sm")]: {
            marginBottom: "0px !important"
          }
        },

        "& .preload": {
          "& .main": {
            "& .MuiGrid-container": {
              margin: "auto",
              maxWidth: 1280,

              "@media (min-width: 1440px)": {
                maxWidth: 1440
              },

              "& .responsive svg": {
                width: "100%",
                height: "auto"
              }
            }
          }
        },

        "& .error-page": {
          padding: "30px 0",

          "& .title-step": {
            fontSize: 26,
            marginBottom: 0,

            [theme.breakpoints.down("sm")]: {
              fontSize: 22
            },

            [theme.breakpoints.up("md")]: {
              marginTop: 96,
              marginBottom: 20
            }
          },

          "& .description": {
            marginTop: 16,
            marginBottom: 25,
          },

          "& .img-error": {
            maxWidth: 510
          }
        },

        "& .non-refundable-container": {
          backgroundColor: "#fff",
          border: `2px solid ${styles.thankyoupage.paymentPending.backgroundColor}`,
          borderRadius: 12,
          height: 80,

          "& .left": {
            backgroundColor: styles.thankyoupage.paymentPending.backgroundColor,
            height: "100%",
            borderRadius: "9px 0 0 9px",
            padding: 10,
            color: "#fff"
          },

          "& .right": {
            flex: 1,
            height: "100%",
            justifyContent: "center",
            backgroundColor: `${styles.thankyoupage.paymentPending.backgroundColor}03`,

            "& p": {
              fontSize: 17
            }
          }
        },

        "& .header-step": {
          marginBottom: 12,

          "& .title-step": {
            color: styles.brandPrimary.value,
            fontSize: 26,

            "& .MuiSvgIcon-root": {
              marginRight: 10,
              border: `2px solid ${styles.brandPrimary.value}`,
              borderRadius: "50%"
            }
          }
        },

        "& .payment-method-card": {
          position: "relative",
          border: "1px solid #fff",

          "&:hover": {
            borderColor: "#5599d6"
          },

          "&:not(:last-of-type)": {
            marginBottom: 25
          },

          "& .MuiButton-root": {
            width: "100%",
            padding: 0,

            "& .payment-method-card-header": {
              width: "100%",
              minHeight: 60,
              textAlign: "left",

              "& .MuiCardHeader-content": {
                paddingRight: 40,

                "& .MuiGrid-root": {
                  justifyContent: "space-between"
                },

                "& .MuiTypography-root": {
                  fontSize: 20
                },

                "& .bank-image": {
                  width: 100
                }
              },

              "& .MuiCardHeader-action": {
                top: 0,
                bottom: 0,
                right: 16,
                margin: "auto",
                position: "absolute",
                width: 23,
                height: 23,
                color: "#606060"
              }
            }
          }
        },

        "& .payment-resume-selected": {
          padding: 16,
          overflow: "visible",

          "& .title": {
            fontSize: 22,
            color: styles.brandPrimary.value,
            fontWeight: 500,

            "& .MuiIcon-root, & .MuiSvgIcon-root": {
              marginRight: 10
            }
          },

          "& .content": {
            margin: "0 33px",

            "& .payment-info": {
              fontSize: 20,
              fontWeight: 500,
              color: "#3c3c3c",
              marginBottom: 20,

              "& .amount": {
                color: styles.brandPrimary.value,
                fontWeight: 600,
                margin: "2px 0 0 5px"
              }
            },

            "& .payment-message-container": {
              minHeight: 62,
              borderRadius: 12,
              display: "flex",
              marginBottom: 20,

              "& > *": {
                display: "flex",
                alignItems: "center"
              },

              "& .icon-container": {
                padding: "0 15px",
                borderRadius: "11px 0 0 11px",
                color: "#fff"
              },

              "&.ok": {
                border: "1px solid #7cbe4c",

                "& .icon-container": {
                  backgroundColor: styles.green
                },
              },

              "&.ko": {
                border: "1px solid #ff4a45",

                "& .icon-container": {
                  backgroundColor: "#ff4a45"
                },
              }
            }
          },

          "& .title-row": {
            justifyContent: "space-between",

            "& .bank-image": {
              width: 100
            }
          }
        },

        "& .billing-container": {
          marginTop: 20,

          "& .paper-form": {
            padding: 20
          },

          "& .rccs": {
            marginTop: 26
          },

          "& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0
          },

          /* Oculta las flechas en Firefox */
          "& input[type='number']": {
            MozAppearance: "textfield"
          }
        },

        "& .braspag-container": {
          "& .payment-resume-selected": {
            "& .MuiFormGroup-root": {
              "& > div:last-of-type .MuiFormControlLabel-root, & > .MuiFormControlLabel-root:last-of-type": {
                marginRight: 0
              },

              "& .MuiFormControlLabel-root": {
                marginRight: 48
              },

              "& .MuiFormHelperText-root": {
                fontSize: 13,
                fontWeight: "bold",
                marginLeft: 30
              }
            },

            "& .payment-info": {
              marginBottom: 0
            }
          },

          "& .paper-form": {
            "&:not(:last-of-type)": {
              marginBottom: 30
            },

            "& .title-section": {
              fontSize: 22,
              color: styles.brandPrimary.value,
              fontWeight: 600,
              height: "100%",
              display: "flex",
              alignItems: "center"
            },

            "& .amount-field": {
              "& > div": {
                marginLeft: "auto",

                "& .MuiFormControl-root": {
                  maxWidth: 235,

                  "& .MuiInputAdornment-root .MuiTypography-root, & input": {
                    fontSize: 20,
                    color: styles.brandPrimary.value,
                    fontWeight: 600
                  },

                  "& input": {
                    paddingLeft: 0
                  }
                }
              }
            },

            "& .amount": {
              fontSize: 20,
              color: styles.brandPrimary.value,
              fontWeight: 600
            },

            "& .autocomplete-button": {
              "& .MuiTypography-root": {
                color: "#1e1e1e",
                fontSize: 16,
                fontWeight: 500
              }
            }
          }
        },

        "& .pix-container": {
          "& .payment-resume-selected": {
            "& .MuiFormGroup-root": {
              "& > div:last-of-type .MuiFormControlLabel-root, & > .MuiFormControlLabel-root:last-of-type": {
                marginRight: 0
              },

              "& .MuiFormControlLabel-root": {
                marginRight: 48
              },

              "& .MuiFormHelperText-root": {
                fontSize: 13,
                fontWeight: "bold",
                marginLeft: 30
              }
            },

            "& .payment-info": {
              marginBottom: 0
            }
          },

          "& .form-container": {
            marginTop: 20,

            "& .title-section": {
              fontSize: 22,
              color: styles.brandPrimary.value,
              fontWeight: 500,
              marginBottom: 10
            }
          },

          "& .qr-container": {
            marginTop: 15,

            "& .qr-message": {
              fontSize: 16,
              fontWeight: 600
            },

            "& .qr-title": {
              fontSize: 22,
              fontWeight: "bold"
            },

            "& .qr-subtitle": {
              color: "#a1a1a1"
            },

            "& .qr-code": {
              margin: "20px 0"
            },

            "& .total-liquidation": {
              marginRight: "auto",
              padding: "0 10%",
              fontSize: 22,
              fontWeight: 600
            },

            "& .instructions-container": {
              "& .instructions-title": {
                fontSize: 22,
                fontWeight: "bold"
              },

              "& .instructions-list": {
                listStyle: "auto",
                paddingLeft: 25,
                fontSize: 16,

                "& .instructions-item": {
                  fontSize: 16,
                  lineHeight: 1.7,
                  paddingLeft: 5
                }
              }
            }
          }
        }
      },
      "& .timer-checkout": {
        padding: "5px 10px",
        color: styles.timer.color,
        backgroundColor: styles.timer.background,
        borderRadius: 12,
        marginLeft: "auto",

        "& .timer-content": {
          flex: 1,
          marginLeft: 10,

          "& .right-side": {
            flex: 1,
            justifyContent: "space-between",

            "& .time": {
              fontSize: 24
            },

            "& .tooltip-container": {
              display: "flex",
              justifyContent: "flex-end"
            }
          }
        }
      },
      "& .dialog-timer": {
        "& .MuiDialogTitle-root": {
          fontSize: 24
        },

        "& .MuiDialogContent-root": {
          fontSize: 16
        },

        "& .MuiButton-root": {
          backgroundColor: styles.brandPrimary.value,
          color: "#fff"
        }
      },
      "& .modal-detail-purchase": {
        "& .MuiDialog-paper": {
          borderRadius: 12
        },

        "& .MuiDialogTitle-root": {
          padding: 0,

          "& .purchase-card-modal": {
            borderRadius: "4px 4px 0 0"
          },

          "& .MuiCardHeader-root": {
            backgroundColor: styles.brandPrimary.value,
            color: "#fff",
            [theme.breakpoints.down("sm")]: {
              padding: "8px !important"
            },

            "& .MuiAvatar-root": {
              backgroundColor: "#ffffff33",
              overflow: "initial",

              "& .MuiBadge-badge": {
                top: "-15%",
                right: "-15%",
                backgroundColor: styles.badge.background,
                color: styles.badge.color
              }
            },

            "& .title-card-header": {
              fontSize: 20,
              [theme.breakpoints.down("sm")]: {
                fontSize: 18
              }
            },

            "& .title-card-subheader": {
              fontSize: 14,
              [theme.breakpoints.down("sm")]: {
                fontSize: 12,
                maxWidth: 216
              }
            },

            "& .MuiCardHeader-action": {
              margin: "auto -3px",

              "& .MuiIconButton-root": {
                color: "#fff"
              }
            },
          },
        },

        "& .dialog-content-resume": {
          padding: "16px 0 0",

          "& .products": {
            padding: "0 24px"
          },

          "& .prices": {
            padding: "0 24px",

            "& .MuiDivider-root": {
              margin: "12px 0",
              borderWidth: 1
            },

            "& .left": {
              "&:not(:first-of-type)": {
                padding: 4
              },

              "& .MuiTypography-root": {
                fontSize: 14,

                ":first-of-type": {
                  fontSize: 16
                }
              }
            },

            "& .sub-amount": {
              fontSize: 18,
              color: styles.headings.color
            },

            "& .amount": {
              fontSize: 24,
              color: styles.headings.color
            },

            "& .MuiGrid-item:has(> .hide)": {
              display:'none'
            }
          },

          "& .dialog-footer": {
            padding: 16,

            "& .MuiCard-root": {
              borderRadius: 12,

              "& .MuiCardHeader-root": {
                backgroundColor: "#ebecf933",

                "& .MuiCardHeader-avatar": {
                  display: "flex",
                  color: "#6695ff"
                },

                "& .MuiCardHeader-title": {
                  fontSize: 20,
                  fontWeight: 500,
                  color: styles.brandPrimary.value
                }
              },

              "& .MuiCardContent-root": {
                padding: 16,

                "& .details-container": {
                  display: "flex",
                  alignItems: "flex-start"
                },

                "& img": {
                  maxWidth: 250,
                  marginRight: 10,
                  objectFit: "contain"
                },

                "& .details": {
                  "& > .row": {
                    marginBottom: 5,
                    whiteSpace: "pre-line"
                  },

                  "& .title": {
                    marginRight: 5
                  },

                  "& .title-h3 .title": {
                    color: styles.headings.color,
                    fontSize: 20,
                    fontWeight: 500
                  },

                  "& .strong-title .title": {
                    fontWeight: "bold"
                  }
                }
              }
            }
          }
        },

        "&.trains-details": {
          "& .dialog-footer": {
            backgroundColor: "#f8f8f8",
            marginTop: 10
          },

          "& .segment-container": {
            "&:not(:first-of-type)": {
              marginTop: 16
            },

            "&.tickets": {
              "& .date": {
                margin: "3px 0",
                textTransform: "capitalize",
                color: styles.headings.color
              }
            },

            "&.passes": {
              "& .date b": {
                textTransform: "capitalize"
              }
            },

            "& .segment": {
              fontSize: 17,
              color: "#155dff",
              fontWeight: 500
            },

            "& .duration": {
              color: styles.headings.color,

              "& .changes": {
                color: "#155dff",
                textDecoration: "underline",
                marginRight: 5
              }
            },

            "& img": {
              marginLeft: 5,
              height: 20,
              maxWidth: "100px"
            },

            "& .scales-container": {
              marginTop: 15,
              borderRadius: 12,
              padding: 10,
              backgroundColor: "#f1f5ff"
            },

            "& .conditions-card": {
              "& .type-title": {
                color: styles.headings.color,
                fontWeight: 600,
                fontSize: 16
              },

              "& .short-condition .MuiSvgIcon-root": {
                marginRight: 3
              },

              "& .title": {
                fontSize: 28,
                fontWeight: 500,
                color: styles.brandPrimary.value
              },

              "& .condition": {
                marginTop: 3,
                alignItems: "flex-start !important",

                "& .MuiSvgIcon-root": {
                  marginRight: 5,
                  marginTop: 1
                },

                "& .luggage-condition, & .food-condition": {
                  "& p:not(:first-of-type)": {
                    marginTop: 5
                  }
                }
              },

              "& .conditions p": {
                margin: "14px 0"
              },

              "& .minor-message": {
                marginTop: 14
              },

              "& .show-more-button": {
                padding: 0,
                fontSize: 16,
                color: "#155dff"
              }
            }
          }
        },

        "&.flights-details": {
          "& .fare-features-card": {
            marginBottom: 20,

            "& .title-container": {
              backgroundColor: `${styles.brandPrimary.value} !important`,
              padding: 10,

              "& .MuiCardHeader-title": {
                fontSize: "16px !important",
                color: "#fff !important",
              }
            },

            // "& .MuiCardContent-root": {
              // padding: "0px !important",

              "& .fare-feature": {
                padding: 10,

                "& .MuiSvgIcon-root": {
                  color: "#bdbdbd",
                  marginRight: 5,

                  "&.included": {
                    color: styles.brandPrimary.value
                  }
                }
              },
            // }
          },

          "& .option-accordion": {
            margin: 0,
            boxShadow: "none",

            "&:not(:first-of-type)": {
              marginTop: 10
            },

            "&::before": {
              display: "none"
            },

            "& .MuiAccordionSummary-root": {
              padding: "0 15px",

              "& .MuiAccordionSummary-content": {
                margin: "6px 0"
              },

              "& .option-container": {
                height: 66,
                width: "100%",

                "& .airline-logo": {
                  width: "100%",
                  height: "auto"
                },

                "& .multiple-logo": {
                  width: 50,
                  height: "auto",
                  color: styles.brandPrimary.value
                },

                "& .title": {
                  fontWeight: 'bold',
                  fontSize: 18
                },

                "& .time": {
                  fontSize: 16
                },

                "& .scales-text": {
                  fontWeight: 500,

                  "&.direct": {
                    color: styles.accentColor
                  }
                },

                "& .scale-line": {
                  height: 2,
                  width: 90,
                  backgroundColor: styles.accentColor,
                  margin: "4px 0",
                  position: "relative",

                  "& .circles-container": {
                    width: "100%",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    justifyContent: "center",

                    "& .circle": {
                      border: "1px solid #a01127",
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                      height: 10,
                      width: 10,

                      "&:not(:last-of-type)": {
                        marginRight: 5
                      }
                    }
                  }
                },

                "& .plus-days": {
                  marginLeft: 5
                },

                "& .MuiTypography-root": {
                  color: styles.brandPrimary.value
                },

                "& .baggage-container-icons": {
                  "& svg": {
                    color: styles.green,

                    "&:not(:last-of-type)": {
                      marginRight: 10
                    },

                    "&.not-included": {
                      color: "#bdbdbd"
                    }
                  }
                }
              }
            },

            "& .MuiAccordionDetails-root": {
              padding: 0,

              "& .flight-scales-container, & .baggage-container": {
                padding: 16
              }
            }
          }
        }
      },
      "& .wrapper-login": {
        "& .form": {
          color: styles.brandPrimary.value,

          "& .title": {
            fontSize: 39,
            fontWeight: 700,
            lineHeight: 1
          },

          "& .subtitle": {
            fontSize: 16,
            fontWeight: 500
          },

          "& form": {
            marginTop: 10
          },

          "& .MuiFormLabel-root": {
            fontSize: 14,
            fontWeight: 500,
            marginBottom: 6,
            color: styles.brandPrimary.value,

            ":not(:first-of-type)": {
              marginTop: 12
            }
          },

          "& .MuiInputBase-root": {
            backgroundColor: "#fff"
          },

          "& .submit-button": {
            height: 40,
            marginTop: 22,
            fontSize: 18,
            fontWeight: 700
          },

          "& .buttons-bottom": {
            marginTop: 22,
            justifyContent: "space-between",

            "& .MuiButton-root": {
              padding: 0,
              fontSize: 14
            },

            "& .recover-button.blink": {
              animation: "blink 1.5s infinite"
            },

            "@keyframes blink": {
              "0%, 66%": {
                opacity: 1
              },

              "33%": {
                opacity: 0
              },
            },
          },

          "& .empty-select": {
            color: "#60606061"
          },

          "& .go-login-button": {
            color: "#fff",
            borderColor: "#fff",
            fontSize: 18,
            fontWeight: 700
          },

          "& .MuiAlert-root": {
            marginTop: 8
          },

          "& .device-code-error-container": {
            marginTop: 20,
            padding: 10,
            borderRadius: 8,
            backgroundColor: darkenHexColor(styles?.brandPrimary?.value, styles?.brandPrimary?.darken),

            "& .MuiSvgIcon-root": {
              color: styles?.errorColor,
              marginRight: 15
            },

            "& .MuiTypography-root": {
              fontSize: 14
            }
          }
        },

        "&.default-login": {
          height: "100vh",

          "& > .MuiGrid-container": {
            height: "100%"
          },

          "& .bg-login": {
            height: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",

            "& .logo": {
              top: 120,
              display: "block",
              position: "relative",
              maxWidth: 300,
              margin: "auto",
              filter: "brightness(0) invert(1)"
            }
          }
        },

        "&.login": {
          "& .banner-form-container": {
            display: "flex",
            position: "relative",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",

            "& img": {
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
              position: "absolute"
            },

            "& .form-container": {
              margin: "30px auto",
              zIndex: 10,

              "& .form": {
                padding: 20,
                backgroundColor: styles.login.backgroundColor,
                borderRadius: 16,
                color: "#fff",

                "& .MuiFormLabel-root": {
                  color: "#fff",
                },

                "& .MuiButton-root.MuiButton-containedPrimary:not(.Mui-disabled)": {
                  backgroundColor: styles.login.button.backgroundColor,
                  color: styles.login.button.color,
                }
              },

              "& .title-container": {
                textAlign: "center",
                paddingLeft: 40,

                "& .MuiTypography-root": {
                  fontSize: 60,
                  fontWeight: 700,
                  color: "#fff",
                  lineHeight: '64px'
                }
              }
            }
          },

          "& .banners-container": {
            margin: "30px auto",

            "& img": {
              objectFit: "cover",
              width: "100%",
              height: "100%"
            },

            "& .horizontal-banners": {
              marginTop: 24,

              "& > .MuiGrid-item": {
                position: "relative",

                "& img": {
                  borderRadius: 16
                },

                "& .MuiTypography-root": {
                  position: "absolute",
                  fontSize: 39,
                  fontWeight: 700,
                  color: "#fff",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)"
                }
              }
            }
          },

          "& .MuiButton-root.Mui-disabled": {
            backgroundColor: "#e3e3e3"
          },
        }
      },
      "& .assist-container": {
        display: "flex",
        height: 65,
        boxShadow: "0 4px 4px 0px #00000040",
        borderRadius: 12,
        cursor: "pointer",

        "& .left-side": {
          backgroundColor: "#dd3e1b",
          flex: 1,
          borderRadius: "12px 0 0 12px",
          justifyContent: "center",
          color: "#fff",

          "& .hand-icon": {
            transform: "rotate(90deg)",
            marginRight: 30,
            fontSize: "40px !important"
          },

          "& .MuiTypography-root": {
            fontSize: 20,
            fontWeight: 600
          },

          "& .MuiSvgIcon-root": {
            fontSize: 30,

            "&:not(.hand-icon)": {
              marginRight: 10
            }
          }
        },

        "& .right-side": {
          backgroundColor: "#1751a1",
          display: "flex",
          borderRadius: "0 12px 12px 0",

          "& img": {
            height: 40,
            margin: "auto 20px"
          }
        }
      },
      "& .comparation_view-main-box":{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        backgroundColor: `${styles.brandPrimary.value}CC`,
        opacity: 1,
        transition: 'opacity 0.5s, visibility 0.5s !important',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
      "& .comparation_view-grid-coverage-cards":{
        "& .comparation_view-coverage_grid-item:first-child":{
          marginLeft: -16
        }
      },
      "& .col-add-more-coverages":{
        position: 'relative',
        top: -100,
        "& .insurance-card__name_icon":{
          marginTop: '0!important'
        },
        "& .insurance-card__header":{
          borderTop: `2px solid ${styles.accentColor}`,
        }
      },
      "& .title-add-more-coverages":{
        color: styles.headings.alternative.color,
        fontSize: '16px',
        fontWeight: 'bold' ,
        marginBottom: 12,

      },
      "& .comparation_view-coverage_card-item":{
        backgroundColor: '#fff',
        borderRadius: 16,
        position: 'relative',
        padding: 16,
        overflow: 'visible',
        height: '100%'
      },
      "& .coverages-comparation-names": {
        "& .coverage-name":{
          padding: '5px 8px',
          height: 32,
          marginBottom: 4,
          backgroundColor: '#E1EAFF',
          color: styles.headings.alternative.color,
          fontWeight: 400,
          fontSize: 12,
          alignItems:'center',
          display: 'flex',
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textWrap: "wrap",
          [theme.breakpoints.down("lg")]: {
            fontSize: 11
          }
        }
      },
      "& .coverage_comparation-item":{
        position: 'relative',
        //boxShadow: '0 2px 6px rgba(0,0,0,0.08)',
        backgroundColor: styles.body.background,
        minHeight: 32,

        "& .buttonClose":{
          overflow: 'visible',
          position: 'absolute',
          top: -12,
          right: -12,
          backgroundColor: '#fff',
          borderRadius: '50%',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          width: 32,
          height: 32,
          '&:hover': { backgroundColor: '#f5f5f5' },
        },
        "& .coverage_comparation-header":{
            height: '100px',
            backgroundColor: '#E1EAFF',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            borderRadius: '16px 16px 0 0',
            borderTop: `2px solid ${styles.accentColor}`
        },
        "& .coverage_comparation-image":{
            width: 60,
            height: 60,
            borderRadius: '50%',
            objectFit: 'cover',
        },
        "& .coverage_comparation-title":{
          color: 'black'
        },
        "& .coverage_comparation-name":{
            color: styles.headings.alternative.color,
            fontWeight: 'bold',
        },
        "& .coverage_comparation-row-item":{
            padding: '5px 8px',
            marginBottom: 4,
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 400,
            minHeight: 32,
            alignItems: 'center'
        },
        "& .coverage_comparation-price":{
            //backgroundColor: '#edf4ff',
            backgroundColor: 'white',
            textAlign: 'center',
            overflow: 'hidden',
            borderRadius: '0 0 8px 8px',
            boxShadow: '0 2px 6px rgba(0,0,0,0.08)',
        },
        "& .coverage_comparation-price-title":{
            color: styles.headings.alternative.color,
            fontWeight: 'bold',
        },
        "& .coverage_comparation-totalpvp":{
            fontSize: '0.875rem',
            textDecoration: 'line-through',
            marginRight: 4,
        },
        "& .coverage_comparation-total":{
          color: styles.headings.alternative.color,
          fontWeight: 'bold',
          fontSize: '1.25rem',
        },
        "& .comparation_btn-select":{
          //borderWidth: 3,
          //fontWeight: 'bold',
          textTransform: 'none',

        },

      },
      "& .comparation_view-actions-buttons":{
          height: '100px',
        },
      "& .comparation-component-assist":{
        "& .insurance-card":{ height: 'auto'},
        "& .comparation_view-title":{
          fontSize: 24,
          fontWeight: 500,
          color:styles.headings.color,
          "& .icon-comparation":{
            color:styles.headings.color,
          },
        },

      },
      "& .comparation_view-fixed-box":{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        backgroundColor: 'hsla(225,88.5%,44.3%,0.8)',
        boxSizing: 'border-box',
        opacity: 1,
        transition: 'opacity 0.5s, visibility 0.5s !important',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
      "& .comparation_view-button-clear":{
        color: '#fff',
        textTransform: 'none',
        fontWeight: 400,
        fontSize: '0.875rem',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
      },
      "& .comparation_view-button-compare":{
        color: '#fff',
        borderColor: '#fff',
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '0.875rem',
        borderRadius: '8px',
        '&:hover': { borderColor: '#f0f0f0', },
      },
      "& .comparation_view-table_container": {
        "& .insurance-card__price-old":{
          fontSize: 12
        },
       "& .insurance-card__price-amount":{
        fontSize:'1.2rem'
       }
      },
      "& .comparation_view-table_container_sticky": {
        position: 'sticky',
        top: 0,
        zIndex: 1000,

        "& .MuiGrid-item":{
          backgroundColor: styles.body.background
        }
      },
      "& .reservation-container": {
        border: `1px solid ${styles.thankyoupage.ok.backgroundColor}`,
        borderRadius: 12,
        height: 120,
        backgroundColor: "#fff",

        "& .left-side": {
          backgroundColor: styles.thankyoupage.ok.backgroundColor,
          color: styles.thankyoupage.ok.color,
          height: "100%",
          borderRadius: "11px 0 0 11px",
          padding: "0 20px"
        },

        "& .right-side": {
          textAlign: "center",
          flex: 1,

          "& .MuiButton-root": {
            marginTop: 10
          },

          "& .MuiGrid-item": {
            padding: "0 8px",
            justifyContent: "center"
          }
        }
      },
      "& .favorite-page": {
        "& .search-container": {
          backgroundColor: styles.search.background,

          "& .search-box-container": {
            padding: 0,
            display: "block",

            "& form": {
              paddingLeft: 0
            },

            "& .search-box": {
              borderRadius: 0,
              padding: "0 0 20px"
            }
          }
        },

        "& .results-container": {
          padding: "24px 0",

          "& > .MuiGrid-item": {
            paddingTop: 16,

            "& > .title": {
              fontSize: 24,
              fontWeight: 500,
              marginBottom: 16,
              color: styles.headings.color,

              "@media(max-width: 425px)": {
                fontSize: 18
              },

              "@media(min-width: 426px) and (max-width: 768px)": {
                fontSize: 20
              },

              "& .MuiSvgIcon-root": {
                marginRight: 5
              }
            }
          },

          "& .sort-options": {
            height: 36,
            marginBottom: 16
          },

          "& .hotel-content": {
            minHeight: 210,

            "& .destination": {
              marginBottom: "0px !important",
              //color: "#155dff",
              color: styles.chipColor.background,
              fontSize: 16
            },

            "& .location": {
              marginRight: 15
            },

            "& .boardbases": {
              marginLeft: 15
            },

            "& .right-side": {
              paddingTop: 0,

              "& .MuiButton-root": {
                margin: "10px auto"
              }
            }
          }
        },
      },
      "& .favorite-icon": {
        color: styles.favoriteIcon
      },
      "& .reservation-popper": {
        width: 360,
        maxWidth: "100vw",
        marginTop: 5,
        padding: 15,
        borderRadius: 4,
        backgroundColor: "#fff",
        boxShadow: "#d3d3d3 0px 0px 3px 0px",
        display: "flex",
        flexDirection: "column",

        "& .reservation": {
          padding: 10,
          marginBottom: 15,
          border: "1px solid",
          borderColor: styles.chipColor.background,
          borderRadius: 16,
          display: "flex",
          flexDirection: "column",

          "&.selected": {
            borderColor: "#d32f2f80"
          },

          "& .reservation-title": {
            marginBottom: 6,

            "& .reservation-number": {
              color: styles.brandPrimary.value,
              fontSize: 20,
              fontWeight: 700
            },

            "& .status": {
              fontSize: 13,
              padding: "2px 8px",
              borderRadius: 50,
              color: "#fff",
              "&.open" :{
                backgroundColor : "#22ee65",
              },
              "&.cancelled" :{
                backgroundColor : "#0000ff",
              },
              "&.closed" :{
                backgroundColor : "#ff0000",
              }
            }
          },

          "& .title": {
            color: "#606060",
            fontWeight: 500,
            marginRight: 5
          },

          "& .MuiBadge-root": {
            "&:not(:last-of-type)": {
              marginRight: 5
            },

            "& .custom-icon, & .MuiSvgIcon-root": {
              height: 20,
              color: styles.brandPrimary.value
            },

            "& .MuiBadge-badge": {
              //backgroundColor: "#155dff",
              backgroundColor: styles.chipColor.background,
              color: "#fff",
              top: 0,
              height: 17,
              minWidth: 17,
              padding: "0 4px"
            }
          }
          ,

          "& .continue-button": {
            color: "#fff",
            //backgroundColor: "#155dff",
            //backgroundColor: styles.chipColor.background,
            backgroundColor: styles.navbarTop?.button?.color,

            "& .MuiSvgIcon-root": {
              marginRight: 5
            }
          },

          "& .cancel-button": {
            borderRadius: "50px",

            "& .MuiSvgIcon-root": {
              marginLeft: 5
            }
          }
        },

        "& .more-button": {
          //color: "#155dff",
          //color: styles.chipColor.background,
          //borderColor: "#155dff",
          //borderColor: styles.chipColor.background,
          color: styles.navbarTop?.button?.color,
          borderColor: styles.navbarTop?.button?.color,
        },
      },
      "& .closable-modal": {
        "& .MuiDialog-paper": {
          overflow: "initial"
        },

        "& .close-button": {
          top: -15,
          left: "calc(100% - 15px)",
          backgroundColor: "#fff",
          height: 28,
          minHeight: 28,
          width: 28,
          position: "absolute"
        }
      },
      "& .menu-list": {
        display: "flex",
        alignItems: "center",
        gap: "8px",

        "& .MuiButtonBase-root": {
          textTransform: "uppercase"
        }
      },
      "& .MuiPopover-paper": {
        maxHeight: "500px !important"
      },
      "& .trains-results": {
        "& .results-component": {
          marginTop: 6,
          marginBottom: 30,

          "& .left-side": {
            "& > .MuiGrid-root": {
              backgroundColor: "#fff",
              borderRadius: 12
            },
            "& .title-container.row":{
              marginBottom: '32px', width: '100%',
            },

            "& .results-tabs": {
              "& .MuiTabScrollButton-root": {
                width: 30,

                "&  ~ .MuiTabs-scroller": {
                  "& .MuiTab-root": {
                    borderRadius: "0px !important"
                  }
                }
              },

              "& .MuiTabs-scroller": {
                padding: "0 1px",

                "& .MuiTab-root": {
                  textTransform: "none",
                  border: `1px solid #155dff`,

                  "&:not(.Mui-disabled)": {
                    color: "#155dff",
                  },

                  "&.Mui-selected": {
                    color: "#fff",
                    backgroundColor: "#155dff"
                  },

                  "&:first-of-type": {
                    borderTopLeftRadius: 12
                  },

                  "&:last-of-type": {
                    borderTopRightRadius: 12
                  },

                  "& > .MuiGrid-root": {
                    marginRight: "auto"
                  },

                  "& .train-icon": {
                    marginRight: 10
                  },

                  "& .destination": {
                    fontSize: 14,
                    fontWeight: 500,
                    flexWrap: "wrap",
                    "& .row": {
                      fontSize: 16,
                      flexWrap: "wrap",
                      textAlign: "start"
                    }
                  },
                  "& .date": {
                    textTransform: "capitalize",
                    marginLeft: 5,
                    fontSize: 14
                  },
                  "& .message-no-results": {
                    color: "#808080",
                    border: "1px solid #808080",
                    borderRadius: 4,
                    padding: 4
                  }
                }
              },

              "& .MuiTabs-indicator": {
                display: "none"
              }
            },

            "& .tab-panel": {
              "& .title-container": {
                borderBottom: "1px solid #e1eaff",
                justifyContent: "space-between",
                padding: 15,

                "& .title": {
                  color: styles.brandPrimary.value,
                  fontSize: 18
                },

                "& .filters": {
                  "& .MuiButton-root": {
                    borderRadius: 25,
                    color: "#155dff",
                    borderColor: "#155dff",
                    fontSize: 14,
                    marginLeft: 15,

                    "&.selected": {
                      backgroundColor: "#155dff",
                      color: "#fff"
                    }
                  }
                }
              },

              "& .recommended-container": {
                backgroundColor: styles.brandSecondary.value,
                color: "#fff",
                padding: 8,
                fontSize: 14,

                "& .MuiSvgIcon-root": {
                  marginRight: 8
                }
              },

              "& .highlight-container": {
                border: "1px solid #aac0f1",
                padding: 15,

                "&:hover": {
                  backgroundColor: "#f1f5ff"
                },

                "&.recommended": {
                  borderColor: styles.brandSecondary.value
                },

                "& .segment-data": {
                  display: "flex",
                  justifyContent: "space-between",

                  "& .time": {
                    fontSize: 18,
                    color: styles.brandPrimary.value,
                    fontWeight: 600
                  },

                  "& .arrow": {
                    color: "#155dff",
                    margin: "0 5px"
                  },

                  "& .duration": {
                    color: styles.brandPrimary.value
                  },

                  "& .MuiButton-root": {
                    marginLeft: 5,
                    padding: 0,
                    color: "#155dff",
                    textDecoration: "underline"
                  },

                  "& .providers-container": {
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    height: 72,
                    margin: "auto",

                    "& > *": {
                      margin: 3,
                      maxHeight: 30,
                      maxWidth: "calc(50% - 6px)",

                      "&:only-child": {
                        maxHeight: 45
                      }
                    },
                  }
                },

                "& .prices-container": {
                  justifyContent: "center",

                  "& .offer-button-container": {
                    position: "relative",
                    minWidth: 90,

                    "& .cheapest-tag": {
                      backgroundColor: styles.green,
                      color: "#fff",
                      borderRadius: 4,
                      fontSize: 10,
                      padding: "2px 8px",
                      position: "absolute",
                      top: -25
                    },

                    "& .offer-button": {
                      color: "#155dff",
                      borderColor: "#155dff",
                      padding: "2px 15px",
                      fontSize: 14,

                      "&.selected-offer": {
                        color: "#fff",
                        backgroundColor: "#155dff"
                      }
                    },

                    "& .message-fare-container": {
                      flexWrap: "wrap",
                      marginTop: 5,
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",

                      "& .message-fare": {
                        fontSize: 12,
                        fontWeight: 500,
                        color: "#ff0000"
                      },

                      "& .message-fare-button": {
                        fontSize: 12,
                        padding: 0,
                        margin: "0 2px"
                      }
                    }
                  }
                }
              },

              "& .no-direct-trains-message": {
                fontSize: 24,
                padding: "50px 0",
                textAlign: "center"
              }
            },

            "& .headers-container": {
              height: 64,
              padding: "0 15px",

              "& .header": {
                "&.schedule-header": {
                  color: styles.brandPrimary.value,

                  "& .MuiButton-root": {
                    color: "#155dff",
                    borderColor: "#155dff",
                    borderRadius: 25,
                    fontSize: 13
                  }
                },

                "&:not(.schedule-header)": {
                  justifyContent: "center",
                  color: styles.brandPrimary.value
                }
              }
            }
          },

          "& .right-side": {
            "& > .MuiCard-root": {
              borderRadius: 12,
              position: "sticky",
              top: 24,

              "& > .MuiCardHeader-root": {
                backgroundColor: styles.brandPrimary.value,
                textAlign: "center",

                "& .MuiTypography-root": {
                  color: "#fff"
                },

                "& .price-currency": {
                  fontSize: 28
                },

                "& .price": {
                  fontSize: 32,
                  color: "#fff",
                  marginLeft: 4,
                  fontWeight: 600
                }
              },

              "& > .MuiCardContent-root": {
                minHeight: 221,
                padding: "7px 15px 15px",

                "& .no-tickets": {
                  marginTop: 24,
                  marginBottom: 16
                },

                "& .passengers": {
                  color: styles.brandPrimary.value,
                  textAlign: "center",
                  fontWeight: 600,
                  marginBottom: 7
                },

                "& .submit-button": {
                  fontSize: 18,
                  padding: "3px 16px"
                },

                "& .segment-resume": {
                  marginTop: 15,
                  borderRadius: 12,
                  border: "1px solid #e6e6e6",
                  width: "100%",

                  "& .MuiAccordion-root": {
                    marginBottom: 0,
                    boxShadow: "none",
                    borderRadius: "12px 12px 0 0",

                    "& .MuiAccordionSummary-root": {
                      padding: 16,
                      backgroundColor: "#f1f5ff",
                      borderRadius: "12px 12px 0 0",

                      "& .MuiAccordionSummary-content": {
                        margin: 0,

                        "& .segment-info": {
                          color: "#155dff",

                          "& .train-icon": {
                            marginRight: 5
                          },

                          "& .destination": {
                            fontSize: 14,

                            "& .row": {
                              fontSize: 16,
                              fontWeight: 500
                            },

                            "& .MuiSvgIcon-root": {
                              margin: "0 5px"
                            }
                          }
                        },

                        "& .date": {
                          color: styles.brandPrimary.value,
                          textTransform: "capitalize",
                          margin: "2px 0",

                          "& .MuiSvgIcon-root": {
                            marginRight: 5
                          }
                        },

                        "& .amenities-container": {
                          marginLeft: 20,

                          "& .MuiIcon-root": {
                            marginRight: 5
                          }
                        },

                        "& .duration": {
                          color: styles.brandPrimary.value,

                          "& .MuiButton-root": {
                            marginLeft: 5,
                            padding: 0,
                            color: "#155dff",
                            textDecoration: "underline"
                          },
                        },

                        "& .providers-container": {
                          marginLeft: 15,

                          "& > img": {
                            height: 20
                          },

                          "& img:not(:last-of-type)": {
                            marginRight: 10
                          }
                        }
                      }
                    },

                    "& .MuiAccordionDetails-root": {
                      padding: 0,

                      "& .title": {
                        fontSize: 20,
                        fontWeight: 700,
                        color: styles.brandPrimary.value
                      },

                      "& .scales-container": {
                        padding: "16px 16px 0",

                        "& .train-logo": {
                          marginLeft: 5,
                          height: 20
                        }
                      },

                      "& .flexible-container": {
                        marginTop: 10,
                        margin: "10px 16px 0",
                        border: "1px solid #e6e6e6",
                        borderBottom: "none",
                        borderRadius: "12px 12px 0 0",

                        "& .option": {
                          cursor: "pointer",
                          padding: 16,
                          borderBottom: "1px solid #e6e6e6",
                          alignItems: "flex-start",
                          flex: 1,

                          "&.selected-option": {
                            backgroundColor: "#f1f5ff",
                            borderBottomColor: "#155dff",

                            "& .name": {
                              color: "#155dff"
                            },

                            "& .value": {
                              color: styles.brandPrimary.value
                            },
                          },

                          "& > .MuiGrid-root": {
                            textAlign: "start",

                            "& .MuiTypography-root": {
                              fontSize: 16,
                              fontWeight: 500,
                              textTransform: "none"
                            }
                          }
                        },

                        "& .MuiTabScrollButton-root": {
                          width: 35,
                          borderBottom: "1px solid #e6e6e6",

                          "&.Mui-disabled": {
                            opacity: 1,

                            "& .MuiSvgIcon-root": {
                              opacity: 0
                            }
                          },

                          "&:not(.Mui-disabled)": {
                            backgroundColor: "#e6e6e64d"
                          }
                        }
                      },

                      "& .tab-panel": {
                        margin: "0 16px",

                        "& .resume-option": {
                          border: "1px solid #e6e6e6",
                          borderTop: "none",
                          borderRadius: "0 0 12px 12px",
                          padding: 10,
                          color: "#000000de",
                          fontSize: 14,
                          fontWeight: 500,

                          "& .MuiSvgIcon-root, & .MuiIcon-root": {
                            marginRight: 3,
                          },

                          "& .conditions-button": {
                            padding: 0,
                            marginTop: 5,
                            color: styles.brandPrimary.value,

                            "& .MuiSvgIcon-root": {
                              fontSize: 14
                            }
                          },

                          "& .type-title": {
                            color: styles.headings.color,
                            fontWeight: 600,
                            fontSize: 16
                          },

                          "& .short-conditions": {
                            paddingTop: 0,
                            display: "flex",
                            flexWrap: "wrap",

                            "& .condition": {
                              paddingTop: 8,
                              flex: 1,
                              width: "50%",
                              minWidth: "50%"
                            }
                          }
                        }
                      }
                    }
                  },

                  "& .accordion-footer": {
                    padding: "8px 16px",

                    "& .price": {
                      justifyContent: "space-between",
                      color: styles.brandPrimary.value,

                      "& .info-icon": {
                        marginLeft: 5
                      },

                      "& b": {
                        fontSize: 16
                      },

                      "& .value": {
                        fontSize: 20,
                        fontWeight: 500
                      }
                    }
                  }
                }
              }
            }
          }
        },

        "& .results-component-passes": {
          marginTop: 6,
          marginBottom: 30,

          "& .left-side": {
            "& > .MuiGrid-root": {
              backgroundColor: "#fff",
              borderRadius: 12
            },

            "& .headers-container": {
              height: 72,

              "& .header": {
                backgroundColor: "#fff",
                justifyContent: "center",
                color: styles.brandPrimary.value,

                "&.passe-header": {
                  backgroundColor: "#155dff",
                  color: "#fff",
                  justifyContent: "flex-start",
                  padding: "12px 16px",
                  borderTopLeftRadius: 12,

                  "& .train-icon": {
                    marginRight: 10
                  },

                  "& .title": {
                    fontSize: 16,
                    fontWeight: 500
                  },

                  "& .date": {
                    fontSize: 14,

                    "& .time-icon": {
                      marginRight: 5
                    }
                  }
                },

                "&.duration-header": {
                  backgroundColor: "#e1eaff"
                },

                "&:last-of-type": {
                  borderTopRightRadius: 12
                }
              }
            },

            "& .product-container": {
              border: "1px solid #aac0f1",

              "& .MuiGrid-item": {
                padding: "10px 16px"
              },

              "& .product-column": {
                "& .product-title-logo": {
                  display: "flex",
                  marginBottom: 10,

                  "& .product-title": {
                    fontSize: 17,
                    color: styles.brandPrimary.value,
                    fontWeight: 700,
                    lineHeight: "16px"
                  },

                  "& .logo": {
                    width: 80,
                    maxHeight: 25,
                    marginLeft: 10
                  }
                },

                "& .conditions": {
                  fontSize: 14,

                  "& p": {
                    margin: "5px 0"
                  },

                  "& ul": {
                    margin: 0
                  }
                },

                "& .conditions-button": {
                  marginTop: 10,
                  padding: 0,
                  fontWeight: 600
                }
              },

              "& .duration-column": {
                "& > div:not(:first-of-type)": {
                  marginTop: 15
                },

                "& .title": {
                  color: styles.brandPrimary.value,
                  marginBottom: 5,

                  "& .MuiSvgIcon-root": {
                    marginRight: 5
                  }
                },

                "& .MuiInputBase-root": {
                  borderRadius: 12
                }
              },

              "& .offer-button": {
                color: "#155dff",
                borderColor: "#155dff",
                padding: "2px 15px",
                fontSize: 14,

                "&.selected-offer": {
                  color: "#fff",
                  backgroundColor: "#155dff"
                }
              }
            }
          },

          "& .right-side": {
            "& > .MuiCard-root": {
              borderRadius: 12,

              "& > .MuiCardHeader-root": {
                backgroundColor: styles.brandPrimary.value,
                textAlign: "center",

                "& .MuiTypography-root": {
                  color: "#fff"
                },

                "& .price-currency": {
                  fontSize: 28
                },

                "& .price": {
                  fontSize: 32,
                  color: "#fff",
                  marginLeft: 4,
                  fontWeight: 600
                }
              },

              "& > .MuiCardContent-root": {
                minHeight: 221,
                padding: "7px 15px 15px",

                "& .no-tickets": {
                  marginTop: 24,
                  marginBottom: 16
                },

                "& .passengers": {
                  color: styles.brandPrimary.value,
                  textAlign: "center",
                  fontWeight: 600,
                  marginBottom: 7
                },

                "& .submit-button": {
                  fontSize: 18,
                  padding: "3px 16px"
                },

                "& .segment-resume": {
                  marginTop: 16,
                  boxShadow: "none",
                  border: "1px solid #e6e6e6",
                  borderRadius: 8,

                  "& .MuiCardHeader-root": {
                    padding: 16,
                    backgroundColor: "#f1f5ff",

                    "& .MuiCardHeader-avatar": {
                      color: "#155dff",
                      marginRight: 8
                    },

                    "& .destination": {
                      color: "#155dff",
                      fontSize: 18,
                      fontWeight: 500
                    }
                  },

                  "& .MuiCardContent-root": {
                    padding: "8px 16px",

                    "& .logo": {
                      width: 80,
                      marginBottom: 10
                    },

                    "& .MuiTypography-root": {
                      fontSize: 13,
                      margin: "2px 0"
                    },

                    "& .date b": {
                      textTransform: "capitalize"
                    },

                    "& .conditions-button": {
                      padding: 0,
                      marginTop: 10,
                      color: styles.brandPrimary.value,

                      "& .MuiSvgIcon-root": {
                        fontSize: 14
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "& .assists-results": {
        "& .results-component":{
          marginTop: 6,
          marginBottom: 30,

          "& .left-side": {
            "& .title":{
              fontWeight: 'bold',
              color: styles.headings.alternative.color,
            },
            "& .results-tabs": {
              "& .MuiTabScrollButton-root": {
                width: 30,

                "&  ~ .MuiTabs-scroller": {
                  "& .MuiTab-root": {
                    borderRadius: "0px !important"
                  }
                }
              },

              "& .MuiTabs-scroller": {
                padding: "0 1px",

                "& .MuiTab-root": {
                  textTransform: "none",
                  border: `1px solid #155dff`,

                  "&:not(.Mui-disabled)": {
                    color: "#155dff",
                  },

                  "&.Mui-selected": {
                    color: "#fff",
                    backgroundColor: "#155dff"
                  },

                  "&:first-of-type": {
                    borderTopLeftRadius: 12
                  },

                  "&:last-of-type": {
                    borderTopRightRadius: 12
                  },

                  "& > .MuiGrid-root": {
                    marginRight: "auto"
                  },

                  "& .train-icon": {
                    marginRight: 10
                  },

                  "& .destination": {
                    fontSize: 14,
                    fontWeight: 500,
                    flexWrap: "wrap",
                    "& .row": {
                      fontSize: 16,
                      flexWrap: "wrap",
                      textAlign: "start"
                    }
                  },
                  "& .date": {
                    textTransform: "capitalize",
                    marginLeft: 5,
                    fontSize: 14
                  },
                  "& .message-no-results": {
                    color: "#808080",
                    border: "1px solid #808080",
                    borderRadius: 4,
                    padding: 4
                  }
                }
              },

              "& .MuiTabs-indicator": {
                display: "none"
              }
            },
          },

          "& .right-side":{
            "& > .MuiCard-root": {
              borderRadius: 12,
              position: "sticky",
              top: 24,

              "& > .MuiCardHeader-root": {
                backgroundColor: styles.brandPrimary.value,
                textAlign: "center",

                "& .MuiTypography-root": {
                  color: "#fff"
                },

                "& .price-currency": {
                  fontSize: 28
                },

                "& .price": {
                  fontSize: 32,
                  color: "#fff",
                  marginLeft: 4,
                  fontWeight: 600
                }
              },

              "& > .MuiCardContent-root": {
                minHeight: 221,
                padding: "7px 15px 15px",

                "& .no-coverages": {
                  margin: "24px 0 16px 0"
                },

                "& .submit-button": {
                  fontSize: 18,
                  padding: "3px 16px"
                }
              }
            },
            "& .travel-details": {
              marginTop: 20,
              width: '100%',

              "& .travel-details__row":{
                display: "flex",
                marginBottom: 8,
              },
              "& .travel-details__item":{
                fontWeight: 500,
                width: "100%"
              },
              "& .travel-details__value":{
                fontWeight: 400,
              }
            },
            "& .segment-resume":{
              width: "100%",
              marginTop: 16,
            },
            "& .passengers": {
                color: styles.headings.alternative.color,
                fontWeight: 600,
                marginBottom: 7
            },
            "& .insurance-card-resume":{

              "& .insurance-card-resume__header":{
                background: styles.cardsAlt.header.background,
                color: styles.headings.alternative.color,
                padding: "12px 16px"
              },
              "& .insurance-card-resume__content":{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                padding: "8px 16px",
              },
              "& .insurance-card-resume__content-box" :{
                display: 'flex',
                alignItems: 'center'
              },
              "& .insurance-card-resume__plan-name": {
                fontWeight: 600,
                fontSize: "16px",
              },
              "& .insurance-card-resume__link": {
                color: styles.linkColor,
                fontSize: "14px",
                cursor: "pointer",
                textDecoration: "underline",
                mt: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              },
              "& .insurance-card-resume__footer":{
                color: styles.headings.alternative.color,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderTop: "1px solid #e0e0e0",
                padding: "8px 16px",
              },
              "& .insurance-card-resume__footer-price":{
                color: styles.headings.alternative.color,
                fontWeight: 'bold',
                fontSize: '1.125rem'
              }

            },

          }

        },

        "& .insurance-card": {
          width: '100%',
          position: "relative",
          marginBottom: "16px",
          borderRadius: "16px",
          overflow: "hidden",
          boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.12)",
          background: "#FFFFFF",
          height: "100%",
        },

        "& .insurance-card__header": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "12px",
          //height: "80px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          borderRadius: "16px 16px 0 0",
          backgroundColor: '#E1EAFF',

        },

        "& .insurance-card__name": {
          fontWeight: "bold",
          fontSize: "16px",
          color: "#000",
          margin: "0",
        },

        "& .insurance-card__name_icon":{
          display: 'flex',
              alignItems: 'center',
              mt: 3,
              marginTop: 24,

        },

        "& .insurance-card__badge": {
          width: "60px",
          height: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#FFFFFF",
          borderRadius: "50%",
          //boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
          marginRight: "6px",
        },

        "& .compare-checkbox": {
          position: "absolute",
          top: "10px",
          right: "12px",
          display: "flex",
          alignItems: "center",
          gap: "1px",
          zIndex: 1,
          // fontSize: "14px",
          color: styles.green,

          "& .MuiFormControlLabel-label": {
            color: styles.green,
            // fontWeight: "bold",
            margin: 0
          },
          "& .MuiCheckbox-root": {
            color: styles.green,
            "&.Mui-checked": {
              color: styles.green,
            },
          },
        },

       "& .insurance-card__button": {
          height: '40px',
          borderRadius: '4px',
          textAlign: 'center',
          lineHeight: '36px',
          cursor: 'pointer',
          fontSize: '1.025rem',
          margin: '6px auto 0',
          transition: 'background-color 0.3s, color 0.3s',
        },

        "& .insurance-card__promo-text": {
          display: "inline-block",
          backgroundColor: styles.green,
          color: "#FFFFFF",
          fontSize: "12px",
          fontWeight: "bold",
          padding: "4px 12px",
          borderRadius: "16px",
          textAlign: "center",
          margin: "8px auto",
          width: "fit-content",
        },

        "& .insurance-card__item":{
          textAlign: 'center'
        },

        "& .insurance-card__details":{
          justifyContent: 'center',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column'
        },

        "& .insurance-card__rate-promotion":{
          display: 'inline-flex',
          alignItems: 'center',
          backgroundColor: styles.green,
          color: '#fff',
          borderRadius: '16px',
          whiteSpace: 'nowrap',
          padding: '4px 16px'
        },

        "& .insurance-card__price": {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        },

        '& .insurance-card__price-title': {
          fontSize: '16px',
          color: styles.headings.alternative.color,
          fontWeight: 'bold',
        },

        "& .insurance-card__price-values": {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px',
        },

        "& .insurance-card__price-amount":{
          color: styles.headings.alternative.color,
          fontWeight: 'bold'
        },

        '& .insurance-card__price-old': {
          fontSize: '14px',
          color: '#FF0000',
          textDecoration: 'line-through',
          marginRight: 8,
        },

        '& .insurance-card__price-new': {
          fontSize: '24px',
          fontWeight: 'bold',
          color: styles.headings.alternative.color,
        },

        '& .insurance-card__price-per-person': {
          fontSize: '14px',
          color: styles.headings.alternative.color,
        },

        "& .insurance-card__actions":{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: 16
        },

        '& .insurance-card__people-count': {
          fontSize: '14px',
          color: styles.headings.alternative.color,
        },

        "& .insurance-card.selected": {
          border: '3px solid #2f4fff',
        },
      },
      "& .age-card-selection": {
        color: "#155dff",
        border: "1px solid #155dff",
        cursor: 'pointer',
        position: 'relative',
        padding: 10,

        "&.selected": {
          backgroundColor: "#155dff",
          color: "#fff"
        },

        "&.not-first": {
          borderLeft: "none",
        },

        "&:first-of-type": {
          borderTopLeftRadius: 8
        },

        "&:last-of-type": {
          borderTopRightRadius: 8
        }
      },
      "& .icon-check":{
        color: '#89CA5A',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        position: 'absolute',
        top: '8px',
        right: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      "& .paper-title-assits": {
        color: '#0052CC',
        marginBottom: 20,
        fontSize: 26,
        [theme.breakpoints.down("sm")]: {
          fontSize: 22
        }
      },
      "& .title-row-assists": {
        color: '#0052CC',
        fontWeight: 500,
        marginRight: '40px'
      },
      '& .item-resume': {
        padding: '0.5rem 0',
        borderBottom: '1px solid #eee',
        marginBottom: '0.5rem',
      },
      '& .item-resume.total-resume': {
        borderBottom: 'none',
        marginTop: '1rem',
        paddingTop: '1rem',
      },

      '& .container-item-resume': {
        display: 'flex',
        flexDirection: 'column',
      },

      '& .title-line': {
        display: 'block',
        fontSize: '1rem',
        fontWeight: 600,
        color: '#333',
        marginBottom: '2px',
      },

      '& .promo-line': {
        display: 'block',
        fontSize: '0.875rem',
        color: '#009900',
        marginTop: '2px',
      },

      '& .price-container': {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '8px',
        whiteSpace: 'nowrap',
      },

      '& .old-price': {
        fontSize: '1rem',
        fontWeight: 'normal',
        color: '#999',
        textDecoration: 'line-through',
      },

      '& .offer-price': {
        fontSize: '1rem',
        fontWeight: 600,
        color: '#0f0b0b',
      },

      '& .normal-price': {
        fontSize: '1rem',
        fontWeight: 600,
        color: '#333',
      },

      '& .comparisonBar': {
        position: 'relative',
        width: '100%',
        minHeight: '265px',
        backgroundColor: '#003DA5', // Azul de fondo
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '20px',
        gap: '20px',
        boxSizing: 'border-box'
      },

      '& .comparisonTitle': {
        fontSize: '1.2rem',
        fontWeight: 700,
        color: '#fff',
        marginRight: 'auto'
      },

      '& .comparisonActions': {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',

        // Podrías apuntar a button si lo deseas:
        '& button': {
          background: 'transparent',
          border: 'none',
          fontSize: '0.95rem',
          color: '#fff',
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline'
          }
        },
        '& .primary': {
          backgroundColor: '#fff',
          color: '#003DA5',
          borderRadius: '4px',
          padding: '6px 12px',
          fontWeight: 600
        }
      },

      '& .selectedCardsContainer': {
        display: 'flex',
        gap: '20px',
        flex: 1,
        overflowX: 'auto'
      },

      '& .coverageCard': {
        position: 'relative',
        width: '366px',
        height: '258px',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '16px',
        gap: '10px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 2px 6px rgba(0,0,0,0.08)',
        flexShrink: 0,

        '&:hover': {
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
        },
        '& .closeButton': {
          position: 'absolute',
          top: '8px',
          right: '8px',
          background: '#ffffffcc',
          border: 'none',
          borderRadius: '50%',
          cursor: 'pointer',
          padding: '4px',

          '&:hover': {
            background: '#ffffff'
          }
        },
        '& .coverageHeader': {
          display: 'flex',
          alignItems: 'center',
          gap: '8px',

          '& .coverageBadge': {
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            background: '#FF5B2E',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 700,
            color: '#fff'
          },
          '& h3': {
            margin: 0,
            fontSize: '1rem',
            fontWeight: 600,
            color: '#000'
          }
        },
        '& .priceInfo': {
          fontSize: '1.2rem',
          fontWeight: 700,
          color: '#003DA5'
        },
        '& .coverageDetails': {
          fontSize: '0.9rem',
          color: '#666',
          lineHeight: 1.2
        },
        '& .seeCoverageLink': {
          fontSize: '0.85rem',
          color: '#003DA5',
          cursor: 'pointer',
          textDecoration: 'underline',
          marginTop: 'auto'
        },
      },

      "& .scales-modal, & .conditions-modal": {
        "& .MuiDialogTitle-root": {
          fontWeight: 600
        },

        "& .segment-title": {
          color: "#155dff",
          fontSize: 14,
          fontWeight: 500,
          marginBottom: 5,

          "& .train": {
            marginRight: 3
          },

          "& .arrow": {
            margin: "0 3px"
          }
        },

        "& .date": {
          textTransform: "capitalize",
          color: styles.brandPrimary.value,
          marginBottom: 5,
          fontSize: 14,
          fontWeight: 500,

          "& .time": {
            marginRight: 3
          },

          "& .arrow": {
            margin: "0 3px"
          }
        },

        "& .duration": {
          color: styles.brandPrimary.value,
          fontSize: 14,
          fontWeight: 500,

          "& .scales": {
            marginLeft: 5,
            color: "#155dff",
            textDecoration: "underline"
          }
        },

        "& .scales-container": {
          marginTop: 15,
          borderRadius: 12,
          padding: 10,
          backgroundColor: "#f1f5ff",

          "& .train-logo": {
            marginLeft: 5,
            height: 20,
            maxWidth: "100px"
          }
        },

        "& .conditions": {
          marginTop: 10,

          "& p": {
            margin: "14px 0"
          },

          "& .condition": {
            alignItems: "flex-start !important",

            "&:not(:first-of-type)": {
              marginTop: 3
            },

            "& .MuiSvgIcon-root": {
              marginRight: 5,
              marginTop: 1
            },

            "& .luggage-condition p": {
              margin: 0,

              "&:not(:first-of-type)": {
                marginTop: 5
              }
            }
          }
        },

        "& .title": {
          fontSize: 28,
          fontWeight: 500,
          color: styles.brandPrimary.value
        }
      },
      "& .availability-trains-modal": {
        "& .MuiDialogTitle-root": {
          fontWeight: 600
        },

        "& .destination": {
          color: "#155dff",

          "& .train": {
            marginRight: 3
          },

          "& .arrow": {
            margin: "0 3px"
          }
        },

        "& .time": {
          marginTop: 5,
          textTransform: "capitalize",
          color: styles.brandPrimary.value,

          "& .time-icon": {
            marginRight: 3
          },

          "& .arrow": {
            margin: "0 3px"
          }
        }
      },
      "& .baggage-conditions-modal": {
        "& .carrier-container": {
          "&:not(:last-of-type)": {
            marginBottom: 10
          },

          "& .carrier-name": {
            color: styles.brandPrimary.value,
            fontSize: 18,
            fontWeight: 600
          },

          "& .message:not(:last-of-type)": {
            marginBottom: 5
          }
        }
      },
      "& .scales-container": {
        "& .scale-container": {
          minHeight: 100,

          "&:not(:last-of-type)": {
            marginBottom: 10
          },

          "& .scale-points": {
            marginRight: 8,
            height: "calc(100% - 15px)",
            justifyContent: "space-between",
            position: "relative",

            "& .train": {
              color: "#155dff"
            },

            "& .circle": {
              height: 6,
              width: 6,
              backgroundColor: "#155dff",
              borderRadius: "50%"
            },

            "& .line": {
              position: "absolute",
              top: 20,
              left: 8,
              width: 2,
              height: "calc(100% - 24px)",
              backgroundColor: "#155dff"
            }
          },

          "& .time-container": {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",

            "& .time": {
              textTransform: "uppercase",
              fontSize: 16,
              fontWeight: 600,
              color: styles.brandPrimary.value,
            }
          },

          "& .info-container": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",

            "& .place": {
              fontSize: 16,
              fontWeight: 500,
              color: styles.brandPrimary.value,
            },

            "& .amenity": {
              margin: "2px 0",

              "& .MuiIcon-root": {
                marginRight: 3
              }
            }
          }
        },

        "& .connection-container": {
          height: 40,
          borderLeft: "2px dotted #155dff",
          marginBottom: 10,
          marginLeft: 8,
          paddingLeft: 16.5,
          display: "flex",
          alignItems: "center",
          color: styles.brandPrimary.value,
          fontSize: 13
        },

        "& .amenities-container": {
          margin: "8px 0"
        }
      },
      "& .flight-scales-container": {
        "& .scale-container": {
          minHeight: 220,
          border: "1px solid #e9e9e9",
          borderRadius: 16,
          padding: 10,
          margin: 0,
          width: "100%",

          "& .airports-container": {
            "& .scale-points": {
              marginRight: 8,
              marginBottom: 5,
              height: "calc(100% - 30px)",
              justifyContent: "space-between",
              position: "relative",

              "& .circle": {
                height: 16,
                width: 16,
                backgroundColor: "#155dff",
                border: "1px solid #155dff",
                borderRadius: "50%",

                "&.empty": {
                  backgroundColor: "#fff",
                }
              },

              "& .line": {
                position: "absolute",
                top: 16,
                left: 7,
                width: 2,
                height: "calc(100% - 24px)",
                backgroundColor: "#155dff"
              }
            },

            "& .airports-info": {
              height: "100%",
              justifyContent: "space-between",
              alignItems: "flex-start",

              "& .airport-code, & .time": {
                color: styles.headings.color,
                fontSize: 18,
                fontWeight: "bold"
              },

              "& .date": {
                textTransform: "capitalize",
                color: styles.headings.color,
              },

              "& .airport": {
                fontSize: 14
              },

              "& .duration": {
                margin: "10px 0"
              }
            }
          },

          "& .airline-logo": {
            maxWidth: 90,
            height: "auto",
            marginRight: 10
          }
        },

        "& .connection-container": {
          height: 40,
          border: "1px solid #e9e9e9",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 16,
          margin: "10px 0"
        },
      },
      "& .mobile-menu-list": {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      },
      "& .red": {
        color: '#f64c4c'
      },
      "& .green": {
        color: styles.green
      },
      "& .money-icon": {
        width: '0.8em',
        height: '0.8em'
      },
      "& .dots-container": {
        fontSize: 32,
        lineHeight: "12.5px !important",
        color: styles.brandPrimary.value,

        "& .dots": {
          display: "inline-flex",
          animation: "jump 1s infinite",
          animationDelay: "calc(0.2s * var(--i))"
        },

        "@keyframes jump": {
          "0%, 50%, 100%": {
            transform: "translateY(0)"
          },

          "25%": {
            transform: "translateY(-5px)"
          },
        }
      },
      "& .image-counter": {
        position: "absolute",
        bottom: 0,
        left: 0,
        background: "rgba(0,0,0,0.8)",
        color: "#fff",
        padding: "8px 12px",
        fontSize: 12,
        zIndex: 10,
        borderRadius: "0 8px 0 16px"
      },
      "& .dialog-message": {
        "& .MuiDialogTitle-root": {
          fontSize: '1.2rem'
        }
      },
      "& .rowmarkup": {
        display: 'inline-block'
      },
      "& .change-price-modal": {
        "& .MuiDialog-paper": {
          overflow: "initial"
        },

        "& .error-icon": {
          top: -20,
          left: "calc(50% - 15px)",
          backgroundColor: "#fff",
          borderRadius: "50%",
          height: 35,
          minHeight: 35,
          width: 35,
          position: "absolute",
          color: "#ff8f00"
        },

        "& .MuiDialogTitle-root": {
          fontSize: 32,
          textAlign: "center"
        },

        "& .MuiDialogContent-root": {
          fontSize: 18
        }
      },
      "& .gotrenes": {
        "& .contact-column": {
          "& .MuiGrid-grid-sm-6": {
            flexBasis: '100%',
            maxWidth: '100%'
          }
        }
      },
      "& .info-page-trenes": {
        "& .card-info": {
          borderRadius: 12,
          height: '100%',
          paddingBottom: 44,
          position: 'relative',

          "&.document": {
            paddingBottom: 0,
          },

          "& .caption": {
            position: 'absolute',
            top: 10,
            left: 10,
          },

          "& .title": {
            fontSize: 18,
            marginBottom: 12,
            maxWidth: '100%',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },

          "& .description": {
            //marginBottom: 12,
            fontSize: 12,
            maxWidth: '100%',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            //WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },

          "& .MuiCardContent-root": {
            padding: 12
          }
        },
        "& .tabs-landing-info": {
          flexGrow: 1,
          width: '100%',
          marginBottom: 30,
          borderRadius: '0 0 16px 16px',

          "& .MuiTabs-root": {
            borderBottom: '2px solid',
            borderColor: styles.brandPrimary.value,
          },

          "& .MuiTab-root": {
            backgroundColor: 'white',
            color: styles.brandPrimary.value,
            minWidth: 230,
            borderRadius: '8px 8px 0 0',
            marginRight: 8,
            flexDirection: 'row',
            fontSize: '16px',

            "& .MuiIcon-root": {
              marginRight: 8
            },
          },

          "& .Mui-selected": {
            backgroundColor: styles.brandPrimary.value,
            color: 'white'
          },

          "& .MuiTabs-indicator": {
            backgroundColor: '#06c'
          },

          "& .MuiButton-root": {
            fontSize: 14
          }
        },
        "& .card-media": {
          height: 180
        },
        "& .card-footer": {
            position: 'absolute',
            bottom: 0,
            zIndex: 1,
            "& .MuiButton-root": {
              textTransform: 'uppercase',
              fontSize: 14
            }
        },
        "& .carousel-container": {
          marginBottom: 20
        },
        "& .slider-infopage": {
          backgroundColor: '#666666',
          minHeight: 300,
          height: '40vh',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          "& .title": {
              color: 'white',
              lineHeight: '42px',
              fontSize: '40px',
              marginTop: 0,
              marginBottom: 0
          },
          "& .subtitle": {
              color: 'white'
          },
          "& .description": {
              color: 'white'
          },
          "& .category": {
              color: 'white',
              textTransform: 'uppercase',
              fontSize: 36,
              marginBottom: 0,
              marginTop: 0
          },
          "& .button": {
              marginRight: 16
          },
          "& .button-white": {
              color: 'white',
              borderColor: 'white',
              "&:hover": {
                  backgroundColor: 'rgba(255,255,255,0.15)'
              }
          },
        },
        "& .dialog-video": {
          "& .dialogContent_root": {
              position: 'relative',
          },
          "& .caption": {
              position: 'absolute',
              top: 30,
              left: 30,
              zIndex: 1
          }
        },
      },
      "& .site-ttoperadora": {
        ".search-box-container .search-box .submit-button": {
          fontWeight: 'bold'
        },
        "& .row-images": {
          "& a": {
            display: 'inline-block'
          }
        },
        "& .redes *": {
          color: "white"
        },
        "& .asociaciones a": {
          display: "inline-block",
          marginRight: 20,
          marginBottom: 10
        },
        "& .MuiButton-outlined": {
          borderWidth: borderWidth
        },
        "& .purchase-card":{
          "& .MuiGrid-item:has(> .sub-total)": {
            display:'none'
          },

          "& .comission": {
            color: styles.red
          }
        }
      },
      "& .site-gotravelres": {
        "& .redes": {
          "& a": { marginRight: 10},
          "& .MuiSvgIcon-root": { width: 34, height: 34 }
        },
        "& .flags": {
          "& a": { marginRight: 10 },
          "& img": {
            width: 30,
            borderRadius: 5,

            "&:not(:first-of-type)": {
              marginLeft: 10
            }
          },
        },
        "& .legales": {
          "& p": {marginBottom: 4}
        },
      },
      "& .landing-container": {
        margin: "15px auto",

        "& .content": {
          padding: "20px 35px",
          backgroundColor: "#fff",

          "& .MuiContainer-root": {
            padding: 0
          },

          "& .sector-title": {
            fontSize: 30,
            margin: "15px 0"
          },

          "& .rowmarkup": {
            width: "100%",

            "& a": {
              display: "contents"
            }
          }
        }
      },
      "& .row-submenu":{
        "& .MuiSvgIcon-root":{marginRight: 4}
      },
      "& .site-gotrenes":{
        paddingBottom: 80,

        "& .scroll-to-top-button": {
          bottom:20
        },

        "& .redes": {
          "& a": {marginRight: 10},
          "& .MuiSvgIcon-root": {width: 34, height:34}
        },
        "& .footer ": {
          "minHeight":250,
          "& .title":{ fontSize: 20, marginBottom: 10  },
          "& .contacto":{
            textAlign:"right",
            "& *":{ justifyContent: 'end' },
            "& .title":{ fontSize: 24, marginBottom: 10  },
            "& .subtitle":{ fontSize: 20   }
          },
        }
      },
      "& .modal-paypal": {
        zIndex: 100,

        "& .MuiDialog-paper": {
          borderRadius: 12
        },

        "& .MuiDialogTitle-root": {
          padding: 0,

          "& .MuiPaper-root": {
            borderRadius: "4px 4px 0 0"
          },

          "& .MuiCardHeader-root": {
            backgroundColor: styles.brandPrimary.value,
            color: "#fff",
            [theme.breakpoints.down("sm")]: {
              padding: "8px !important"
            },

            "& .title-card-header": {
              fontSize: 20,
              [theme.breakpoints.down("sm")]: {
                fontSize: 18
              }
            },

            "& .MuiCardHeader-action": {
              margin: "auto -3px",

              "& .MuiIconButton-root": {
                color: "#fff"
              }
            },
          },
        },

        "& .subtitle": {
          display: "flex",
          alignItems: "center",

          "& .MuiTypography-root": {
            fontSize: 16,
            fontWeight: 500,
            color: styles.brandPrimary.value
          }
        },

        "& .paypal-button": {
          marginTop: 10,
          height: 55,
          backgroundColor: "#2c2e2f",
          fontSize: 16,
          color: "#fff",

          "& .MuiSvgIcon-root": {
            marginRight: 10
          }
        },

        "& .spinner-container": {
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",

          "& .MuiCircularProgress-root": {
            color: styles.brandPrimary.value
          }
        },

        "& .paypal-field": {
          padding: 12,
          width: "100%",
          border: "1px solid #ccc",
          borderRadius: 4,
          boxSizing: "borderbox",
          margin: "8px 2px",
          resize: "vertical",
          height: 40,
          background: "#fff",
          fontSize: 16,
          color: "#3a3a3a",
          fontFamily: "monospace",
          outline: "none"
        },

        "& .pay-button": {
          marginTop: 10,
          width: 165,
          height: 40,

          "& .MuiSvgIcon-root": {
            marginLeft: 10
          }
        }
      },
      "& .flights-results": {
        "& .results-container": {
          padding: "16px 0",

          "& > .title": {
            fontSize: 24,
            fontWeight: 500,
            marginBottom: 16,
            color: styles.headings.color,

            "@media(max-width: 425px)": {
              fontSize: 18
            },

            "@media(min-width: 426px) and (max-width: 768px)": {
              fontSize: 20
            }
          },

          "& .three-days-container": {
            backgroundColor: "#fff",
            borderRadius: 16,
            marginBottom: 20,
            boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",

            "& .three-days-content": {
              padding: "12px 20px 12px 28px",

              "& .title": {
                color: styles.brandPrimary.value,
                fontSize: 20,
                fontWeight: 500
              },

              "& .left-side": {
                display: "flex",
                justifyContent: "flex-end",

                "& .MuiButton-root": {
                  padding: "2px 15px",

                  "& .MuiSvgIcon-root": {
                    marginRight: 5
                  }
                }
              }
            }
          },

          "& .rates-airlines-accordion": {
            borderRadius: 16,
            marginBottom: 20,

            "&::before": {
              display: "none"
            },

            "& .MuiAccordionSummary-root": {
              minHeight: 0,

              "& .MuiAccordionSummary-content": {
                justifyContent: "space-between",
                margin: 12,
                alignItems: "center",

                "& .title": {
                  color: styles.brandPrimary.value,
                  fontSize: 20,
                  fontWeight: 500
                }
              }
            },

            "& .MuiAccordionDetails-root": {
              padding: 0,

              "& .MuiTable-root": {
                color: styles.brandPrimary.value,
                borderTop: "1px solid #e0e0e0",
                tableLayout: "fixed",

                "& .MuiTableCell-root": {
                  borderRight: "1px solid #e0e0e0",
                  fontWeight: 500,
                  fontSize: 15,
                  textAlign: "center",
                  padding: "2px 10px",
                  position: "relative",

                  "&.clickable": {
                    cursor: "pointer"
                  },

                  "&.selected": {
                    backgroundColor: `${styles.brandPrimary.value}33`,
                    border: `2px solid ${styles.brandPrimary.value}`,
                  }
                },

                "& .MuiTableRow-root": {
                  height: 40,

                  "&:last-of-type .MuiTableCell-root": {
                    borderBottom: "none"
                  },

                  "& .MuiTableCell-root": {
                    "&:first-of-type": {
                      fontWeight: 600
                    },

                    "&:last-of-type": {
                      borderRight: "none"
                    }
                  }
                },

                "& .airline-logo": {
                  maxWidth: 80,
                  maxHeight: 28
                },

                "& .arrow-button": {
                  position: "absolute",
                  boxShadow: "0px 0px 1px 0px black",
                  backgroundColor: "#ffffffd9",
                  color: styles.brandPrimary.value,
                  border: `1px solid ${styles.brandPrimary.value}`,
                  minHeight: 0,
                  height: 28,
                  width: 28,
                  top: -14,
                  right: -15,

                  "&.Mui-disabled": {
                    opacity: 0.3
                  }
                }
              }
            }
          },

          "& .results-component": {
            "& .flight-card": {
              boxShadow: "0px 5px 10px 0px lightgray",
              marginBottom: 30,
              borderRadius: 16,

              "&:last-of-type": {
                marginBottom: 0
              },

              "&.skeleton": {
                height: 245,

                "& .MuiSkeleton-root": {
                  transform: "unset"
                },

                "& .info-container": {
                  justifyContent: "space-between",
                  flex: 1,

                  "& .MuiSkeleton-root": {
                    margin: "10px 0"
                  }
                }
              },

              "&.recommended": {
                "& .flight-content": {
                  borderRadius: "0 0 16px 16px",

                  "& .leg-title-container": {
                    borderTopLeftRadius: "0px !important"
                  }
                }
              },

              "&:not(.recommended):not(.skeleton)": {
                borderTop: `2px solid ${styles.accentColor}`
              },

              "& .recommended-container": {
                height: 40,
                backgroundColor: styles.recommended.background,
                color: styles.recommended.color,
                borderRadius: "16px 16px 0 0",
                fontSize: 14,

                "& .MuiSvgIcon-root": {
                  marginRight: 5,
                  marginLeft: 5,
                  transform: "rotate(20deg)"
                }
              },

              "& .flight-content": {
                borderRadius: 16,
                backgroundColor: "#fff",

                "& > .left": {
                  "& .leg-container": {
                    "&:first-of-type": {
                      "& .leg-title-container": {
                        borderTopLeftRadius: 16
                      }
                    },

                    "& .leg-title-container": {
                      padding: 16,
                      background: "#e1eaff",
                      borderBottomRightRadius: 16,
                      justifyContent: "space-between",
                      color: styles.brandPrimary.value,
                      minHeight: 63,

                      "& .left": {
                        "& > *:not(:last-child)": {
                          marginRight: 15
                        },

                        "& .trip-type-container": {
                          backgroundColor: styles.accentColor,
                          color: "#fff",
                          padding: "4px 8px",
                          borderRadius: 16,

                          "& .flight-icon": {
                            marginRight: 5,
                            transform: "rotate(90deg)",

                            "&.return": {
                              transform: "rotate(270deg)"
                            }
                          }
                        },

                        "& .title": {
                          fontSize: 17
                        },

                        "& .date": {
                          textTransform: "capitalize",

                          "& .MuiSvgIcon-root": {
                            marginRight: 5
                          }
                        }
                      },

                      "& .cabin-class": {
                        "& .MuiSvgIcon-root": {
                          marginRight: 5
                        }
                      }
                    },

                    "& .option-accordion": {
                      margin: 0,
                      boxShadow: "none",

                      "&::before": {
                        display: "none"
                      },

                      "& .MuiAccordionSummary-root": {
                        padding: "0 15px",

                        "& .MuiAccordionSummary-content": {
                          margin: "6px 0"
                        },

                        "& .option-container": {
                          height: 66,
                          width: "100%",

                          "& .MuiFormControlLabel-label": {
                            width: "100%",

                            "& .airline-logo": {
                              width: "100%",
                              height: "auto"
                            },

                            "& .multiple-logo": {
                              width: 50,
                              height: "auto",
                              color: styles.brandPrimary.value
                            },

                            "& .title": {
                              fontWeight: 'bold',
                              fontSize: 18
                            },

                            "& .time": {
                              fontSize: 16
                            },

                            "& .scales-text": {
                              fontWeight: 500,

                              "&.direct": {
                                color: styles.accentColor
                              }
                            },

                            "& .scale-line": {
                              height: 2,
                              width: 90,
                              backgroundColor: styles.accentColor,
                              margin: "4px 0",
                              position: "relative",

                              "& .circles-container": {
                                width: "100%",
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                                justifyContent: "center",

                                "& .circle": {
                                  border: "1px solid #a01127",
                                  backgroundColor: "#fff",
                                  borderRadius: "50%",
                                  height: 10,
                                  width: 10,

                                  "&:not(:last-of-type)": {
                                    marginRight: 5
                                  }
                                }
                              }
                            },

                            "& .plus-days": {
                              marginLeft: 5
                            },

                            "& .MuiTypography-root": {
                              color: styles.brandPrimary.value
                            }
                          }
                        }
                      },

                      "& .MuiAccordionDetails-root": {
                        padding: 0,

                        "& .flight-scales-container, & .baggage-container": {
                          padding: 16
                        }
                      }
                    }
                  }
                },

                "& > .right": {
                  padding: "0 16px",

                  "& .top": {
                    height: 63,
                    justifyContent: "space-between",

                    "& .provider-logo": {
                      maxHeight: 20,
                      maxWidth: 90
                    },

                    "& .compare-checkbox": {
                      color: styles.compareCheckbox.value,
                      margin: 0,

                      "& .MuiCheckbox-root": {
                        paddingRight: 5,

                        "&:not(.Mui-disabled)": {
                          color: styles.compareCheckbox.value,
                        }
                      },

                      "& .MuiTypography-root": {
                        fontSize: 14,
                        fontWeight: 'bold'
                      }
                    }
                  },

                  "& .bottom": {
                    marginBottom: 20,

                    "& .fare-type-container": {
                      color: styles.brandSecondary.value,
                      border: `1px solid ${styles.brandSecondary.value}`,
                      padding: "4px 6px",
                      borderRadius: 16,
                      marginBottom: 10,
                      fontSize: 14
                    },

                    "& .total-price-text": {
                      fontSize: 14
                    },

                    "& .amount": {
                      fontSize: 28,
                      fontWeight: "bold",
                      color: styles.brandPrimary.value,
                      lineHeight: 1,
                      margin: "5px 0"
                    },

                    "& .amount-passenger": {
                      fontWeight: "500",
                      color: styles.brandPrimary.value
                    },

                    "& .reserve-button": {
                      marginTop: 10,
                      fontSize: 16
                    },

                    "& .detail-accordion": {
                      margin: 0,
                      marginTop: 5,
                      boxShadow: "none",
                      width: "100%",
                      maxWidth: 270,

                      "&::before": {
                        display: "none"
                      },

                      "& .MuiAccordionSummary-root": {
                        minHeight: 0,
                        justifyContent: "center",

                        "& .MuiAccordionSummary-content": {
                          margin: 0,
                          flex: 0,
                          textWrap: "nowrap"
                        },

                        "& > *": {
                          color: styles.brandPrimary.value
                        }
                      },

                      "& .MuiAccordionDetails-root": {
                        color: styles.brandPrimary.value,
                        paddingBottom: 0,

                        "& .row": {
                          justifyContent: "space-between"
                        }
                      }
                    }
                  }
                }
              },

              "& .plus-days": {
                color: "#ff232b"
              },

              "& .more-rates-container": {
                padding: "0 20px",
                marginBottom: 10,

                "& .baggage-image": {
                  color: styles.brandPrimary.value
                },

                "& .text": {
                  color: styles.brandPrimary.value,
                  fontSize: 16,
                  fontWeight: 500,
                  marginLeft: 10
                }
              }
            },
          },

          "& .baggage-container-icons": {
            "& svg": {
              color: styles.green,

              "&:not(:last-of-type)": {
                marginRight: 10
              },

              "&.not-included": {
                color: "#bdbdbd"
              }
            }
          },

          "& .filters-component": {
            "& .airports-container:not(:last-of-type)": {
              marginBottom: 10
            },

            "& .schedule .MuiTabs-root": {
              marginBottom: 10,

              "& .MuiTabScrollButton-root": {
                width: 20
              }
            }
          }
        },

        "& .comparator-container": {
          width: "100%",
          backgroundColor: `${styles.brandPrimary.value}CC`,
          position: "fixed",
          bottom: 0,
          left: 0,
          zIndex: 1100,
          color: "#fff",
          padding: "10px 0 15px",

          "& .MuiContainer-root": {
            height: "100%",

            "& .title": {
              fontSize: 20
            },

            "& .buttons-container": {
              "& .MuiButton-root": {
                color: "#fff",
                borderColor: "#fff",

                "&:last-of-type": {
                  marginLeft: 10
                }
              }
            },

            "& .flight-info": {
              padding: 15,
              backgroundColor: "#fff",
              borderRadius: 16,
              maxHeight: 155,
              position: "relative",

              "& .left-side": {
                justifyContent: "space-between",
                alignItems: "flex-start",

                "& .airline-logo": {
                  maxHeight: 25,
                  width: "auto",
                  marginBottom: 5
                },

                "& .section": {
                  color: "#606060",

                  "& .MuiTypography-root": {
                    fontSize: 12,

                    "&:not(:last-of-type)": {
                      marginRight: 5
                    }
                  }
                }
              },

              "& .right-side": {
                justifyContent: "space-between",
                alignItems: "flex-end",

                "& .baggage-container": {
                  "& svg": {
                    color: styles.green,

                    "&.not-included": {
                      color: "#bdbdbd"
                    },

                    "&:not(:last-of-type)": {
                      marginRight: 10
                    }
                  }
                },

                "& .price-container": {
                  alignItems: "flex-end",

                  "& .MuiTypography-root:first-of-type": {
                    fontSize: 12,
                    color: "#606060"
                  },

                  "& .price": {
                    fontSize: 18,
                    color: styles.brandPrimary.value,
                    fontWeight: 600
                  }
                }
              },

              "& .close-button": {
                position: "absolute",
                top: -10,
                right: -10,
                backgroundColor: "#ffffffd9",
                boxShadow: "0px 0px 1px 0px black"
              }
            }
          }
        },

        "& .comparation-component": {

          "& .title-container": {
            marginBottom: 20,
            justifyContent: "space-between",

            "& .buttons-container": {
              "& .button": {
                marginLeft: 10
              }
            }
          },

          "& .leg-container": {
            "&:first-of-type .flight-info-container": {
              borderRadius: "16px 16px 0px 0px",
            },

            "&:not(:first-of-type) .flight-info-container": {
              borderTop: `2px solid ${styles?.accentColor}1a`
            },

            "& .flight-info-container": {
              backgroundColor: styles?.cardsAlt?.header?.background,

              "& .destination-container": {
                minHeight: 40,
                padding: "5px 10px 0",

                "& .trip-type-container": {
                  backgroundColor: styles.accentColor,
                  color: "#fff",
                  padding: "4px 8px",
                  borderRadius: 16,
                  marginRight: 10,
                  fontSize: 14,

                  "& .flight-icon": {
                    marginRight: 5,
                    transform: "rotate(90deg)",

                    "&.return": {
                      transform: "rotate(270deg)"
                    }
                  }
                },

                "& .leg-title": {
                  color: styles.brandPrimary.value,
                  fontSize: 16,
                  fontWeight: 600
                },

                "& .data-container": {
                  marginTop: 0
                }
              }
            },

            "& .data-container": {
              minHeight: 40,
              display: "flex",
              alignItems: "center",
              padding: "0 10px",
              marginTop: 2,
              backgroundColor: `${styles?.cardsAlt?.header?.background}66`,
              width: '100%',

              "&.info-title": {
                color: styles.brandPrimary.value
              }
            },

            "& .option-column": {
              alignItems: "normal",

              "& .flight-info-container": {
                flex: 1,
                justifyContent: "flex-end"
              },

              "& .data-container": {
                justifyContent: "center",

                "&.airports": {
                  color: styles.brandPrimary.value,
                  fontSize: 17,
                  lineHeight: "20px",
                  fontWeight: 500,
                },

                "&.criterion": {
                  backgroundColor: "#fff"
                },

                "&.logo-container": {
                  padding: 6,

                  "& .airline-logo": {
                    maxHeight: 26,
                    maxWidth: "100%"
                  }
                }
              },

              "& .tooltip-baggage": {
                height: 26,

                "& .baggage-icon": {
                  color: styles.green,

                  "&:not(:last-of-type)": {
                    marginRight: 10
                  },

                  "&.not-included": {
                    color: "#bdbdbd"
                  }
                }
              }
            },

            "& .price-container": {
              paddingTop: 2,

              "& .data-container": {
                padding: 15,
                backgroundColor: "#fff",

                "& .price": {
                  fontSize: 24,
                  fontWeight: "bold",
                  marginBottom: 10,
                  color: styles.brandPrimary.value
                },

                "& .reserve-button": {
                  fontSize: 18,
                  fontWeight: 700,
                  padding: "0 10px"
                },

                "& .not-available-message": {
                  textAlign: "center",
                  color: "#ff0000",
                  marginTop: 10,
                  fontWeight: 500
                }
              }
            }
          }
        }
      },
      "& .baggage-container": {
        color: "#606060",
        padding: "5px 0 3px 0",

        "& .MuiGrid-container": {
          alignItems: "flex-start !important",

          "& .icon-container": {
            textAlign: "center",

            "& svg": {
              color: styles.green,

              "&.not-included": {
                color: "#bdbdbd"
              }
            }
          },

          "& .baddage-description": {
            alignItems: "flex-start"
          }
        }
      },
      "& .flights-modal": {
        "& .MuiDialog-paper": {
          "& .MuiDialogTitle-root": {
            color: styles.brandPrimary.value,

            "& .baggage-image": {
              width: 117,
              height: "auto"
            },

            "& .text": {
              fontSize: 22,
              fontWeight: 600,
              marginLeft: 10
            }
          },

          "& .MuiDialogContent-root": {
            padding: "0 12px 20px",

            "& .react-multi-carousel-list": {
              marginBottom: 5
            },

            "& .MuiCard-root": {
              margin: "0 10px 1px",
              paddingBottom: 1,
              borderRadius: 16,
              height: "calc(100% - 1px)",
              display: "flex",
              flexDirection: "column",

              "& .MuiSvgIcon-root": {
                marginRight: 5
              },

              "& .top": {
                backgroundColor: styles.brandPrimary.value,
                color: "#fff",
                padding: 10
              },
              "& .fare-title": {
                fontSize:20,
                fontWeight: 500
              },

              "& .middle": {
                padding: 20,
                flex: 1,

                "& .baggage-container-icons": {
                  marginBottom: 10,

                  "& svg": {
                    color: styles.green,

                    "&:not(:last-of-type)": {
                      marginRight: 10
                    },

                    "&.not-included": {
                      color: "#bdbdbd"
                    },

                    "&.small": {
                      width: 18,
                      height: "auto"
                    },

                    "&.medium": {
                      width: 19,
                      height: "auto"
                    },

                    "&.large": {
                      width: 23,
                      height: "auto"
                    }
                  }
                },

                "& .row": {
                  width: "100%",

                  "&:not(:last-of-type)": {
                    marginBottom: 10
                  },

                  "& .MuiSvgIcon-root": {
                    color: "#bdbdbd",

                    "&.included": {
                      color: styles.brandPrimary.value
                    }
                  }
                }
              },

              "& .bottom": {
                padding: 20,
                backgroundColor: "#fbfbfb",
                color: styles.brandPrimary.value,

                "& .MuiTypography-root": {
                  marginBottom: 5,

                  "& b": {
                    fontSize: 18
                  }
                },

                "& .MuiButton-root": {
                  marginTop: 10
                }
              }
            }
          }
        }
      },
      "& .three-days-modal": {
        "& .MuiDialog-paper": {
          "& .MuiDialogTitle-root": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            "& .title": {
              color: styles.brandPrimary.value,
              fontSize: 20,
              fontWeight: 500
            },

            "& .message": {
              fontSize: 14
            }
          },

          "& .MuiTable-root": {
            color: styles.brandPrimary.value,
            borderTop: "1px solid #e0e0e0",
            tableLayout: "fixed",

            "& .first-cell": {
            position: "relative",
            backgroundImage: "linear-gradient(to top right,#fff 49%, #e0e0e0 ,#fff 51%)",

            "& span": {
              position: "absolute",

              "&:first-of-type": {
                top: 2,
                right: 10
              },

              "&:last-of-type": {
                bottom: 2,
                left: 10
              }
            }
            },

            "& .MuiTableCell-root": {
              border: "1px solid #e0e0e0",
              fontWeight: 500,
              padding: 6,
              position: "relative",

              "& .info-icon, & .refresh-icon": {
                position: "absolute",
                bottom: 0,
                right: 0,
                color: "#0000008a"
              },

              "& .info-icon": {
                margin: 5
              },

              "& > .MuiGrid-root": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",

                "& .price-container": {
                  color: "#ff9645",
                  fontSize: 16,
                  fontWeight: 600,

                  "&.real-time-price": {
                    color: "#4b9516"
                  }
                }
              }
            },

            "& .MuiTableRow-root:first-of-type .MuiTableCell-root, & .MuiTableRow-root .MuiTableCell-root:first-of-type": {
              textTransform: "capitalize",
              fontSize: 14
            },

            "& .MuiTableRow-root:first-of-type": {
              height: 48
            },

            "& .MuiTableRow-root:not(:first-of-type) .MuiTableCell-root:not(:first-of-type)": {
              fontWeight: 500,
              fontSize: 14,
              height: 74,

              "&:not(:has(> .loading-logo)):not(:has(> .refresh-icon-large))": {
                textAlign: "start",
                verticalAlign: "top",
              },

              "& .loading-logo": {
                maxWidth: 50
              }
            },

            "& .selected-date": {
              backgroundColor: styles.brandPrimary.value,
              borderBottomColor: styles.brandPrimary.value,
              borderRightColor: styles.brandPrimary.value,
              color: "#fff"
            },

            "& .selected-price": {
              backgroundColor: `${styles.brandPrimary.value}33`
            },

            "& .border-bottom-price": {
              borderBottomColor: styles.brandPrimary.value
            },

            "& .border-right-price": {
              borderRightColor: styles.brandPrimary.value
            },

            "& .date": {
              fontWeight: 600
            },

            "& .price-cell": {
              cursor: "pointer"
            },

            "& .flights-data-container": {
              paddingRight: 21,
              marginTop: 5,

              "& .flight-data": {
                marginTop: 3,
                height: 15,

                "&:first-of-type": {
                  marginTop: 6
                },

                "& .airline-logo-container": {
                  marginRight: 5,
                  width: 50,
                  display: "flex",
                  justifyContent: "center",

                  "& .airline-logo": {
                    maxWidth: 50,
                    maxHeight: 15
                  },
                },

                "& .stopovers": {
                  fontSize: 12
                }
              }
            }
          },

          "& .legend-container": {
            marginTop: 5,

            "& > .row": {
              fontSize: 14,
              fontWeight: 500,

              "&:first-of-type": {
                marginLeft: "auto",
                marginRight: 15
              },

              "& .circle": {
                width: 10,
                height: 10,
                marginRight: 5,
                borderRadius: "50%",

                "&.green": {
                  backgroundColor: "#4b9516"
                },

                "&.yellow": {
                  backgroundColor: "#ff9645"
                }
              }
            }
          }
        }
      },
      "& .flight-data-tooltip": {
        "&:not(:first-of-type)": {
          marginTop: 10
        },

        "& .date": {
          fontSize: 15,
          fontWeight: 500,
          textTransform: "capitalize"
        },

        "& .airports-container": {
          justifyContent: "space-between",
          margin: "2px 0",

          "& .airport": {
            fontSize: 15,
            fontWeight: 500
          },

          "& .stopovers": {
            margin: "0 20px"
          }
        },

        "& .duration-container": {
          justifyContent: "space-between",

          "& .duration": {
            color: "#606060"
          },

          "& .airline-logo": {
            maxWidth: 60,
            height: "auto"
          }
        }
      },
      "& .se-container": {
        "& .se-image-container, & .se-image-container img": {
          margin: "0px !important",
          padding: "0px !important",
          outline: "none !important",

          "&.__se__float-none figure": {
            margin: 'auto !important'
          }
        }
      },
      '& .upload_file_passenger-table':{
        '& .MuiTableCell-root' : {
          fontSize: 11,
        },
        '& .header-container': {
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '10px',
          float: 'right',
        },

        /* Estilos del botón */
        '& .custom-upload-button': {
          display: 'inline-flex',
          alignItems: 'center',
          gap: '8px',
          backgroundColor: '#fff',
          color: '#267FFF',
          border: '1px solid #267FFF',
          borderRadius: '8px',
          padding: '8px 16px',
          fontSize: '14px',
          fontWeight: 500,
          cursor: 'pointer',
          transition: 'all 0.2s ease-in-out',
        },

        /* Efecto hover: invertimos colores */
        '& .custom-upload-button:hover': {
          backgroundColor: '#267FFF',
          color: '#fff',
        },

        /* Tamaño del ícono de MUI */
        '& .upload-button-icon': {
          fontSize: '20px',
        },

      },
      "& .sun-editor-editable": {
        fontFamily: "Montserrat",
        fontSize: 16
      }
    }
  }
};

export default createThemeFromData;
