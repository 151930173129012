import React, { useEffect, useState } from 'react';
import { Container, Grid, Hidden, Typography } from '@mui/material';
import { Favorite } from '@mui/icons-material';
import FiltersComponent from '../../modules/Gimmonix/FiltersComponent';
import FavoritesComponent from './Favorites/FavoritesComponent';
import HotelModal from '../../modules/Gimmonix/HotelModal';
import { connect } from 'react-redux';
import { deleteFavoriteActionGimmonix, getFavoritesActionGimmonix, resetFavoritesActionGimmonix } from '../../../store/actions';
import { useTranslation } from 'react-i18next';
import Multibuscador from '../../common/Multibuscador/Multibuscador';

function FavoritesView({ resultFavorites, errorFavorites, selectedReservation, ...props }) {
  const { t } = useTranslation();
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  const [filteredResults, setFilteredResults] = useState([]);
  const [activeSort, setActiveSort] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState();
  const [defaultView, setDefaultView] = useState('map');
  const [filters, setFilters] = useState({});
  const [defaultDataForm, setDefaultDataForm] = useState();

  useEffect(() => {
    let user = localStorage.getItem('user');
    if (user) {
      user = JSON.parse(user);
      if (user.email) {
        props.getFavorites(user.email);
        setLoading(true);
      }
    }

    return () => {
      props.resetFavorites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading && Object.keys(resultFavorites).length > 0) {
      setLoading(false);
      setResults(resultFavorites?.hotels || []);
      setFilteredResults(resultFavorites?.hotels || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultFavorites]);

  useEffect(() => {
    if (loading && errorFavorites) {
      setLoading(false);
      setResults([]);
      setFilteredResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFavorites]);

  useEffect(() => {
    if (results) {
      const stars = Array.from(Array(5).keys()).map(star => {
        const transformedStar = (star + 1).toString();
        return ({
          value: `${transformedStar} ${t('results.hotels.stars').toLowerCase()}`,
          idFilter: transformedStar,
          active: false,
          total: results.filter(elem => elem.stars === transformedStar).length
        })
      })

      const destinations = results.map(elem => elem.destination);
      const filtersAux = {
        starsCount: {
          total: results.length,
          resultados: stars
        },
        destinations: {
          active: 'all',
          options: [...new Set(destinations)]
        },
        hotelName: ''
      };
      setFilters(filtersAux);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  const sortResults = (resultsAux, sort) => {
    let resultsAuxCopy = [...resultsAux];
    if (sort) {
      if (sort.name === 'recommended') {
        resultsAuxCopy.sort((a, b) => {
          if (b.recommended && !a.recommended) {
            return 1;
          } else if (a.recommended && !b.recommended) {
            return -1;
          } else {
            return 0;
          }
        })
      } else {
        resultsAuxCopy.sort((a, b) => a[sort.name] > b[sort.name] ? 1 : -1);

        if (sort.sort === 'descending') {
          resultsAuxCopy = resultsAuxCopy.reverse();
        }
      }
    }

    return resultsAuxCopy;
  }

  const handleChangeSort = (data) => {
    setActiveSort(data);
    setFilteredResults(sortResults(filteredResults, data));
  }

  const handleClickHotel = (view, hotel) => {
    setDefaultView(view);
    setOpenModal(true);
    setSelectedHotel(hotel);
  }

  const handleChangeFilters = (data) => {
    const filtersAux = {...filters};
    const stars = data.stars.map(elem => elem.toString());
    filtersAux['starsCount'].resultados.forEach(elem => elem.active = stars.includes(elem.idFilter));
    filtersAux['destinations'].active = data.destination || 'all';
    filtersAux['hotelName'] = data.hotelName || '';
    setFilters(filtersAux);

    const sortedResults = sortResults(results || [], activeSort);
    let filteredResultsAux = stars.length === 0 ? sortedResults : sortedResults.filter(elem => stars.includes(elem.stars));

    if (filtersAux['destinations'].active !== 'all') {
      filteredResultsAux = filteredResultsAux.filter(elem => elem.destination === data.destination);
    }

    if (filtersAux['hotelName']) {
      filteredResultsAux = filteredResultsAux.filter(elem => elem.name.toLowerCase().includes(filtersAux['hotelName'].toLowerCase()));
    }

    setFilteredResults(filteredResultsAux);
  }

  const handleSelectHotel = (hotel) => {
    setDefaultDataForm({
      label: `${hotel.name}, ${hotel.destination}`,
      gimmonix_id: hotel.id,
      pointInterest: false
    })

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  const handleDeleteFavorite = (hotel) => {
    let user = localStorage.getItem('user');
    if (user && hotel) {
      user = JSON.parse(user);
      props.deleteFavorite(user.email, hotel.id);

      const hotelId = hotel.id.toString();
      setResults(current => current.filter(elem => elem.id !== hotelId));
      setFilteredResults(current => current.filter(elem => elem.id !== hotelId));
    }
  }

  return (
    <div className="favorite-page">
      <Grid className='search-container'>
        <Container>
          <Multibuscador
            module='hotels'
            defaultDestination={defaultDataForm}
            selectedReservation={selectedReservation}
          />
        </Container>
      </Grid>

      <Container>
        <Grid className='results-container' container spacing={3}>
          <Grid item lg={3}>
            <Typography className='title row'>
              <Favorite className='favorite-icon' /> {results?.length}&nbsp;{t('results.hotels.favoriteHotels')}
            </Typography>

            <Hidden lgDown>
              {filteredResults && filteredResults.length > 0 && (
                <FiltersComponent
                  isFavoritePage
                  filters={filters}
                  loading={loading || results === undefined}
                  handleChangeFilters={handleChangeFilters}
                />
              )}
            </Hidden>
          </Grid>

          <Grid item xs={12} lg={9}>
            <FavoritesComponent
              results={filteredResults}
              loading={loading || results === undefined}
              activeSort={activeSort}
              handleChangeSort={handleChangeSort}
              handleClickHotel={handleClickHotel}
              handleSelectHotel={handleSelectHotel}
              handleDeleteFavorite={handleDeleteFavorite}
            />
          </Grid>
        </Grid>
      </Container>

      {filteredResults && (
        <HotelModal
          isOpen={openModal}
          hotels={filteredResults}
          defaultView={defaultView}
          selectedHotel={selectedHotel}
          isFavoritePage
          handleClose={() => setOpenModal(false)}
          onHotelSelect={hotel => setSelectedHotel(hotel)}
        />
      )}
    </div>
  );
}

const mapStateToProps = reducers => {
  return reducers.gimmonixReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    resetFavorites: () => dispatch(resetFavoritesActionGimmonix()),
    getFavorites: (dataForm) => dispatch(getFavoritesActionGimmonix(dataForm)),
    deleteFavorite: (user, hotelId) => dispatch(deleteFavoriteActionGimmonix(user, hotelId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesView);
