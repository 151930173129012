import React, { Fragment } from 'react';
import { Avatar, Badge, Card, CardContent, CardHeader, Grid, Hidden, Icon, Typography } from '@mui/material';
import DetailDialog from './DetailDialog';
import * as HotelsDetail from './utils/HotelsDetail';
import * as TrainsDetail from './utils/TrainsDetail';
import * as FlightsDetail from './utils/FlightsDetail';
import * as AssistsDetail from './utils/AssistsDetail';
import { useTranslation } from 'react-i18next';
import { usePurchaseDetailContext } from '../../../context/PurchaseDetailContext';
import dayjs from 'dayjs';

const PurchaseDetail = ({
  module,
  purchaseDetail,
  products
}) => {
  const { t } = useTranslation();
  const { includeComission } = usePurchaseDetailContext();

  const getProductsDetails = () => {
    if (module === 'hotels') {
      return HotelsDetail.getProductsDetails(purchaseDetail);
    } else if (module === 'trains') {
      return TrainsDetail.getProductsDetails(purchaseDetail, products);
    } else if (module === 'flights') {
      return FlightsDetail.getProductsDetails(purchaseDetail);
    } else if (module === 'assists') {
      return AssistsDetail.getProductsDetails(purchaseDetail);
    }
  }

  const getPriceDetails = () => {
    if (module === 'trains') {
      return TrainsDetail.getPriceDetails(products, includeComission, false);
    } else if (module === 'flights') {
      return FlightsDetail.getPriceDetails(purchaseDetail);
    }

    return [
      {
        title: 'total',
        value: purchaseDetail?.totalAmount,
        classname: 'strong amount',
        showTax: true
      }
    ];
  }

  return (
    <Card className='purchase-card'>
      <CardHeader
        avatar={
          <Avatar className='avatar-purchase-detail'>
            <Badge
              badgeContent={purchaseDetail?.priceDetail?.length || 0}
              color='primary'
              overlap='circular'
            >
              <Icon>{purchaseDetail?.icon}</Icon>
            </Badge>
          </Avatar>
        }
        action={products &&
          <DetailDialog
            icon='expand_more'
            purchaseDetail={purchaseDetail}
            products={products}
            module={module}
          />
        }
        title={
          <Typography className='title-card-header'>
            {t('checkout.common.purchaseDetailTitle')}
          </Typography>
        }
        subheader={
          <Typography noWrap className='title-card-subheader' >
					{module === 'assists'
          ? `${dayjs(purchaseDetail.checkin).format('ddd DD-MM')} al ${dayjs(purchaseDetail.checkout).format('ddd DD-MM')}`
          : purchaseDetail?.subtitle
          }
          </Typography>
        }
      />

      <Hidden smDown>
        <CardContent>
          {getProductsDetails(purchaseDetail?.priceDetail)}
        </CardContent>
      </Hidden>

      <Grid container className="price-details-container row">
  {getPriceDetails().map((elem, i) => (
    <Fragment key={i}>
      {elem.value && parseInt(elem.value.replaceAll('.', '')) > 0 && (
        <>
          <Grid item xs={6} className="left">
            {/* Etiqueta (ej. "Total") */}
            <Typography
              className={elem.classname}
              sx={{
                // Si es assists, azul. Si no, color por defecto.
                color: module === 'assists' ? '#1449e4 !important ' : 'inherit',
              }}
            >
              {elem.noTranslate ? elem.title : t(`checkout.common.${elem.title}`)}
            </Typography>
            {elem.showTax && Boolean(purchaseDetail?.taxAmount) && purchaseDetail?.taxAmount > 0 && (
              <Typography className="taxes">
                {t('checkout.common.taxesIncluded')} {purchaseDetail?.currency}{' '}
                {purchaseDetail?.taxAmount}
              </Typography>
            )}
          </Grid>

          <Grid item xs={6} className="right">
            {/* Valor (ej. "USD 24") */}
            <Typography
              className={`text-right ${elem.classname}`}
              sx={{
                color: module === 'assists' ? '#1449e4 !important'  : 'inherit',
              }}
            >
              {elem.parentehsis && '('}
              {purchaseDetail?.currency} {elem.value}
              {elem.parentehsis && ')'}
            </Typography>
          </Grid>
        </>
      )}
    </Fragment>
  ))}
</Grid>

    </Card>
  )
}

export default PurchaseDetail;
