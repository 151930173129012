import React, { Fragment, useState } from 'react';
import { Alert, Button, Dialog, DialogContent, DialogTitle, Divider, Fab, Grid, Hidden, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import { Close, Info, LocalCafe, LocalHotel, Person } from '@mui/icons-material';
import FiltersComponent from './FiltersComponent';
import { getClientIp } from '../../../../store/services/IpServices';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { startCheckoutAction } from '../../../../store/actions';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import Preloader from '../../../common/Preloader';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../../context/ClientContext';
import { getCountry } from '../../../../store/services/InfoFormServices';

const FILTERS = [
    { field: 'refoundable', fieldPackage: 'refundable', byRoom: false, checkCancelationPolicy: true},
    { field: 'providerName', byRoom: false},
    { field: 'boardbase', byRoom: true},
    { field: 'roomName', byRoom: true},
    { field: 'bedding', byRoom: true},
];

const BOARDBASES = {
    'Todo Incluido': 'allInclusive',
    'Solo Habitación': 'onlyRoom',
    'Desayuno': 'breakfast',
    'Media Pension': 'halfPension'
  };

function RoomView({
    selectedHotel,
    availability,
    providers,
    hashFlow,
    apiCheckout,
    errorApiCheckout,
    showMoreButton,
    filters,
    detailOnly,
    countPackages,
    showAllRooms,
    loadingAvailability,
    loadingCancelationPolicy,
    loadingMoreAvailability,
    handleClickShowMoreOptions,
    handleClickShowAllRooms,
    getCancelationPolicy,
    ...props
}) {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const countries = getCountry();
    const nationality = countries.find(elem => elem.code === params?.nationality )?.code_2;
    const { currentProduct, setClientData } = useClientData();
    const [loadingCheckout, setLoadingCheckout] = useState(false);
    const [loadingAllRooms, setLoadingAllRooms] = useState(false);
    const [activeFilters, setActiveFilters] = useState({});
    const [filteredPackages, setFilteredPackages] = useState([]);
    const [filteredFilters, setFilteredFilters] = useState({});
    const [remarks, setRemarks] = useState();
    const [user, setUser] = useState({});

    useEffect(() => {
        const userAux = JSON.parse(localStorage.getItem('user') || '{}');
        setUser(userAux);
    }, []);

    useEffect(() => {
        if (apiCheckout && Object.keys(apiCheckout).length !== 0) {
            if (!errorApiCheckout) {
                const iframePath = params.tokenIframe ? `${params.tokenIframe}/` : '';
                if (apiCheckout.baseDomain) {
                    window.location.href = apiCheckout.urlRedirect.replace(apiCheckout.baseDomain, apiCheckout.baseDomain + iframePath);
                } else {
                    const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
                    window.location.href = iframePath + `${window.location.origin}/${apiCheckout.urlRedirect}`;
                }
            } else {
                setLoadingCheckout(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorApiCheckout, apiCheckout]);
    
    useEffect(() => {
        handleChangeFilters(activeFilters, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availability, filters]);

    useEffect(() => {
        if (showAllRooms) {
            const packages = getPackages(activeFilters);
            setFilteredPackages(packages);
            handleChangeFilters(activeFilters, false);

            const interval = setInterval(() => {
                const bookCode = packages[packages.length - 1]?.bookCode;
                const element = document.getElementById(`package-${bookCode}`);
                if (element) {
                  clearInterval(interval);
                  setLoadingAllRooms(false);
                }
            }, 50);

            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAllRooms])

    const getProviderLogo = (providerName) => {
        const providerNameAux = providerName.replaceAll(' ', '').toLowerCase();
        const provider = providers.find(elem => providerNameAux.includes(elem.name));

        if (provider) {
            return <Tooltip title={providerName}>
                <img className='provider-logo' src={provider?.image} alt='logo-provider' />
            </Tooltip>
        } else {
            return <Typography>{providerName}</Typography>
        }
    }

    const getCancelationInfo = (data) => {
        if (data?.cancelation_policy?.Policy && data?.cancelation_policy?.Policy.length > 0) {
            if (data.refundable) {
                let text = [];
                data.cancelation_policy.Policy.forEach((elem, i) => {
                    if (i === 0) {
                        text.push(<span className='cancelation-info' key={0}>
                            {t('results.hotels.freeCancellationBefore', { date: dayjs(elem.dateFrom).format('ddd DD MMM YY') })}
                        </span>);
                    }
                    if (elem.price > 0 && elem.type !== 'No show') {
                        const price = elem.price.toLocaleString("es-ES", { minimumFractionDigits: 0, maximumFractionDigits: 0, useGrouping: true });
                        text.push(<span key={i + 1}>
                            &nbsp;{t('results.hotels.cancellationAfter', { date: dayjs(elem.dateFrom).format('ddd DD MMM YY'), currency: elem.Currency, price })}
                        </span>);
                    }
                });

                return <Typography className='text-center'>{text}</Typography>;
            } else {
                const checkRefundable = Boolean(user?.config?.nrf_book) === false && !data.refundable;

                return <>
                    {checkRefundable && (
                        <Typography className='align-center row mb-05' color='secondary' fontWeight={500}>
                            <Info className='mr-05' fontSize='small' />
                            {t('results.hotels.userWithourPermission')}
                        </Typography>
                    )}
                    <Typography className='text-center'>{t('results.hotels.noAllowChangesOrCancellation')}</Typography>
                </>
            }
        } else if (showAllRooms) {
            if (data?.error_policy) {
                return <Typography className='text-center error-policy'>{t('results.hotels.notAvailable')}</Typography>
            }
            return <Typography className='text-center'>{t('results.hotels.consultCancelationPolicy')}</Typography>
        }

        return '';
    }

    const filterPackages = (packages, activeFiltersAux) => {
        let filteredPackages = showAllRooms
            ? packages
            : detailOnly
                ? packages.slice(0, countPackages).filter(elem => !elem.error_policy)
                : packages.filter(elem => elem.cancelation_policy && !elem.error_policy);

        FILTERS.forEach(filter => {
            if (activeFiltersAux[filter.field] && activeFiltersAux[filter.field].length > 0) {
                if (filter.byRoom) {
                    filteredPackages = filteredPackages.filter(elem => (
                        elem.rooms.some(room => activeFiltersAux[filter.field].includes(room[filter.fieldPackage || filter.field]))
                    ));
                } else {
                   filteredPackages = filteredPackages.filter(elem => (
                        (!filter.checkCancelationPolicy || elem.cancelation_policy)
                        && activeFiltersAux[filter.field].includes(elem[filter.fieldPackage || filter.field].toString())
                    ));
                }
            }
        });

        return filteredPackages;
    }

    const getPackages = (activeFiltersAux, filter = true) => {
        let packages = [];
        if (availability?.packages) {
            packages = availability?.packages;
        } else if (availability?.package) {
            packages = [availability?.package];
        }

        if (filter) {
            return filterPackages(packages, activeFiltersAux);
        }

        return packages;
    }

    const handleSelectPackage = async (selectedPackage) => {
        if (!Boolean(selectedPackage.cancelation_policy)) {
            getCancelationPolicy(selectedPackage.bookCode, true);
        } else {
            const user = localStorage.getItem('user');
            const accessToken = localStorage.getItem('jwt');
            const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;

            if (user && accessToken && tokenCheckout) {
                setLoadingCheckout(selectedPackage.bookCode);

                const accessToken = localStorage.getItem('jwt');
                const clientIp = await getClientIp();
                const urlRedirect = location.pathname;
                const hotelProduct = JSON.parse(localStorage.getItem('products') || '[]')
                    ?.find(elem => elem.ref_table_detail === 'hotel_detail');
                const daysToCancel = hotelProduct?.parameters?.days_to_cancel || 0;

                const dataProduct = {
                    urlRedirect,
                    supplier: null,
                    ip: clientIp,
                    bookCode: selectedPackage.bookCode,
                    hashFlow,
                    daysToCancel,
                    recommended: selectedHotel.recommended || false
                };

                const requestData = {
                    module: 'hotels',
                    nationality,
                    dataProduct
                };

                props.startCheckout(accessToken, tokenCheckout, requestData);
            } else {
                const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
                if (iframePath) {
                    localStorage.clear();
                    setClientData(null);
                    navigate(iframePath);
                } else {
                    navigate('/login', { state: { expiredSession: true } });
                }
            }
        }
    }

    const handleChangeFilters = (data, scroll = true) => {
        setActiveFilters(data);

        const filteredFiltersAux = {};
        const packages = getPackages(data);
        setFilteredPackages(packages);

        FILTERS.forEach(filter => {
            filteredFiltersAux[filter.field] = {};

            packages.forEach(packageAux => {
                if (showAllRooms || !filter.checkCancelationPolicy || packageAux.cancelation_policy) {
                   if (filter.byRoom) {
                        packageAux.rooms.forEach(room => {
                            const value = room[filter.fieldPackage || filter.field];
                            filteredFiltersAux[filter.field][value] =
                                (filteredFiltersAux[filter.field][value] || 0) + 1
                        })
                    } else {
                        const value = packageAux[filter.fieldPackage || filter.field].toString();
                        filteredFiltersAux[filter.field][value] =
                            (filteredFiltersAux[filter.field][value] || 0) + 1
                    }
                }
            });

            filteredFiltersAux[filter.field] = Object.entries(filteredFiltersAux[filter.field]).map(entry => {
                const [key, value] = entry;
                return { value: key, total: value }
            });
        });

        setFilteredFilters(filteredFiltersAux);

        if (scroll) {
            document.getElementById('hotel-modal-content')?.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    const handleOpenRemarks = (room) => {
        setRemarks(room?.cancelation_policy?.remarks || t('results.hotels.defaultRemarks'))
    }

    const parseHTML = (html) => {
        const textArea = document.createElement("textarea");
        textArea.innerHTML = html;
        return <div dangerouslySetInnerHTML={{ __html: textArea.value }} />
    };

    const isLoading = (room) => {
        const checkCheckout = Boolean(loadingCheckout) && loadingCheckout === room.bookCode;
        const checkCancelationPolicy = room.bookCode === loadingCancelationPolicy;
        return checkCheckout || checkCancelationPolicy;
    }

    const isDisabled = (room) => {
        const checkCheckout = Boolean(loadingCheckout) && loadingCheckout !== room.bookCode;
        const checkRefundable = Boolean(user?.config?.nrf_book) === false && !room.refundable;
        const checkCancelationPolicy = !Boolean(room.cancelation_policy) && showAllRooms;

        return checkCancelationPolicy
            ? (loadingCancelationPolicy && room.bookCode !== loadingCancelationPolicy) || Boolean(room.error_policy)
            : checkCheckout || checkRefundable;
    }

    const getButtonText = (room) => {
        return !Boolean(room.cancelation_policy) && showAllRooms
            ? t('common.consult')
            : t('common.reserve')
    }

    const getRoomTypeAlert = (roomType) => {
        let alert = '';

        if (roomType?.includes('dormitory')) {
            alert = t('results.hotels.roomTypeAlerts.dormitory');
        }

        if (alert) {
            return <Typography className='room-alert'>{alert}</Typography>
        }
    }

    return (
        <Grid container className='room-view' columnSpacing={2}>
            <Hidden lgDown>
                <Grid item lg={3}>
                    <FiltersComponent
                        filters={filteredFilters}
                        activeFilters={activeFilters}
                        loading={loadingAvailability}
                        showAllRooms={showAllRooms}
                        handleChangeFilters={handleChangeFilters}
                    />
                </Grid>
            </Hidden>

            <Grid item xs={12} lg={9}>
                <Grid className='row' justifyContent='space-between' marginBottom='10px'>
                    <Typography className='title'>
                        {t('results.hotels.ratesAndRoomType')}
                    </Typography>

                    {!loadingAvailability && !showAllRooms && (
                        <Button
                            className='show-rooms-button'
                            variant='contained'
                            onClick={() => {
                                setLoadingAllRooms(true);
                                handleClickShowAllRooms();
                            }}
                        >
                            {t('results.hotels.showAllRooms')}
                        </Button>
                    )}
                </Grid>

                {loadingAvailability ? (
                    Array.from(Array(3).keys()).map(elem => (
                        <Grid key={elem} container className='room-container row' columns={24} columnSpacing={1}>
                            <Grid item className='left-side' xs={24} md={19}>
                                <Grid container className='top row' columns={24} spacing={1}>
                                    <Grid container className='room-info row' columns={24} spacing={1}>
                                        <Grid item className='name-container' xs={24} sm={11} md={12}>
                                            <Skeleton height='30px' width='100%' />
                                            <Skeleton height='30px' width='50%' />
                                        </Grid>

                                        <Grid item className='room-pax' xs={24} sm={6} md={5}>
                                            <Skeleton height='30px' width='100%' />
                                        </Grid>
                                        <Grid item className='row boardbase' xs={24} sm={7}>
                                            <Skeleton height='30px' width='100%' />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Skeleton height='45px' width='100%' />
                            </Grid>
                            <Grid item className='price-container' sm={24} md={5}>
                                <Skeleton height='30px' width='100%' />
                                <Skeleton height='30px' width='100%' />
                                <Skeleton height='30px' width='100%' />
                            </Grid>
                        </Grid>
                    ))
                ) : (
                    <>
                        {getPackages(null, false).length === 0 ? (
                            <Alert severity='warning' className='alert-error'>
                                <Typography>{t('results.hotels.noRooms')}</Typography>
                            </Alert>
                        ) : selectedHotel?.property === 'Apartment' ? (
                            <Alert severity='warning' className='alert-error'>
                                <Typography>{t('results.hotels.apartmentInfo')}</Typography>
                            </Alert>
                        ) : null}
                        {filteredPackages?.map((elem, i) => (
                            <Grid id={`package-${elem.bookCode}`} key={i} container className='room-container' columns={24} columnSpacing={1}>
                                <Grid item className='left-side column' xs={24} md={19}>
                                    <Grid container className='top row' columns={24} spacing={1}>
                                        <Grid item className='rooms' xs={24} sm={19}>
                                            {elem?.rooms.map((room, j) => (
                                                <Fragment key={`${i}_${j}`}>
                                                    <Grid container className='room-info row' columns={24} spacing={1}>
                                                        <Grid item className='name-container' xs={24} sm={11} md={12}>
                                                            <Typography className='title'>{room.roomName}</Typography>
                                                            {getRoomTypeAlert(room.roomName?.toLowerCase())}
                                                            {room.bedding && <Typography className='subtitle'>{t('results.hotels.bedType')}: {room.bedding}</Typography>}
                                                        </Grid>

                                                        <Grid item className='room-pax' xs={24} sm={6} md={5}>
                                                            <Grid className='row'>
                                                                {Array.from(Array(room.adults + (room.kids?.count || 0)).keys()).map(icon => <Person key={icon} fontSize='small' />)}
                                                            </Grid>
                                                            1 {t('results.hotels.room')}
                                                        </Grid>
                                                        <Grid item className='row boardbase' xs={24} sm={7}>
                                                            {room.boardbase === 'Solo Habitacion' ? (
                                                                <><LocalHotel className='green' /> {t('results.hotels.onlyRoom')}</>
                                                                ) : <><LocalCafe className='green' /> {BOARDBASES[room.boardbase]
                                                                    ? t(`results.hotels.${BOARDBASES[room.boardbase]}`)
                                                                    : room.boardbase
                                                                }</>
                                                            }
                                                        </Grid>
                                                    </Grid>

                                                    {j < elem.rooms.length - 1 && (
                                                        <Divider />
                                                    )}
                                                </Fragment>
                                            ))}
                                        </Grid>

                                        <Grid item xs={24} sm={5} className='refundable'>
                                            {elem?.cancelation_policy?.Policy && !elem?.error_policy && (
                                                elem.refundable ? (
                                                    <span>{t('results.hotels.refundable')}</span>
                                                ) : (
                                                    <span className='non-refundable'>{t('results.hotels.nonRefundable')}</span>
                                                )
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className='price-container' xs={24} md={5}>
                                    <Grid container className='column'>
                                        <Grid item xs={12} sm={6} md={12} className='column'>
                                            <Typography className='title row'>
                                                <Tooltip
                                                    title={elem.taxAmount && elem.taxAmount > 0
                                                        ? `${t('results.hotels.taxesAndFeesIncluded')} ${elem.currency} ${elem.taxAmount}`
                                                        : null
                                                    }
                                                >
                                                    {elem.currency} {parseInt(elem.totalPrice)}
                                                </Tooltip>
                                                <Tooltip title={t('results.hotels.seeHotelComments')}>
                                                    <IconButton size='small' onClick={() => handleOpenRemarks(elem)}>
                                                        <Info fontSize='small' color='primary' />
                                                    </IconButton>
                                                </Tooltip>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={12} className='column'>
                                            <LoadingButton
                                                size='small'
                                                variant='contained'
                                                color='secondary'
                                                loading={isLoading(elem)}
                                                disabled={isDisabled(elem)}
                                                onClick={() => handleSelectPackage(elem)}
                                            >
                                                {getButtonText(elem)}
                                            </LoadingButton>
                                            {getProviderLogo(elem.providerName)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={24} className='cancelation-policy'>
                                    {getCancelationInfo(elem)}
                                </Grid>
                            </Grid>
                        ))}
                        {showMoreButton && !showAllRooms && (
                            <LoadingButton
                                variant='outlined'
                                style={{ display: 'flex', margin: 'auto' }}
                                loading={loadingMoreAvailability}
                                onClick={handleClickShowMoreOptions}
                            >
                                {t('results.hotels.seeMoreOptions')}
                            </LoadingButton>
                        )}
                    </>
                )}
            </Grid>

            <Dialog open={Boolean(remarks)} onClose={() => setRemarks(null)} className='closable-modal'>
                <Fab size="small" onClick={() => setRemarks(null)} className='close-button'>
                    <Close fontSize='small' />
                </Fab>

                <DialogTitle>
                    {t('results.hotels.hotelComments')}
                </DialogTitle>
                <DialogContent>
                    {parseHTML(remarks)}
                </DialogContent>
            </Dialog>

            {(loadingCheckout || loadingAllRooms) && (
                <Preloader
                    addDots
                    image={`sites/${props.client.client.name}/preloadHotel.gif`}
                    text={t('common.processingRequest')}
                />
            )}
        </Grid>
    );
}

const mapStateToProps = reducers => {
    return reducers.gimmonixReducer;
};

const mapDispatchToProps = dispatch => {
    return {
        startCheckout: (access, tokenCheckout, data) => dispatch(startCheckoutAction(access, tokenCheckout, data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomView);
